import { axiosProductsList, axiosRemoveCatalog, axiosCreateCatalog, axiosProductParam, axiosGetProductParam, axiosGetProductsUser, axiosSaveProduct, axiosGetProductUser, axiosRemoveProduct, axiosCatalogParam } from '@/api/index'
import router from "@/router";

export const productsModule = {
  state: () => ({
    loading: false,
    loadDel: false,
    loadSave: false,
    message: '',
    errors: [],
    length: 0,
    flagParam: false,
    productsList: [],
    product: {},
    selectedSupplier: '',
    searchName: '',
    searchBrand: '',
    searchBarcode: '',
    searchSku: '',
    searchSkuSupplier: '',
    productParam: [],
    productParamStat: [
      {id: 'image', name: 'Фото'}, 
      {id: 'name', name: 'Название'},
      {id: 'sku_supplier', name: 'Артикул поставщика'},
      {id: 'sku', name: 'Артикул StartCommerce'},
      {id: 'price', name: 'Цена-1 (₽)'}, 
      {id: 'price_two', name: 'Цена-2 (₽)'},
      {id: 'price_three', name: 'Цена-3 (₽)'},
      {id: 'count', name: 'Количество'},
      {id: 'brand', name: 'Бренд'},
      {id: 'barcode', name: 'Штрихкод'},
    ],
    showParam: false,
    temporary: {
      num: 0,
      str: ''
    },
    filter: false,
    limit: 30,
    page: 1
  }),

  mutations: {
    setMessage(state, v){state.message = v},
    setLoadDel(state, v){state.loadDel = v},
    setLoadSave(state, v){state.loadSave = v},
    setErrors(state, v){state.errors = v},
    setProduct(state, v){state.product = v},
    setProductImage(state, v){state.product.image = v},
    setProductName(state, v){state.product.name = v},
    setProductSkuSupplier(state, v){state.product.sku_supplier = v},
    setProductRetailPrice(state, v){state.product.retail_price = v},
    setProductPriceTwo(state, v){state.product.price_two = v},
    setProductPriceThree(state, v){state.product.price_three = v},
    setProductCount(state, v){state.product.count = v},
    setProductBrand(state, v){state.product.brand = v},
    setProductBarcode(state, v){state.product.barcode = v},
    setProductCatalog(state, v){state.product.catalog = v},
    setProductDescription(state, v){state.product.description = v},
    // ---
    setFlagParam(state, v){state.flagParam = v},
    setLength(state, v){state.length = v},
    setProductsList(state, v){state.productsList = v},
    setProductParam(state, v){state.productParam = v},
    setProductParamAdd(state, v){state.productParam.push(v)},
    setProductParamDel(state, v){state.productParam = state.productParam.filter(e => e != v)},
    setShowParam(state, v){state.showParam = !state.showParam},
    setSelectedSupplier(state, v){state.selectedSupplier = v},
    setSearchName(state, v){state.searchName = v, state.page = 1},
    setSearchBrand(state, v){state.searchBrand= v, state.page = 1},
    setSearchBarcode(state, v){state.searchBarcode= v, state.page = 1},
    setSearchSku(state, v){state.searchSku= v},
    setSearchSkuSupplier(state, v){state.searchSkuSupplier= v},
    setSearchCount(state, v){state.searchCount= v},
    setPage(state, v){state.page = v},
    setTemporaryNum(state, v){state.temporary.num = v},
    setTemporaryStr(state, v){state.temporary.str = v},
    setLoading(state, v){state.loading = v},
    setFilter(state, v){state.filter = !state.filter}, 
    setLimit(state, v){state.limit = Number(v)}
  },

  actions: {

    // Получаем данные товара
    async actionGetProductsUser({state, rootState, commit, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosGetProductsUser(rootState.token, {catalog: state.selectedSupplier, skuSupplier: state.searchSkuSupplier, sku: state.searchSku, name: state.searchName, brand: state.searchBrand, barcode: state.searchBarcode}, state.page, state.limit)
        commit('setFlagParam', response.flagParam)
        commit('setLength', response.products.length)
        commit('setProductsList', response.products.products)
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetProductsUser')
        }
        console.log(e.response.data)
      }
    },

    // Получение одного товара
    async actionGetProductUser({state, rootState, commit, dispatch}, param){
      try {
        commit('setTemporaryStr', param)
        const response = await axiosGetProductUser(rootState.token, state.selectedSupplier, state.temporary.str)
        commit('setProduct', response.product)
        commit('setTemporaryStr', '')
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetProductUser', state.temporary.str)
        }
        console.log(e.response.data)
      }
    },

    // Сохранения товара
    async actionSaveProduct({state, rootState, commit, dispatch}){
      try {
        let errors = []
        !state.product.sku_supplier ? errors.push('Укажите артикул.') : ''
        !state.product.catalog ? errors.push('Каталог не выбран.') : ''

        if(errors.length){
          commit('setErrors', errors)
          return
        }
        commit('setErrors', [])
        commit('setLoadSave', true)
        const response = await axiosSaveProduct(rootState.token, state.product)
        router.push('/products')
        commit('setMessage', response.message)
        commit('setLoadSave', false)
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionSaveProduct')
        }
        console.log(e.response.data)
      }
    },

    // Удаляем товар
    async actionRemoveProduct({state, rootState, commit, dispatch}, param){
      try {
        commit('setLoadDel', true)
        commit('setTemporaryStr', param)
        await axiosRemoveProduct(rootState.token, state.temporary.str, state.selectedSupplier)
        const data = state.productsList.filter(f => f.sku != param)
        const length = state.length - 1
        commit('setTemporaryStr', '')
        commit('setProductsList', data)
        commit('setLength', length)
        commit('setLoadDel', false)
        commit('setMessage', 'Товар удален')
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);

      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionRemoveProduct', state.temporary.str)
        }
        console.log(e.response.data)
      }
    },

    // Сохраняем параметры для вывода товаров
    async actionProductParam({state, rootState, commit, dispatch}){
      try {
        await axiosProductParam(rootState.token, {data: state.productParam})
        commit('setShowParam')
        await dispatch('actionGetProductsUser')
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionProductParam')
        }
        console.log(e.response.data)
      }
    },

    // Получаем параметры для вывода товаров

    async actionGetProductParam({rootState, commit, dispatch}){
      try {
       
        const response = await axiosGetProductParam(rootState.token)
        commit('setProductParam', response.param_product)
        await dispatch('actionGetProductsUser')
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetProductParam')
        }
        console.log(e.response.data)
      }
    },

    // Cохроняем параметр каталога
    async actionCatalogParam({state, rootState, commit, dispatch}, param){
      try {
        commit('setTemporaryStr', param)
        await axiosCatalogParam(rootState.token, state.temporary.str)
        commit('setSelectedSupplier', state.temporary.str)
        commit('setTemporaryStr', '')
        await dispatch('actionUser', null, { root: true })
        await dispatch('actionGetProductsUser')
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionCatalogParam', state.temporary.str)
        }
        console.log(e.response.data)
      }
    },

    //Создаем каталог
    async actionCreateCatalog({state, rootState, commit, dispatch}, id){
      try {
        commit('setTemporaryNum', id)
        await axiosCreateCatalog(rootState.token, state.temporary.num)
        commit('setTemporaryNum', 0)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionCreateCatalog', state.temporary.num)
        }
        console.log(e.response.data)
      }
    },

    //Удалемем каталог
    async actionRemoveCatalog({state, rootState, commit, dispatch}, id){
      try {
        commit('setTemporaryNum', id)
        await axiosRemoveCatalog(rootState.token, state.temporary.num)
        commit('setTemporaryNum', 0)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionRemoveCatalog', state.temporary.num)
        }
        console.log(e.response.data)
      }
    },

    async actionProductsList({commit, rootState, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosProductsList(rootState.user.user_package.token)
        commit('setProductsList',  response.products)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionSuppliersList')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },
  },
  namespaced: true
}