<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
      </div>
      <div v-if="neurnetwork.error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{neurnetwork.error}}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Рерайт текста</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Рерайт текста</h4>
                </div> 
              </div>

              <div class="row">
                <div class="col-sm-12 col-lg-5">
                  <div class="card ">
                    <div class="card-header">
                      <div class="header-title"><h5 class="card-title">Рерайт</h5></div>
                    </div>
                    <div class="card-body">
                      <div class="form-group">
                        <label>Введите текст для рерайта</label>
                        <my-textarea 
                          :model-value="neurnetwork.rewriteText.text" @update:model-value="setRewriteTextText"
                          class="form-text-neur"
                          maxlength="2000"
                          placeholder="Ваш текст"
                          />
                          <div><span class="text-secondary">{{ neurnetwork.rewriteText.text ? neurnetwork.rewriteText.text.length : 0 }} / 2000</span></div>
                      </div>

                      <div class="form-group">
                        <label>Дополнительные указания для нейросети (необязательно). <span class="text-secondary fz-13">Например,  Добавить дополнительный текст к рерайту, Добавить текст в нумерации к рерайту. и т. д.</span></label>
                        <my-input
                          :model-value="neurnetwork.rewriteText.keywords" @update:model-value="setRewriteTextKeywords"
                          type="text"
                          maxlength="150"
                        />
                      </div>

                      <div class="form-group">
                        <label>Допустимая креативность.</label><br>
                        <my-range 
                          :model-value="neurnetwork.rewriteText.creative" @update:model-value="setRewriteTextCreative"
                          :min="1" 
                          :max="4" 
                          :step="1"
                        />
                        <div><span class="text-secondary">{{ neurnetwork.rewriteText.creative }} / 4</span></div>
                      </div>

                      <div class="form-group">
                        <div class="custom-control custom-switch custom-control-inline">
                          <my-checkbox
                            class="custom-control-input"
                            :id="`strong1`"
                            :model-value="neurnetwork.rewriteText.strong" @update:model-value="setRewriteTextStrong"
                          />
                          <label for="strong1" class="custom-control-label">Сильное перефразирование</label>
                        </div>
                  
                      </div>

                      <my-button type="submit" class="btn btn-primary btn-block mt-5" @click="saveText">Перефразировать текст за <span class="badge ml-2 badge-light">{{ Math.ceil(neurnetwork.rewriteText.text.length / 100) }}</span> руб.</my-button>
                    </div>
                    
                  </div>
                </div>

                <div class="col-sm-12 col-lg-7">
                  <div class="card ">
                    <div class="card-header d-flex justify-content-between">
                      <div class="header-title"><h5 class="card-title">Результат</h5></div>
                    </div>
                    <div class="card-body">
                      <div v-if="neurnetwork.loading">
                        <loading-small />
                        <div class="text-secondary text-center">Генерация займет некоторое количество времени</div>
                      </div>
                      <div v-else >
                        <div v-html="neurnetwork.rewriteText.textResult"></div>
                        <div class="mt-5" v-if="neurnetwork.rewriteText.textResult">
                          <copy-text :text="neurnetwork.rewriteText.textResult" name="Копировать" message="Текс скопирован в буфер обмена" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-12">
                  <div class="alert alert-primary" role="alert">
                    <div class="iq-alert-text">
                      <h5><b>Памятка для рерайта</b></h5><br>
                      <ul>
                        <li class="mb-2">Нейронная сеть обладает обширными знаниями, но иногда она также может выдумывать информацию, которая не соответствует действительности. Поэтому важно всегда проверять сгенерированную информацию на точность перед ее использованием.</li>
                        <li class="mb-2">Важно учитывать, что при добавлении дополнительных указаний, нейронная сеть может интерпретировать их неточно или может полностью игнорировать их.</li>
                        <li class="mb-2">Чтобы добиться более сильного перефразирования, выберите пункт "Сильное перефразирование", также можно повысить креативность, но нужно учитывать тот момент, что чем выше креативность, тем больше может быть выдуманной информации, также можно попросить нейрость написать дополнительный текст от себя. Все эти рекомендации могут помочь сделать текст уникальным или повысить процент уникальности. Также нельзя забывать, что чем меньше текст, тем сложнее добиться в нем уникальности.</li>
                        <li class="mb-2">Каждый рубль эквивалентен 100 символам. Если символов меньше 100, сумма округляется в большую сторону.</li>
                        <li>Время генерации текста зависит от количества желаемых символов, но в среднем текст генерируется от 10 до 50 секунд. </li>
                      </ul>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import CopyText from '@/components/CopyText'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    CopyText
  },
  methods: {
    ...mapMutations({setRewriteTextText: 'neurnetwork/setRewriteTextText', setRewriteTextKeywords: 'neurnetwork/setRewriteTextKeywords', setRewriteTextCreative: 'neurnetwork/setRewriteTextCreative', setRewriteTextStrong: 'neurnetwork/setRewriteTextStrong'}),
    ...mapActions({actionNeurnetworkRewriteText: 'neurnetwork/actionNeurnetworkRewriteText' }),

    saveText(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.actionNeurnetworkRewriteText()
    }
  },

  computed: {

    ...mapState({
      neurnetwork: state => state.neurnetwork,
      message: state => state.message,
    }),
  },

  mounted(){
    //this.actionProductsList();
    document.title = 'Создание описания товара - StartCommerce"'
  },

  unmounted(){
    //this.setProductsList([])
  }
}
</script>

<style>
  .form-text-neur{
    line-height: 17px;
    min-height: 100px;
  }
  .fz-13{
    font-size: 13px;
  }
</style>