<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item"><router-link to="/partner-information">Партнерская программа</router-link></li>
                  <li class="breadcrumb-item active">Рефералы</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Рефералы</h4>
                </div> 
              </div>

              <div v-if="partner" class="row">
                <div class="col-lg-12">
                  <div class="card card-block">
                    <div class="card-body p-0">
                      <div class="table-responsive">
                        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                          <div class="row">
                            <div class="col-sm-12">
                              <div v-if="loading" class="text-center"><loading-small /></div>
                              <table v-else class="table data-table mb-0 dataTable no-footer sp th-pl">
                                <thead class="table-color-heading">
                                  <tr>
                                    <th>Имя</th>
                                    <th>Промокод</th>
                                    <th>Сумма промокода / остаток с учетом вычета партнерских</th>
                                    <th>Лимит суммы для активации промокода</th>
                                    <th>Сумма пополнений баланса рефералом</th>
                                    <th>Сумма использованных средств рефералом</th>
                                    <th>Партнерское вознаграждение</th>
                                    <th>Платежи</th>
                                    <th>Начисления</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="referral in referralsList" :key="referral.id">
                                    <td>{{ referral.user.name }}</td>
                                    <td>
                                      <div v-if="referral.status">
                                        <p v-if="referral.status_promocode" class="mb-0 text-success">Не активирован</p>
                                        <p v-else class="mb-0 text-success">Активирован</p>
                                      </div>
                                      <p v-else class="mb-0 text-danger">Нет</p>
                                    </td>
                                    <td>
                                      <div class="mb-0 text-success">{{ parseFloat(referral.sum_promocode).toFixed(2) }} <i class="fa fa-rub"></i></div>
                                      <div class="mb-0 text-danger">{{ parseFloat(referral.sum_promocode_deduction).toFixed(2) }} <i class="fa fa-rub"></i></div>
                                    </td>
                                    <td>{{ parseFloat(referral.sum_promocode_limit).toFixed(2) }} <i class="fa fa-rub"></i></td>
                                    <td>{{ parseFloat(referral.sum_deposit).toFixed(2) }} <i class="fa fa-rub"></i></td>
                                    <td>{{ parseFloat(referral.sum_deduction).toFixed(2) }} <i class="fa fa-rub"></i></td>
                                    <td>{{ parseFloat(referral.sum_accruals).toFixed(2) }} <i class="fa fa-rub"></i></td>
                                    <td>
                                      <a href="#" class="" title="Платежи реферала" @click="refAction(referral.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-secondary"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                      </a>
                                    </td>
                                    <td>
                                      <a href="#" class="" title="Начисления" @click="refAccruals(referral.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-secondary"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>

                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row">
                <div class="col-sm-12 col-lg-12">
                  <p>У вас нет статуса партнера, <router-link to="/partner-information" class="svg-icon">Стать партнером</router-link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
  },
  methods: {
    ...mapMutations({setReferralsList: 'partner/setReferralsList', setRefId: 'partner/setRefId'}),
    ...mapActions({actionReferralsList: 'partner/actionReferralsList'}),

    refAction(id){
      this.setRefId(id)
      this.$router.push('/partner-referral-payments')
    },

    refAccruals(id){
      this.setRefId(id)
      this.$router.push('/partner-accrual')
    }
  },

  computed: {

    ...mapState({
      referralsList: state => state.partner.referralsList,
      loading: state => state.loadingSmall,
      partner: state => state.user.partner,
    }),
  },

  mounted(){
    this.actionReferralsList();
    document.title = 'Рефералы - StartCommerce"'
  },

  unmounted(){
    this.setReferralsList([])
  }
}
</script>

<style>
.th-pl th{
  padding-right:0px;
  font-size: 13px;
}
.th-pl td, .th-pl th {
  text-align: center;
}
</style>