<template>
  <Header></Header>
  <invoice :name="name" :room="room" :payment="payment" />
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import Invoice from '@/components/Invoice'
import {mapState} from 'vuex'
export default {
  components: {
    Header,
    Invoice
  },
  data(){
    return {
      name: 'Пополнение баланса на сайте StartCommerce',
      room: 'Б'
    }
  },

  computed: {
    ...mapState({
      payment: state => state.user.top_up_balance,
    })
  },

  mounted(){
    document.title = "Счет для оплаты - StartCommerce"
  }
}
</script>