<template>
  <input :value="modelValue" @input="updateInput" class="form-control" />
</template>

<script>
export default {
  name: 'my-input-array',
  props: {
    modelValue: [String, Number],
    id: [String, Number]
  },

  methods: {
    updateInput(event){
      this.$emit('update:modelValue', {id: this.id, name: event.target.value}) 
    }
  }

}
</script>