<template>
  <form @submit.prevent>
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <div v-if="errors.length">
            <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errors">{{error}}</p>
          </div>
          <label class="text-secondary">Имя:</label>
          <my-input
            :model-value="reg.name" @update:model-value="setRegName"
            type="name"
            required
            placeholder="Имя или название организации"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label class="text-secondary">Электронная почта:</label>
          <my-input
            :model-value="reg.email" @update:model-value="setRegEmail"
            type="email"
            required
            placeholder="Ваш е-mail"
          />
        </div>
      </div>
      <div class="col-lg-12">
        <div class="form-group">
          <label class="text-secondary">Промокод:</label>
          <my-input
            :model-value="reg.promocode" @update:model-value="setRegPromocode"
            type="text"
            placeholder=""
          />
        </div>
      </div>
      <div class="col-lg-12 mt-2">
        <div class="form-group">
          <label class="text-secondary">Пароль</label>
          <my-input 
            :model-value="reg.password" @update:model-value="setRegPassword"
            type="password"
            required
            placeholder="Придумайте пароль"
          />
          <div v-if="errors.empty">{{errors}}</div>
        </div>
      </div>
      <div class="col-lg-12 mt-2">
        <div class="form-group">
           <label class="text-secondary">Повторите пароль</label>
           <my-input 
            :model-value="reg.repeatPassword" @update:model-value="setRegRepeatPassword"
            type="password"
            required
            placeholder="Повторите пароль"
          />
        </div>
      </div>
      <div class="col-lg-12 mt-2">
        <div class="form-check form-check-inline">
          <label> Нажимая кнопку, я соглашаюсь с  <a href="https://startcommerce.ru/polzovatelskoe-soglashenie" target="_blank">Договором оферты</a> и <a href="https://startcommerce.ru/politika-konfidentsialnosti" target="_blank">Политикой конфиденциальности</a> </label>
        </div>
      </div>
    </div>
    <my-button type="submit" class="btn-primary btn-block mt-2" @click="actionReg">Зарегистрироваться</my-button>
    <div class="col-lg-12 mt-3">
      <p class="mb-0 text-center">У вас уже есть аккаунт? <router-link to="/login">Войти</router-link></p>
    </div>
  </form>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'registration-form',
  methods: {
    ...mapMutations(['setRegName', 'setRegEmail', 'setRegPassword', 'setRegRepeatPassword', 'setRegPromocode']),

    ...mapActions(['actionReg'])
  },
  computed: {
    ...mapState({
      reg: state => state.reg,
      errors: state => state.errors
    }),
  }
}
</script>