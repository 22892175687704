<template>
<button class="btn">
  <slot></slot>
</button>
</template>

<script>
export default {
  name: 'my-button'
}
</script>