<template>
  <div class="table-responsive pricing pt-2">
    <table class="table">
      <thead>
        <tr>
          <th class="text-center prc-wrap v-middle">
            <div class="custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline">
              <div class="custom-switch-inner">
                <p class="mb-0">Оплата за</p>
                <input type="checkbox" class="custom-control-input bg-ser" :checked="checked" id="customSwitch-22" @change="setChecked">
                <label class="custom-control-label" for="customSwitch-22" data-on-label="Год" data-off-label="Месяц">
                </label>
              </div>
            </div>
          </th>
          <th v-for="t in tariff" :key="t.id" class="text-center prc-wrap">
            <div class="prc-box">
              <div class="h3 pt-5">{{ checked ? (t.sum - (Number(t.sum) * t.percent / 100)).toLocaleString() : Number(t.sum).toLocaleString() }} <i class="fa fa-rub"></i><small> / В месяц</small>
              </div> <span class="type">{{ t.tariff }}<br> {{ t.profile }}</span>
            </div>
          </th>
          <!--<th class="text-center prc-wrap">
            <div class="prc-box active">
              <div class="h3 pt-5">Скоро
              </div> 
              <span class="type">Marketplace</span>
            </div>
          </th>-->
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="text-center">Период оплаты на {{ checked ? 'год' : 'месяц' }}</th>
          <td v-for="t in tariff" :key="t.id" class="text-center child-cell">
            <div v-if="checked">
              {{( (t.sum - (Number(t.sum) * t.percent / 100)) * 12).toLocaleString() }} <i class="fa fa-rub"></i> <b class="text-danger">(- {{ t.percent }}%)</b>
            </div>
            <div v-else>
              {{ Number(t.sum).toLocaleString() }} <i class="fa fa-rub"></i>
            </div>
          </td>
       </tr>
       <tr>
          <th class="text-center">Обновление цен и остатков поставщиков в своем интернет-магазине</th>
          <td v-for="t in tariff" :key="t.id" class="text-center child-cell">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </td>
       </tr>
       <tr>
          <th class="text-center">Обновление цен и остатков в маркетплейсах Яндекс Маркет, OZON, Wildberries</th>
          <td v-for="t in tariff" :key="t.id" class="text-center child-cell">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-primary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </td>
       </tr>
        <tr>
          <th class="text-center w250">Кол-во поставщиков (прайс-листов)</th>
          <td v-for="t in tariff" class="text-center child-cell" :key="t.id">до {{ t.allowed_number }}</td>
        </tr>
        <tr>
          <th class="text-center w250">Дополнительные поставщики сверх тарифа</th>
          <td v-for="t in tariff" class="text-center child-cell" :key="t.id">
            {{ (t.sum / t.allowed_number).toLocaleString() }} <i class="fa fa-rub"></i>  за одного поставщика
          </td>
        </tr>
        <tr>
          <th class="text-center">Подключение прайс-листов ваших поставщиков</th>
          <td v-for="t in tariff" :key="t.id" class="text-center child-cell">
            от 500 <i class="fa fa-rub"></i>  за одного поставщика<br>
            <span class="f12">(Оплата разовая)</span>
          </td>
        </tr>
        <tr>
          <th class="text-center">Помощь в настройке интернет-магазина под API</th>
          <td v-for="t in tariff" :key="t.id" class="text-center child-cell">
            По договоренности
          </td>
        </tr>
        <tr>
          <th class="text-center">Настройка отправки запроса <span class="f12">(моментальное бронирование товара у поставщика при оформлении или оплате пользователем товара в вашем интернет-магазине)</span></th>
          <td  v-for="t in tariff" :key="t.id" class="text-center child-cell">
            1 000 <i class="fa fa-rub"></i>  за одного поставщика<br>
            <span class="f12">(Оплата разовая)</span>
          </td>
        </tr>
        <tr>
          <th class="text-center">Настройка отправки запроса <span class="f12">(проверка наличия у поставщика товара, находящегося в корзине пользователя в вашем интернет-магазине)</span></th>
          <td  v-for="t in tariff" :key="t.id" class="text-center child-cell">
            300 <i class="fa fa-rub"></i>  за одного поставщика<br>
            <span class="f12">(Оплата разовая)</span>
          </td>
        </tr>
        <tr>
          <th class="text-center">Если количество товарных позиций по одному поставщику превышает 100 000</th>
          <td v-for="t in tariff" :key="t.id" class="text-center child-cell">
           + 100 <i class="fa fa-rub"></i>  за каждые 50 000 позиций
          </td>
        </tr>
        
        <tr>
          <th class="text-center">Период для тестирования </th>
          <td v-for="t in tariff" :key="t.id" class="text-center child-cell">
            3 дня / до 2 поставщиков
          </td>
        </tr>

        <tr>
          <td class="text-center"></td>
          <td v-for="t in tariff" :key="t.id" class="text-center">
            <div v-if="!userPackage">
              <button type="button" class="btn btn-primary mt-3" @click="actionTariff(t.tariff)">Попробовать бесплатно</button>
            </div>
            <div v-else>
              <span v-if="userPackage.tariff === t.tariff" class="text-success"><b>Ваш тариф</b></span>
              <button v-else-if="userPackage.status" type="button" class="btn btn-primary mt-3" data-toggle="modal" data-target="#tariffModal" @click="chooseTariff(t.tariff)">Выбрать тариф</button>
              <button v-else-if="!userPackage.status" type="button" class="btn btn-primary mt-3" @click="actionClosesTariff(t.tariff)">Выбрать тариф</button>
            </div>
          </td>
        </tr>
        

      </tbody>
    </table>
    <div class="modal fade" id="tariffModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Перейти на тариф "{{ closesTariff }}"?</h5>
          </div>
          <div class="modal-body">
            <p v-if="userPackage && userPackage.sum === userPackage.payable">Остаток средств за неизрасходованный активный период по вашему текущему тарифу будет возвращен на баланс вашего аккаунта, за вычетом текущего дня.</p>
            <p v-else>Остаток средств за неизрасходованный активный период по вашему текущему тарифу будет возвращен на баланс вашего аккаунта, за вычетом текущего дня. Обратите внимание, что скидка, которая применяется при оплате за год, аннулируется, и дни, которые вы пользовались тарифом, считаются по полной стоимости, без учета скидки.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal" @click="actionClosesTariff(closesTariff)">Да</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Нет</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'tariff',
  data() {
    return {
      checked: true,
      closesTariff: ''
    }
  },
  methods: {
    ...mapActions({actionTariff: 'actionTariff', actionClosesTariff: 'actionClosesTariff'}),

    chooseTariff(elem){
      this.closesTariff = elem
    },

    setChecked(){
      this.checked = !this.checked
    }
    
  },
  computed: {
    ...mapState({
      userPackage: state => state.user.user_package,
      tariff: state => state.tariff.filter(t => t.profile === 'shop')
    }),
  }
}
</script>
<style>
  .v-middle .custom-switch.custom-switch-text .custom-control-label{
    width: 75px;
  }
  .v-middle .custom-switch.custom-switch-text .custom-control-input:checked~.custom-control-label:after{
    transform: translateX(3.1rem);
  }
  .v-middle .custom-switch.custom-switch-text .custom-control-label:before{
    height: 30px;
  }
  .v-middle .custom-switch.custom-switch-text .custom-control-label:after{
    top: 3px;
    height: 24px;
    width: 24px;
  }
  .v-middle .custom-switch.custom-switch-text label:before{
    font-size: 13px;
    line-height: 25px;
  }
  .v-middle{
    vertical-align: middle !important;
  }
  .w250{
    width: 250px;
  }
  .f12{
    font-size: 13px;
    font-weight: normal
  }
</style>