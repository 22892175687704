<template>
  <input class="form-control" type="file" @input="updateInput" ref="file" multiple @change="updateFile" />
</template>

<script>
export default {
  name: 'my-file',
  props: {
    modelValue: [String, Array, Object],
  },
  
  methods: {
    updateFile(){
      this.$emit('update:modelValue', this.$refs.file.files[0])
    }
  }

}
</script>