import MyInput from '@/components/UI/MyInput'
import MyRange from '@/components/UI/MyRange'
import MyInputArray from '@/components/UI/MyInputArray'
import MyButton from '@/components/UI/MyButton'
import MySelect from '@/components/UI/MySelect'
import MySelectId from '@/components/UI/MySelectId'
import MyTextarea from '@/components/UI/MyTextarea'
import MyFile from '@/components/UI/MyFile'
import MyRequiredField from '@/components/UI/MyRequiredField'
import MyCheckboxVal from '@/components/UI/MyCheckboxVal'
import MyCheckbox from '@/components/UI/MyCheckbox'
import MyRadio from '@/components/UI/MyRadio'
import MyDate from '@/components/UI/MyDate'
import MySumWords from '@/components/UI/MySumWords'
import MyPagination from '@/components/UI/MyPagination'
import LoadingSmall from '@/components/UI/LoadingSmall'
import MySelectSelected from '@/components/UI/MySelectSelected'
import ImageUpload from '@/components/UI/ImageUpload'
import MyFooter from '@/components/UI/MyFooter'


export default [
  MyInput,
  MyRange,
  MyInputArray,
  MyButton,
  MySelect,
  MySelectId,
  MyTextarea,
  MyFile,
  MyRequiredField,
  MyCheckboxVal,
  MyCheckbox,
  MyRadio,
  MyDate,
  MySumWords,
  MyPagination,
  LoadingSmall,
  MySelectSelected,
  ImageUpload,
  MyFooter
]