<template>
  {{`${getDate} ${getMonth} ${getFullYear} г.`}}
</template>

<script>
export default {
  name: 'my-date',
  data() {
    return {
      getFullYear: '',
      getMonth: '',
      getDate: '',
      monthNames: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],

    }
  },
  props: {
    date: [String, Object],
  },

  methods: {
    showDate(){
      this.getDate = this.date.getDate()
      this.getMonth = this.monthNames[this.date.getMonth()]
      this.getFullYear = this.date.getFullYear()
    }
  },

  mounted(){
    this.showDate()
  }

}
</script>