import { axiosNeurnetworkCreateText, axiosNeurnetworkRewriteText, axiosNeurnetworkTextList, axiosCreateNeurnetworkToken } from '@/api/index'

export const neurnetworkModule = {
  state: () => ({
    loading: false,
    error: '',
    textList: [],
    createText: {
      textResult: '',
      text: '',
      keywords: '',
      symbols: 1000,
      resultSum: 0
    },
    rewriteText: {
      textResult: '',
      text: '',
      keywords: '',
      creative: 1,
      strong: false
    },
    page: 1,
    length: 0,
  }),

  getters: {
    
  },

  mutations: {
    setLoading(state, v){state.loading = v},
    setError(state, v){state.error = v},
    setCreateTextTextResult(state, v){state.createText.textResult = v},
    setCreateTextText(state, v){state.createText.text = v},
    setCreateTextKeywords(state, v){state.createText.keywords = v},
    setCreateTextSymbols(state, v){state.createText.symbols = Number(v)},
    setCreateTextResultSum(state, v){state.createText.resultSum = v},

    setRewriteTextTextResult(state, v){state.rewriteText.textResult = v},
    setRewriteTextText(state, v){state.rewriteText.text = v},
    setRewriteTextKeywords(state, v){state.rewriteText.keywords = v},
    setRewriteTextCreative(state, v){state.rewriteText.creative = Number(v)},
    setRewriteTextStrong(state, v){state.rewriteText.strong = v},

    setTextList(state, v){state.textList = v},
    setPage(state, v){state.page = v},
    setLength(state, v){state.length = v}
  },

  actions: {
    async actionNeurnetworkCreateText({state, commit, rootState, dispatch}){
      try {
        commit('setLoading', true)
        const data = {text: state.createText.text, keywords: state.createText.keywords, symbols: state.createText.symbols}
        const response = await axiosNeurnetworkCreateText(rootState.token, data)
        if(response.status === 'error'){
          commit('setError', response.message)
          setTimeout(function () {
            commit('setError', '')
          }, 3000);
          return
        }
        commit('setCreateTextTextResult', response.text)
        const text = response.text.length
        const resultSum = state.createText.symbols > text ? Math.ceil(text / 100) : state.createText.symbols / 100
        commit('setCreateTextResultSum', resultSum)
        commit('setCreateTextText', '')
        commit('setCreateTextKeywords', '')
        commit('setCreateTextSymbols', 1000)
        await dispatch('actionUser', null, { root: true });
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionNeurnetworkCreateText')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    async actionNeurnetworkRewriteText({state, commit, rootState, dispatch}){
      try {
        commit('setLoading', true)
        const data = {text: state.rewriteText.text, keywords: state.rewriteText.keywords, creative: state.rewriteText.creative, strong: state.rewriteText.strong ? 1 : 0}
        const response = await axiosNeurnetworkRewriteText(rootState.token, data)
        if(response.status === 'error'){
          commit('setError', response.message)
          setTimeout(function () {
            commit('setError', '')
          }, 3000);
          return
        }
        commit('setRewriteTextTextResult', response.text)
        commit('setRewriteTextText', '')
        commit('setRewriteTextKeywords', '')
        commit('setRewriteTextCreative', 1)
        commit('setRewriteTextStrong', false)
        await dispatch('actionUser', null, { root: true });
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionNeurnetworkRewriteText')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    async actionNeurnetworkTextList({state, commit, rootState, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosNeurnetworkTextList(rootState.token, state.page)
        commit('setTextList', response.texts)
        commit('setLength', response.length)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionNeurnetworkTextList')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    async actionCreateNeurnetworkToken({commit, rootState, dispatch}){
      try {
        commit('setLoading', true)
        await axiosCreateNeurnetworkToken(rootState.token)
        await dispatch('actionUser', null, { root: true });
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionCreateNeurnetworkToken')
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    }
    
  },
  namespaced: true
}