<template>
  <ul v-if="!tariffSuppliers.length" class="list-style-3 mb-0">
    <li class="p-3 list-item d-flex justify-content-start align-items-center">Список выбранных поставщиков пуст.</li>
  </ul>
  <ul v-for="supplier in tariffSuppliers" :key="supplier.id" class="list-style-3 mb-0">
    <li class="p-3 list-item d-flex justify-content-start align-items-center">
      <div class="avatar">
        <img v-if="supplier.image" class="avatar avatar-img avatar-60 rounded" alt="user-icon" :src="`https://start-commerce.ru/u${supplier.id_user}/supplier/${supplier.image}`" />
        <i v-else class="fa fa-image i-img2"></i>                     
      </div>
      <div class="list-style-detail ml-3 mr-2">
        <p class="mb-0">{{supplier.name}}</p>
      </div>
      <div class="list-style-action d-flex justify-content-end ml-auto">                        
        <h6 class="font-weight-bold"><i class="fa fa-close clink-danger" @click="actionRemovePackageSuppliers(supplier.id)"></i></h6>                        
      </div>
    </li>
  </ul>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
  name: 'package-suppliers-home',
  methods: {
    ...mapActions({actionRemovePackageSuppliers: 'actionRemovePackageSuppliers'}),

    
  },
  computed: {
    ...mapState({
      tariffSuppliers: state => state.tariffSuppliers
    }),
  },


}
</script>
<style>
  .clink-danger{
    color: red;
    cursor: pointer
  }
</style>
