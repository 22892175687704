<template>
  <div class="alert alert-primary" role="alert">
    <div class="iq-alert-text">
      <h5><b>Если вы хотите атоматизировать загрузку прайс-листов от ваших поставщиков, а также обновлять цены и остатки с помощью нашего API, необходимо подключить прайс-листы ваших поставщиков к вашим каталогам, один каталог — один прайс поставщика.</b></h5><br>
        <h4 class="mb-3"><b><i>С чего начать:</i></b></h4>
        <ul>
          <li><em>Создайте каталоги кнопкой выше, один каталог это один поставщик, если у поставщика несколько прайс-листов, один каталог, один прайс-лист</em></li>
          <li><em>Обратитесь к нам, чтобы мы подключили ваших поставщиков;</em></li>
        </ul>
        <p><b><i>Мы можем забирать прайс-листы у поставщиков любым удобным способом:</i></b></p>
      <ul>
        <li><em>По ссылке от поставщика в yml, xml формате;</em></li>
        <li><em>Скачивать прайс-листы с сайта поставщика (если прайс-листы находятся в личном кабинете поставщика, от вас потребуются регистрационные данные к личному кабинету);</em></li>
        <li><em>Скачивать прайс-листы с облачного диска (Яндекс, Google диск);</em></li>
        <li><em>Вы можете отправлять прайс-листы на нашу электронную почту;</em></li>
        <li><em>Если у поставщика есть свой API, мы можем подключится к нему и собирать данные оттуда;</em></li>
        <li><em>Парсить сайт поставщика для сбора цен и остатков товара.</em></li>
      </ul>
      <p class="text-prev"><i>Все вышеперечисленные процедуры по загрузке прайс-листов являются полностью автоматизированными, что обеспечивает быструю загрузку и готовность получать данные через наш API в самое короткое время.</i></p>
      <hr>
      <p><b>Для подключения ваших поставщиков обратитесь в нашу тех. поддержку:<br>
        Электронная почта: <a href="mailto:info@startcommerce.ru">info@startcommerce.ru</a><br>Телефон для связи: <a href="tel:+79950993928">+7 (995) 099-39-28</a> (Whatsapp, Telegram) </b></p>
    </div>
  </div>  
</template>


<script>
export default {
  name: 'inform-supplier',
}
</script>
<style>
 
</style>