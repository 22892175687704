

export const suppliersModule = {
  state: () => ({
   
  }),

  mutations: {
    
  },

  actions: {

  
  },
  namespaced: true
}