<template>
  <div class="wrapper">
    <div><router-view></router-view></div>
  </div>
</template>

<script>
import Auth from '@/pages/Auth.vue'
export default {
  components: {
    Auth
  },
}
</script>

