<template>
  <form @submit.prevent>
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <div v-if="errors.length">
            <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errors">{{error}}</p>
          </div>
          <div v-if="message">
            <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
          </div>
          <div v-if="!message">
            <label class="text-secondary">Пароль</label>
            <my-input
              :model-value="resetPassword.password" @update:model-value="setResetPasswordPass"
              type="password"
              required
              placeholder="*********"
            />
          </div>
        </div>
      </div>
      <div v-if="!message" class="col-lg-12 mt-2">
        <div class="form-group">
          <div class="d-flex justify-content-between align-items-center">
            <label class="text-secondary">Повторите пароль</label>
          </div>
          <my-input 
            :model-value="resetPassword.repeatPassword" @update:model-value="setResetPasswordRepeatPass"
            type="password"
            placeholder="*********"
          />
        </div>
      </div>
    </div>
    <div v-if="!message">
      <my-button v-if="!message" type="submit" class="btn-primary btn-block mt-2" @click="actionResetPassword">Создать</my-button>
    </div>
    <div v-else>
      <router-link to="/login" class="btn btn-primary btn-block mt-2"><span>Авторизоваться</span></router-link>
    </div>
  </form>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'reset-password-form',
  methods: {
    ...mapMutations(['setResetPasswordPass', 'setResetPasswordRepeatPass', 'setErrors', 'setMessage']),

    ...mapActions({actionLinkPassword: 'actionLinkPassword', actionResetPassword: 'actionResetPassword'}),
    
  },
  computed: {
    ...mapState({
      resetPassword: state => state.resetPassword,
      errors: state => state.errors,
      message: state => state.message
    }),
  },

  mounted(){
    this.actionLinkPassword(this.$route.params.link);
  },

  unmounted(){
    this.setErrors('')
    this.setMessage('')
  }
}
</script>