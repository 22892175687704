<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Реквизиты для оплаты</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <div class="header-title">
                <h4 class="card-title">Реквизиты для оплаты по безналичному расчету</h4>
              </div>
            </div>
            <div class="card-body">
              <requisites-yur-form />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import RequisitesYurForm from '@/components/RequisitesYurForm'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    RequisitesYurForm
  },


  computed: {
    ...mapState({
      message: state => state.message
    })
  },

  mounted(){
    document.title = "Реквизиты для оплаты по безналичному расчету - StartCommerce"
  }
}
</script>