<template>
  <form enctype="multipart/form-data" @submit.prevent>
    <div class="row">
      <div v-if="errors.length" class="form-group col-12">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errors" :key="error">{{error}}</p>
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="pwd">Наименование каталога: <my-required-field /></label>
        <my-input
          :model-value="supp.name" @update:model-value="setSuppName"
          type="text"
          required
          placeholder="Например, Опт МСК"  
        />
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="customFile">Регион:</label>
        <my-input
          :model-value="supp.city" @update:model-value="setCity"
          type="text"
          placeholder="Москва"
        />
      </div>
      <div class="form-group col-12">
        <label class="" for="customFile">Комментарий:</label>
        <my-textarea
          :model-value="supp.description" @update:model-value="setDescription"
        />
      </div>
    </div>
     <my-button v-if="!supp.id" type="submit" class="btn btn-primary mr-2" @click="actionSaveSupplier">Создать</my-button>
     <my-button v-else type="submit" class="btn btn-primary mr-2" @click="actionEditSupplier">Сохранить</my-button>
  </form>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'supplier-account-form',
  methods: {
    ...mapMutations({setSuppName: 'supplier/setSuppName', setCity: 'supplier/setCity', setDescription: 'supplier/setDescription', setSupp: 'supplier/setSupp'}),

    ...mapActions({actionSaveSupplier: 'supplier/actionSaveSupplier', actionEditSupplier: 'supplier/actionEditSupplier'}),

    updateCategories(e){
      let categories = this.supp.categories
      if(e.status === true){
        categories.push(e.val)
      } else {
        categories = categories.filter(v => v !== e.val)
      }
      this.$store.commit('supplier/setCategories', categories)
    },
    
  },
  computed: {
    ...mapState({
      supp: state => state.supplier.supp,
      errors: state => state.supplier.errors,
    }),
  },
  unmounted(){
    this.setSupp({id: 0, name: '', description: '', city: ''})
  }
}
</script>

<style>
.hover:after{
  content: attr(data-title);
  display: none;
  position: absolute;
  bottom: 130%;
  left: 0px;
  color: #3aaeda;
  padding: 5px;
  text-align: center;
  -moz-box-shadow: 0 1px 1px rgba(0,0,0,.16);
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.16);
  box-shadow: 0 1px 1px rgba(0,0,0,.16);font-size: 12px;}
  .hover:hover:after{display: block;
  }
  .file-inp{
    line-height:1.5
  }
  .site{
    margin-right:10px;
    font-weight: bold;
  }
  .alert2{
    border: none;
    display: block;
  }
  .color-red{
    color: red;
    cursor: pointer;
    position: relative;
    top:-8px;
    font-size: 12px;
  }
  .img{
    width: 120px;
  }
  .i-img{
    font-size:90px;
    color: #db7e06;
  }
  #multiple{position: absolute; background-color: #FFF; z-index: 999;}
  .cat-bot{margin-bottom: 10px}
  @media (max-width: 440px) {
    .cat-bot{width:100%}
  }
</style>