<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Цены нейросети</h4>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="list-group">
                <a href="#" target="_self" class="list-group-item active list-group-item-action active">Генерация текста</a>
                <a href="#" target="_self" class="list-group-item list-group-item-action"><b>Описание товара:</b> 1 рубль = 100 символов</a>
                <a href="#" target="_self" class="list-group-item list-group-item-action"><b>Рерайт текста:</b> 1 рубль = 100 символов</a>
              </div>            
            </div>
          </div>
        </div>
        <div class="col-sm-12 mt-5">
          <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Тарифы для автоматизации прайс-листов поставщиков и обновления данных по API</h4>
            </div>
          </div>
          <div class="">
            <inform-tariff />
          </div>
          <div class="card">
            <div class="card-body">
              <tariff />             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import Tariff from '@/components/Tariff'
import InformTariff from '@/components/InformTariff.vue'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    Tariff,
    InformTariff
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  },

  computed: {
    ...mapState({
      
    }),
  },

  mounted(){
    document.title = "Тарифы - StartCommerce"
  }
  
}
</script>