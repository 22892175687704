<template>
  <table class="table data-table mb-0 dataTable no-footer">
    <thead class="table-color-heading">
      <tr class="white-space-no-wrap">
        <td colspan="5"><h6 class="mb-0 text-uppercase text-secondary">Выбранные каталоги (поставщики)</h6></td>
      </tr>
    </thead>
    <tbody>
      <tr v-if="!tariffSuppliers.length" class="list-style-3 mb-0">
        <td colspan="5" class="p-3 list-item d-flex justify-content-start align-items-center">Список выбранных каталогов пуст.</td>
      </tr>
      <tr v-for="supplier in tariffSuppliers" :key="supplier.id">
        <td class="w30">                      
          <h5 class="text-center"><i class="fa fa-close clink-danger" @click="actionRemovePackageSuppliers(supplier.id)"></i></h5>                 
        </td>
        <td class="p-3 list-item d-flex justify-content-start align-items-center">
          <div class="avatar">
            <img v-if="supplier.image" class="avatar avatar-img avatar-60 rounded" alt="user-icon" :src="`https://start-commerce.ru/u${supplier.id_user}/supplier/${supplier.image}`" />
            <i v-else class="fa fa-image i-img2"></i>                     
          </div>
          <div class="list-style-detail ml-3 mr-2">
            <p class="mb-0">{{supplier.name}}</p>
          </div>
        </td>
        <td><div v-for="category in supplier.categories" :key="category">{{category}}</div></td>
        <td><div v-if="supplier.region">{{supplier.region}}.</div> <div v-if="supplier.city">г. {{supplier.city}}.</div></td>
      </tr>
      <tr class="list-style-3 mb-0">
        <td colspan="5" class="text-center pt-3 pb-3">
          <router-link to="/catalogs" class="btn btn-secondary">Добавить каталог</router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
  name: 'package-suppliers',
  methods: {
    ...mapActions({actionRemovePackageSuppliers: 'actionRemovePackageSuppliers'}),

    
  },
  computed: {
    ...mapState({
      tariffSuppliers: state => state.tariffSuppliers
    }),
  },


}
</script>
<style>
  .clink-danger{
    color: red;
    cursor: pointer
  }
  .w30{
    width: 30px;
  }
</style>
