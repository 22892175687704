<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Как подключить магазин</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Как подключить магазин</h4>
            </div>                     
          </div>
        </div>
        <div class="row col-lg-12">
          <div class="col-sm-6 col-lg-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Получать данные по API</h5>
                <p class="card-text">Вы можете настроить api самостоятельно или обратиться к нам за помощью.</p>
                <a href="https://start-commerce.ru/api/docs" target="_blank" class="btn btn-primary btn-block">Перейти в инструкцию API</a>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Готовые модули и плагины для вашей CMS</h5>
                <p class="card-text">Модули по обновлению цени и остатков по API: </p>
                <p><a href="https://marketplace.1c-bitrix.ru/solutions/startcommercio.api/" target="_blank">1-C Битрикс</a></p>
                <p><a href="https://www.webasyst.ru/store/plugin/shop/startcommerce/" target="_blank">Webasyst (Shop-Script)</a></p>
              </div>
            </div>
          </div>
         </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
export default {
  components: {
    Header,
  },

  mounted(){
    document.title = "Как подключить магазин - StartCommerce"
  }
}
</script>