<template>
  <form @submit.prevent>
    <div class="row">
      <div v-if="errors.length" class="form-group col-12">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errors">{{error}}</p>
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="pwd">Название прайс-листа: <my-required-field /></label>
        <my-input
          :model-value="price.name" @update:model-value="setPriceName"
          type="text"
          required
          placeholder="Например, Москва Хант"
        />
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="pwd">Код поставщика <my-required-field /></label>
        <my-select
          class="custom-select"
          :model-value="price.code"
          @update:model-value="setPriceCode"
          :options="options"
        />
      </div>
      <div class="form-group col-12">
        <label for="pwd">Cсылка на файл <my-required-field /></label>
        <my-input
          :model-value="price.url" @update:model-value="setPriceUrl"
          type="text"
        />
      </div>
    </div>
     <my-button type="submit" class="btn btn-primary mr-2" @click="actionSavePrice">Сохранить</my-button>
  </form>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'upload-price-form',
  methods: {
    ...mapMutations({
      setPriceName: 'supplier/setPriceName', 
      setPriceUrl:'supplier/setPriceUrl',
      setPriceCode: 'supplier/setPriceCode',
      setPriceDescription: 'supplier/setPriceDescription'}),

    ...mapActions({actionSavePrice: 'supplier/actionSavePrice'}),
  },

  computed: {
    ...mapState({
      price: state => state.supplier.price,
      errors: state => state.supplier.errors,
      options: state => state.suppliers.map(p => {return {'name': `${p.code}: (${p.name})`}})
    }),
  }
}
</script>

<style>

</style>