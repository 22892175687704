<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Мои тексты</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Мои тексты</h4>
                </div>
                <div class="create-workform">
                  <div class="d-flex flex-wrap align-items-center justify-content-between">
                    <div class="modal-product-search d-flex">
                      <router-link to="/create-text-product" class="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mr-2"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg> Создать описание товара
                      </router-link>
                    </div>
                  </div>
                </div>  
              </div>

              <div class="card">
                <text-list :textList="textList" :loading="loading"/>
              </div> 
            </div>
            <div v-if="!loading && length > 1" class="col-lg-12">
              <pagination :pages="Math.ceil(length)" :model-value="page" @update:model-value="editPage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import TextList from '@/components/TextList'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    TextList,
  },
  methods: {
    ...mapMutations({setTextList: 'neurnetwork/setTextList', setPage: 'neurnetwork/setPage'}),
    ...mapActions({actionNeurnetworkTextList: 'neurnetwork/actionNeurnetworkTextList'}),

    editPage(e){
      this.setPage(e)
      this.actionNeurnetworkTextList()
    }
  },

  computed: {

    ...mapState({
      textList: state => state.neurnetwork.textList,
      loading: state => state.neurnetwork.loading, 
      
      limit: state => state.neurnetwork.limit,
      page: state => state.neurnetwork.page,
      length: state => state.neurnetwork.length
    }),
  },

  mounted(){
    this.actionNeurnetworkTextList();
    document.title = 'Мои тексты - StartCommerce"'
  },

  unmounted(){
    this.setTextList([])
  }
}
</script>