import { createStore } from 'vuex'
import { suppliersModule } from '@/store/suppliersModule'
import { supplierModule } from '@/store/supplierModule'
import { ordersModule } from '@/store/ordersModule'
import { balanceHistoryModule } from '@/store/balanceHistoryModule'
import { productsModule } from '@/store/productsModule'
import { categoriesModule } from '@/store/categoriesModule'
import { neurnetworkModule } from '@/store/neurnetworkModule'
import { partnerModule } from '@/store/partnerModule'
import { axiosReg, axiosLogout, axiosLogin, axiosAuth, axiosUser, axiosRecover, axiosActivation, axiosLinkPassword, axiosResetPassword, axiosSupplier, axiosEditUser, axiosEditPassword, axiosRequisites, axiosEditSelectedProviders, axiosTariff, axiosTariffSuppliers, axiosCheckTariff, axiosPaymentTariff, axiosTopUpBalance, axiosClosesTariff, axiosEditRole, axiosRemovePackageSuppliers, axiosAddAdditionalSupplier, axiosGetTariff, axiosPaymentDopSuppliers, axiosTemporaryPayment, axiosTemporaryPeriod } from '@/api/index'
import router from "@/router";
import { validEmail } from '@/assets/validForm'
import VueCookies from 'vue-cookies'

export default createStore({
  state: {
    isAuth: false,
    loading: false,
    loadingSmall: false,
    token: '',
    reg: {name: '', email: '', promocode: '', ref: '', password: '', repeatPassword: '', role: 'Магазин'},
    login: {email: '', password: ''},
    user: [],
    suppliers: [],
    recoverEmail: '',
    resetPassword: {password: '', repeatPassword: ''},
    editPassword: {oldPassword: '', newPassword: '', repeatPassword: ''},
    resetPasswordLink: '',
    tariffSuppliers: [],
    image: null,
    sumTopUpBalance: 100,
    tariff: [],
    numbering: [{id: 30, name: 30}, {id: 60, name: 60}, {id: 100, name: 100}, {id: 150, name: 150}, {id: 200, name: 200}],
    temporary: {
      selectedProviders: [],
      tariff: '',
      packageSuppliersId: 0,
      paymentTariff: {},
      upBalanceParam: '',
			upBalanceParamMetod: 'bank_card',
      string: '',
      payment: false,
      period: false,
      num: 0
    },
    payment: {
      options: [
        {'id': 0, 'name': 'На месяц'},
        {'id': 1, 'name': 'На год'}
      ],
      option: 'На месяц',
      percent: 0,
      discount: 0,
      sum: 0,
      tariffSum: 0,
      dopPriceSupplier: 0,
      paymentMethod: 'Безнал',
      balance: 0
    },
    modal: false,
    img: null,
    errors: [],
    errorsPass: [],
    errorsRequisites: [],
    errorsBankRequisites: [],
    message: '',
    error: '',
    neuro: false
  },
  getters: {
    
  },
  mutations: {
    setNeuro(state, v){state.neuro = !state.neuro},
    setEditPassword(state, v){state.editPassword = v},
    setErrorsRequisites(state, v){state.errorsRequisites = v},
    setErrorsBankRequisites(state, v){state.errorsBankRequisites = v},
    setErrors(state, error){state.errors = error},
    setError(state, error){state.error = error},
    setErrorsPass(state, error){state.errorsPass = error},
    setMessage(state, message){state.message = message},
    setRegName(state, name){state.reg.name = name},
    setRegEmail(state, email){state.reg.email = email},
    setRegRef(state, ref){state.reg.ref = ref},
    setRegPromocode(state, promocode){state.reg.promocode = promocode},
    setRegRole(state, role){state.reg.role = role},
    setRegPassword(state, password){state.reg.password = password},
    setRegRepeatPassword(state, repeatPassword){state.reg.repeatPassword = repeatPassword},
    setLoading(state, loading){state.loading = loading},
    setLoadingSmall(state, loading){state.loadingSmall = loading},
    setLoginEmail(state, email) {state.login.email = email},
    setLoginPassword(state, password) {state.login.password = password},
    setIsAuth(state, isAuth) {state.isAuth = isAuth},
    setToken(state, token) {state.token = token},
    setUser(state, user) {state.user = user},
    setSuppliers(state, supplier) {state.suppliers = supplier},
    setRecoverEmail(state, email) {state.recoverEmail = email},
    setResetPasswordPass(state, pass) {state.resetPassword.password = pass},
    setResetPasswordRepeatPass(state, pass) {state.resetPassword.repeatPassword = pass},
    setResetPasswordLink(state, link) {state.resetPasswordLink = link},
    setOldPassword(state, password) {state.editPassword.oldPassword = password},
    setNewPassword(state, password) {state.editPassword.newPassword = password},
    setRepeatPassword(state, password) {state.editPassword.repeatPassword = password},
    setImage(state, v){state.image = v},
    setEditName(state, v){state.user.name = v},
    setEditPhone(state, v){state.user.phone = v},
    setEditEmail(state, v){state.user.email = v},
    setUserImage(state, v){state.user.image = v},
    setRequisitesName(state, v){state.user.requisites_yur.requisites.name = v},
    setRequisitesAddress(state, v){state.user.requisites_yur.requisites.address = v},
    setRequisitesCity(state, v){state.user.requisites_yur.requisites.city = v},
    setRequisitesRegion(state, v){state.user.requisites_yur.requisites.region = v},
    setRequisitesPostcode(state, v){state.user.requisites_yur.requisites.postcode = v},
    setRequisitesInn(state, v){state.user.requisites_yur.requisites.inn = v},
    setRequisitesKpp(state, v){state.user.requisites_yur.requisites.kpp = v},
    setRequisitesRs(state, v){state.user.requisites_yur.requisites.rs = v},
    setRequisitesBank(state, v){state.user.requisites_yur.requisites.bank = v},
    setRequisitesBic(state, v){state.user.requisites_yur.requisites.bic = v},
    setRequisitesKs(state, v){state.user.requisites_yur.requisites.ks = v},
    setSelectedProviders(state, v){state.user.selected_providers = v},
    setUserPackage(state, v){state.user.user_package = v},
    setTariffSuppliers(state, v){state.tariffSuppliers = v},
    setPayment(state, v){state.user.payment = v},
    setBalance(state, v){state.user.balance.account = v},
    setSumTopUpBalance(state, v){state.sumTopUpBalance = v},
    setTopUpBalance(state, v){state.user.top_up_balance = v},
    setSelectedProvidersData(state, v){state.temporary.selectedProviders = v},
    setTemporaryTariff(state, v){state.temporary.tariff = v},
    setTemporaryPackageSuppliersId(state, v){state.temporary.packageSuppliersId = v},
    setTemporaryPaymentTariff(state, v){state.temporary.paymentTariff = v},
    setTemporaryUpBalanceParam(state, v){state.temporary.upBalanceParam = v},
		setTemporaryUpBalanceParamMetod(state, v){state.temporary.upBalanceParamMetod = v},
    setTemporaryPayment(state, v){state.temporary.payment = v},
    setTemporaryPeriod(state, v){state.temporary.period = v},
    setTemporaryNum(state, v){state.temporary.num = v},
    setUserRole(state, v){state.user.role = v},
    setModal(state){state.modal = !state.modal},
    setImg(state, v){state.img = v},
    setTemporaryString(state, v){state.temporary.string = v},
    setTariff(state, v){state.tariff = v},
    setPaymentObj(state, v){state.payment = v},
  },

  actions: {
    async actionSupplier({commit, state, dispatch}){
      try {
        const response = await axiosSupplier(state.token)
        commit('setSuppliers', response)
      } catch (e) {
        if(e.response.data.statusCode === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionSupplier')
        }
        console.log(e.response.data)
      }
    },

    async actionTariffSuppliers({commit, state}){
      try {
        const data = JSON.stringify({'id': state.user.selected_providers})
        const response = await axiosTariffSuppliers(state.token, data)
        commit('setTariffSuppliers', response)
      } catch (e) {
        console.log(e.response.data)
      }
    },

    async actionReg({state, commit, dispatch}) {
      commit('setRegRef', VueCookies.get('ref_code'))
      //console.log(state.reg.ref)
      //return
      let errors = []
      if(!state.reg.name){errors.push('Укажите имя.')}

      if(!state.reg.email){errors.push('Укажите электронную почту.')} 
      else if (!validEmail(state.reg.email)){errors.push('Укажите корректный адрес электронной почты.')}

      if (!state.reg.password){errors.push('Укажите пароль.')
      } else if(state.reg.password.length < 6){errors.push('Пароль должен быть не менее 6 символов.')}

      if (!state.reg.repeatPassword){errors.push('Повторите пароль.')
      } else if (state.reg.password != state.reg.repeatPassword){errors.push('Повторный пароль введен неверно.')}

      if(errors.length){
        commit('setErrors', errors)
        return
      }
      commit('setErrors', '')

      try{
        commit('setLoading', true)
        const data = {name: state.reg.name, email: state.reg.email, password: state.reg.password, ref: state.reg.ref, promocode: state.reg.promocode}
        const response = await axiosReg(data)
        console.log(response)
        commit('setToken', response.token)
        commit('setIsAuth', true)
        await dispatch('actionUser');
        await dispatch('actionGetTariff')
        await dispatch('actionSupplier')
        await dispatch('actionCheckTariff')
        router.push('/feed')
      } catch (e) {
        if(e.response.status === 400){
          errors.push(e.response.data.message)
        } else {
          errors.push(e.response.data)
        }
        commit('setErrors', errors)
      } finally {
        commit('setLoading', false)
      }
    },
    
    
    async actionLogin({state, commit, dispatch}) {
      let errors = []

      if(!state.login.email){errors.push('Укажите электронную почту.')
      } else if (!validEmail(state.login.email)) {errors.push('Укажите корректный адрес электронной почты.')}

      if (!state.login.password){errors.push('Укажите пароль.')
      } else if(state.login.password.length < 6){errors.push('Пароль должен быть не менее 6 символов.')}

      if(errors.length){
        commit('setErrors', errors)
        return
      }
      commit('setErrors', '')

      try{
        commit('setLoading', true)
        const response = await axiosLogin(state.login)
        commit('setToken', response.token)
        commit('setIsAuth', true)
        await dispatch('actionUser');
        await dispatch('actionGetTariff')
        await dispatch('actionSupplier')
        await dispatch('actionCheckTariff')
        router.push('/feed')
      } catch (e) {
        if(e.response.status === 401){
          errors.push(e.response.data.message)
        } else {
          errors.push(e.response.data)
        }
        commit('setErrors', errors)
      } finally {
        commit('setLoading', false)
      }
    },

    async actionAuthAccess({commit}){
      try {
        const response = await axiosAuth()
        commit('setToken', response.token)
        commit('setIsAuth', true)
      } catch (e) {
        console.log(e.response.data)
      }
    },

    async actionAuthorization({commit, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosAuth()
        commit('setToken', response.token)
        commit('setIsAuth', true)
        await dispatch('actionUser');
        await dispatch('actionGetTariff')
        await dispatch('actionSupplier')
        await dispatch('actionCheckTariff')
        
        router.push('/feed')
      } catch (e) {
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    async actionAuth({commit, dispatch}, link){
      try {
        commit('setLoading', true)
        const response = await axiosAuth()
        commit('setToken', response.token)
        commit('setIsAuth', true)
        await dispatch('actionUser');
        await dispatch('actionGetTariff')
        await dispatch('actionSupplier')
        await dispatch('actionCheckTariff')
        if(link){
          router.push(link)
        } else {
          router.push('/feed')
        }
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      } catch (e) {
        router.push('/login')
        console.log(link)
        console.log(e.response.data)
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      } finally {
  
      }
    },

    async actionLogout({commit}){
      try {
        commit('setLoading', true)
        await axiosLogout()
        commit('setToken', '')
        commit('setIsAuth', false)
        router.push('/login')
      } catch (e) {
        console.log(e.response.data)
      } finally {
        commit('setLoading', false)
      }
    },

    async actionUser({state, commit, dispatch}, link){
      try {
        commit('setLoading', true)
        const response = await axiosUser(state.token)
        commit('setUser', response)
        commit('setIsAuth', true)
        await dispatch('actionTariffSuppliers')
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      } catch (e) {
        if(e.response.data.statusCode === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionUser')
        }
        console.log(e.response.data)
      }
    },

    async actionRecover({state, commit}){
        const errors= []
        if(!state.recoverEmail){errors.push('Укажите электронную почту.')
        } else if (!validEmail(state.recoverEmail)) {errors.push('Укажите корректный адрес электронной почты.')}

        if(errors.length){
          commit('setErrors', errors)
          return
        }
        commit('setErrors', '')
        
        try {
          commit('setLoading', true)
          const response = await axiosRecover(state.recoverEmail)
          commit('setMessage', response.message)
        } catch (e) {
          if(e.response.status === 400){
            errors.push(e.response.data.message)
          } else {
            console.log(e.response.data)
          }
          commit('setErrors', errors)
        } finally {
          commit('setLoading', false)
        }      
    },

    async actionActivation({commit}, link){
      try {
        commit('setLoading', true)
        await axiosActivation(link)
      } catch (e) {
        router.push('/404')
        console.log(e.response.data)
      } finally {
        commit('setLoading', false)
      }  
    },

    async actionLinkPassword({commit}, link){
      try {
        commit('setLoading', true)
        const response = await axiosLinkPassword(link)
        commit('setResetPasswordLink', response)
      } catch (e) {
        router.push('/404')
        console.log(e.response.data)
      } finally {
        commit('setLoading', false)
      }  
    },

    async actionResetPassword({state, commit}){
      let errors = []
      if (!state.resetPassword.password){errors.push('Укажите пароль.')
      } else if(state.resetPassword.password.length < 6){errors.push('Пароль должен быть не менее 6 символов.')}
      if (!state.resetPassword.repeatPassword){errors.push('Повторите пароль.')
      } else if (state.resetPassword.password != state.resetPassword.repeatPassword){errors.push('Повторный пароль введен неверно.')}

      if(errors.length){
        commit('setErrors', errors)
        return
      }
      commit('setErrors', '')

      try {
        commit('setLoading', true)
        const response = await axiosResetPassword({link: state.resetPasswordLink, password: state.resetPassword.password})
        commit('setMessage', response.message)
      } catch (e) {
        errors.push(e.response.data)
        commit('setErrors', errors)
        console.log(e.response.data)
      } finally {
        commit('setLoading', false)
      }  
    },

    async actionEditRole({state, commit, dispatch}, bool){
      try {
        commit('setLoading', true)
        const role = bool ? 'Поставщик' : 'Магазин'
        const response = await axiosEditRole(state.token, role)
        commit('setUserRole', response)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionEditUser')
        } else {
          errors.push(e.response.data)
          console.log(e.response.data)
        }
      } finally {
        setTimeout(function () {
          commit('setLoading', false)
        }, 500);
      }
    },

    async actionEditUser({state, commit, dispatch}){
      let errors = []
      try {
        if(!state.user.name){errors.push('Укажите имя.')}

        if(!state.user.email){errors.push('Укажите электронную почту.')} 
        else if (!validEmail(state.user.email)){errors.push('Укажите корректный адрес электронной почты.')}

        if(errors.length){
          commit('setErrors', errors)
          return
        }
        commit('setErrors', '')
        const data = {'name': state.user.name, 'email': state.user.email, 'phone': state.user.phone}
        const response = await axiosEditUser(state.token, data, state.image)
        commit('setUserImage', response.user.image)
        commit('setMessage', response.message)
        commit('setImg', null)
        commit('setImage', '')
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
      } catch (e) {
        if(e.response.status === 400){
          errors.push(e.response.data.message)
        } else if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionEditUser')
        } else {
          errors.push(e.response.data)
        }
        commit('setErrors', errors)
        console.log(e.response.data)
      }
    },

    async actionEditPassword({state, commit}){
      let errors = []
      try {
        if (!state.editPassword.oldPassword){errors.push('Вы забыли указать старый пароль')}

        if (!state.editPassword.newPassword){errors.push('Укажите новый пароль')
        } else if(state.editPassword.newPassword.length < 6){errors.push('Новый пароль должен быть не менее 6 символов')}

        if(state.editPassword.newPassword === state.editPassword.oldPassword){errors.push('Новый пароль должен отличаться от старого')}

        if (!state.editPassword.repeatPassword){errors.push('Повторите пароль.')
        } else if (state.editPassword.newPassword != state.editPassword.repeatPassword){errors.push('Повторный пароль введен неверно')}

        if(errors.length){
          commit('setErrorsPass', errors)
          return
        }
        commit('setErrorsPass', '')
        const data = {'oldPassword': state.editPassword.oldPassword, 'newPassword': state.editPassword.newPassword}
        const response = await axiosEditPassword(state.token, data)
        commit('setMessage', response.message)
        commit('setEditPassword', [])
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
      } catch (e) {
        if(e.response.status === 400){
          errors.push(e.response.data.message)
        } else if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionEditPassword')
        } else {
          errors.push(e.response.data)
        }
        commit('setErrorsPass', errors)
        console.log(e.response.data)
      }
    },

    async actionRequisites({state, commit}){
      let errors = []
      try {
        if(!state.user.requisites_yur.requisites.name){errors.push('Поле "Название организации" не должно быть пустым')}
        if (!state.user.requisites_yur.requisites.address){errors.push('Поле "Адрес" не должно быть пустым')}
        if (!state.user.requisites_yur.requisites.city){errors.push('Поле "Город" не должно быть пустым')}
        if (!state.user.requisites_yur.requisites.postcode){errors.push('Поле "Почтовый индекс" не должно быть пустым')}
        if (!state.user.requisites_yur.requisites.inn){errors.push('Поле "ИНН" не должно быть пустым')}

        if(errors.length){
          commit('setErrorsRequisites', errors)
          return
        }
        commit('setErrorsRequisites', '')

        const response = await axiosRequisites(state.token, {"requisites": state.user.requisites_yur.requisites})
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionRequisites')
        } else {
          errors.push(e.response.data)
          commit('setErrorsRequisites', errors)
        }
        console.log(e.response.data)
      }
    },

    async actionBankRequisites({state, commit}){
      let errors = []
      try {
        if (!state.user.requisites_yur.requisites.rs){errors.push('Поле "Расчетный счет " не должно быть пустым')}
        if (!state.user.requisites_yur.requisites.bank){errors.push('Поле "Банк" не должно быть пустым')}
        if (!state.user.requisites_yur.requisites.bic){errors.push('Поле "БИК" не должно быть пустым')}
        if (!state.user.requisites_yur.requisites.ks){errors.push('Поле "Корр. счет" не должно быть пустым')}

        if(errors.length){
          commit('setErrorsBankRequisites', errors)
          return
        }
        commit('setErrorsBankRequisites', '')

        const response = await axiosRequisites(state.token, {"requisites": state.user.requisites_yur.requisites})
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionBankRequisites')
        } else {
          errors.push(e.response.data)
          commit('setErrorsBankRequisites', errors)
        }
        console.log(e.response.data)
      }
    },

    async actionEditSelectedProviders({state, commit, dispatch}){
      try {
        const response = await axiosEditSelectedProviders(state.token, {'selected_providers': state.temporary.selectedProviders})
        if(response.status === 'error'){
          commit('setError', response.message)
          setTimeout(function () {
            commit('setError', '')
          }, 4000);
        } else if(response.status === 'ok'){
          commit('setSelectedProviders', response.selected_providers)
          await dispatch('actionTariffSuppliers')
        }
      } catch (e) {
        if(e.response.data.statusCode === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionEditSelectedProviders')
        }
        console.log(e.response.data)
      }
    },

    // Получаем все тарифы
    async actionGetTariff({commit, dispatch}){
      try {
        const response = await axiosGetTariff()
        commit('setTariff', response)
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionGetTariff')
        }
        console.log(e.response.data)
      }
    },

    async actionTariff({state, commit, dispatch}, value){
      commit('setTemporaryTariff', value)
      try {
        const response = await axiosTariff(state.token, state.temporary.tariff)
        commit('setUserPackage', response.user_package)
        commit('setMessage', response.message)
        await dispatch('actionTariffSuppliers')
        router.push('/my-package')
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionTariff', state.temporary.tariff)
        }
        console.log(e.response.data)
      }
    },

    async actionClosesTariff({state, commit, dispatch}, value){
      commit('setTemporaryTariff', value)
      try {
        const response = await axiosClosesTariff(state.token, state.temporary.tariff)
        commit('setUserPackage', response.user_package)
        commit('setMessage', response.message)
        if(response.balance > 0){
          commit('setBalance', response.balance)
        }
        router.push('/my-package')
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionClosesTariff', state.temporary.tariff)
        }
        console.log(e.response.data)
      }
    },

    async actionRemovePackageSuppliers({state, commit, dispatch}, id){
      commit('setTemporaryPackageSuppliersId', id)
      try {
        const data = state.user.selected_providers.filter(v => Number(v) !== Number(state.temporary.packageSuppliersId))
        const response = await axiosRemovePackageSuppliers(state.token, {'selected_providers': data})
        commit('setSelectedProviders', response)
        await dispatch('actionTariffSuppliers')
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionRemovePackageSuppliers', state.temporary.packageSuppliersId)
        }
        console.log(e.response.data)
      }
    },

    async actionCheckTariff({state, commit, dispatch}){
      try {
        const response = await axiosCheckTariff(state.token)
        if(response.status == 'ok'){
          commit('setUserPackage', response.user_package)
        }
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionCheckTariff')
        }
        console.log(e.response.data)
      }
    },

    // + - дополнителных поставщиков
    async actionAddAdditionalSupplier({state, commit, dispatch}, status) {
      commit('setTemporaryString', status)
      try {
        const response = await axiosAddAdditionalSupplier(state.token, state.temporary.string)
        commit('setUserPackage', response.userPackage)
        commit('setTemporaryString', '')
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionAddAdditionalSupplier', state.temporary.string)
        }
        console.log(e.response.data)
      }
    },

    // Оплачиваем дополнительных поставщиков
    async actionPaymentDopSuppliers({state, commit, dispatch}){
      try {
        const response = await axiosPaymentDopSuppliers(state.token)
        if(response.status === 'ok'){
          commit('setUserPackage', response.userPackage)
          console.log(response.balance)
          commit('setBalance', response.balance)
          commit('setMessage', response.message)
          setTimeout(function () {
            commit('setMessage', '')
          }, 10000);
        }
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionPaymentDopSuppliers')
        }
        console.log(e.response.data)
      }
    },

    // Оплата тарифа
    async actionPaymentTariff({state, commit, dispatch}, data){
      commit('setTemporaryPaymentTariff', data)
      try {
       const response = await axiosPaymentTariff(state.token, state.temporary.paymentTariff)
       if(response.status === 'payment'){
        commit('setUserPackage', response.userPackage)
        commit('setPayment', response.payment)
        response.payment.payment_method === 'Безнал' ? router.push('/bank-transfer') : router.push('/thanks')
       } else if(response.status === 'balance'){
        commit('setUserPackage', response.userPackage)
        commit('setBalance', response.balance)
        router.push('/my-package')
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 2000);
       }
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionPaymentTariff', state.temporary.paymentTariff)
        }
        console.log(e.response.data)
      }
    },

    async actionOneYur({state, dispatch}){
      try {
        const response = await axiosRequisites(state.token, {"requisites": state.user.requisites_yur.requisites})
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionOneYur')
        }
        console.log(e.response.data)
      }
    },

    // Оплата баланса
    async actionTopUpBalance({state, commit, dispatch}, param){
      commit('setTemporaryUpBalanceParam', param)
      try {
        const data = {'payment_method': state.temporary.upBalanceParam, 'payment_method_data': state.temporary.upBalanceParamMetod, 'payable': state.sumTopUpBalance}
        const response = await axiosTopUpBalance(state.token, data);
        console.log(response)
        commit('setTopUpBalance', response.payment)
        response.payment.payment_method === 'Безнал' ? router.push('/invoice-balance') : window.location.href = response.url
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionTopUpBalance', state.temporary.upBalanceParam)
        }
        console.log(e.response.data)
      }
    },

    // Автоматическая оплата
    async actionTemporaryPayment({state, commit, dispatch}){
      try {
        commit('setLoadingSmall', true)
        const response = await axiosTemporaryPayment(state.token, state.temporary.payment);
        if(response.status === 'ok'){
          await dispatch('actionUser');
          commit('setMessage', response.message)
          setTimeout(function () {
            commit('setMessage', '')
          }, 5000);
        }

      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionTemporaryPayment', state.temporary.payment)
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoadingSmall', false)
        }, 500);
      }
    },

    async actionTemporaryPeriod({state, commit, dispatch}){
      try {
        commit('setLoadingSmall', true)
        const response = await axiosTemporaryPeriod(state.token, state.temporary.period);
        if(response.status === 'ok'){
          await dispatch('actionUser');
          commit('setMessage', response.message)
          setTimeout(function () {
            commit('setMessage', '')
          }, 5000);
        }

      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess')
          await dispatch('actionTemporaryPeriod', state.temporary.period)
        }
        console.log(e.response.data)
      } finally {
        setTimeout(function () {
          commit('setLoadingSmall', false)
        }, 500);
      }
    }

  },
  
  modules: {
    suppliersList: suppliersModule,
    supplier: supplierModule,
    orders: ordersModule,
    balanceHistory: balanceHistoryModule,
    products: productsModule,
    categories: categoriesModule,
    neurnetwork: neurnetworkModule,
    partner: partnerModule
  }
})
