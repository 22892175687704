<template>
  <input type="radio" :value="modelValue" @input="updateRadio" />
</template>

<script>
export default {
  name: 'my-radio',
  props: {
    modelValue: [String, Number],
  },

  methods: {
    updateRadio(event){
      console.log(event.target.value)
      this.$emit('update:modelValue', event.target.value)
    }
  }

}
</script>