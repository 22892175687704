<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
      </div>
      <div v-if="neurnetwork.error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{neurnetwork.error}}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Создать описание товара</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Создать описание товара</h4>
                </div> 
              </div>

              <div class="row">
                <div class="col-sm-12 col-lg-5">
                  <div class="card ">
                    <div class="card-header">
                      <div class="header-title"><h5 class="card-title">Генерация описания товара с помощью нейросети</h5></div>
                    </div>
                    <div class="card-body">
                      <div class="form-group">
                        <label>Введите краткое описание или название товара</label>
                        <my-textarea 
                          :model-value="neurnetwork.createText.text" @update:model-value="setCreateTextText"
                          class="form-text-neur"
                          maxlength="600"
                          placeholder="Видеокамера оптического прицела TriggerCam, предокулярная насадка, v 2.1, D 38-45 мм., 4k"
                          />
                      </div>

                      <div class="form-group">
                        <label>Дополнительные пожелания к нейросети (необязательно). <span class="text-secondary fz-13">Например, упомянуть ключевые слова или упомянуть нашу компанию "Название компании" и т. д. Все зависит от вашей фантазии</span></label>
                        <my-input
                          :model-value="neurnetwork.createText.keywords" @update:model-value="setCreateTextKeywords"
                          type="text"
                          maxlength="150"
                        />
                      </div>

                      <div class="form-group">
                        <label>Желаемое количество символов.</label><br>
                        <my-range 
                          :model-value="neurnetwork.createText.symbols" @update:model-value="setCreateTextSymbols"
                          :min="500" 
                          :max="3000" 
                          :step="100"
                        />
                        <div><span class="text-secondary">{{ neurnetwork.createText.symbols }} символов</span></div>
                      </div>
                      <my-button type="submit" class="btn btn-primary btn-block mt-5" @click="saveText">Сгенерировать текст за <span class="badge ml-2 badge-light">{{ neurnetwork.createText.symbols / 100 }}</span> руб.</my-button>
                    </div>
                    
                  </div>
                </div>

                <div class="col-sm-12 col-lg-7">
                  <div class="card ">
                    <div class="card-header d-flex justify-content-between">
                      <div class="header-title"><h5 class="card-title">Результат</h5></div>
                    </div>
                    <div class="card-body">
                      <div v-if="neurnetwork.loading">
                        <loading-small />
                        <div class="text-secondary text-center">Генерация займет некоторое количество времени</div>
                      </div>
                      <div >
                        <div v-html="neurnetwork.createText.textResult"></div>
                        <div class="mt-5" v-if="neurnetwork.createText.textResult">
                          <copy-text :text="neurnetwork.createText.textResult" name="Копировать" message="Текст скопирован в буфер обмена" />
                          <!--<router-link to="/text-rewrite" class="btn btn-primary btn-sm ml-5">Сделать рерайт</router-link>-->
                          <span class="text-secondary ml-3"> Текст: {{ neurnetwork.createText.textResult.length }}  символов</span>
                          <span class="text-danger ml-3"> ( - {{ neurnetwork.createText.resultSum }} руб.)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-lg-12">
                  <div class="alert alert-primary" role="alert">
                    <div class="iq-alert-text">
                      <h5><b>Памятка для генерации текста</b></h5><br>
                      <ul>
                        <li class="mb-2">Нейронная сеть обладает обширными знаниями, но иногда она также может выдумывать информацию, которая не соответствует действительности. Поэтому важно всегда проверять сгенерированную информацию на точность перед ее использованием.</li>
                        <li class="mb-2">При указании количества символов невозможно предсказать, сколько точно символов сгенерирует нейросеть в конечном тексте. Нейросеть остановится, когда сочтет нужным, но она учитывает этот параметр и, как правило, генерирует большее количество символов, чем вы установили.</li>
                        <li class="mb-2">Если в итоговом тексте количество символов будет меньше желаемого, с вашего баланса будет списана сумма меньше указанной, исходя из количества символов итогового текста.</li>
                        <li class="mb-2">Если в итоговом тексте количетсво символов будет превышать желаемое, с вашего баланса будет списана сумма, которая была изначально указана.</li>
                        <li class="mb-2">Каждый рубль эквивалентен 100 символам. Если символов меньше 100, сумма округляется в большую сторону.</li>
                        <li>Время генерации текста зависит от количества желаемых символов, но в среднем текст генерируется от 10 до 50 секунд. </li>
                      </ul>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import CopyText from '@/components/CopyText'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    CopyText
  },
  methods: {
    ...mapMutations({setCreateTextText: 'neurnetwork/setCreateTextText', setCreateTextKeywords: 'neurnetwork/setCreateTextKeywords', setCreateTextSymbols: 'neurnetwork/setCreateTextSymbols'}),
    ...mapActions({actionNeurnetworkCreateText: 'neurnetwork/actionNeurnetworkCreateText' }),

    saveText(){
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.actionNeurnetworkCreateText()
    }
  },

  computed: {

    ...mapState({
      neurnetwork: state => state.neurnetwork,
      message: state => state.message,
    }),
  },

  mounted(){
    //this.actionProductsList();
    document.title = 'Создание описания товара - StartCommerce"'
  },

  unmounted(){
    //this.setProductsList([])
  }
}
</script>

<style>
  .form-text-neur{
    line-height: 17px;
    min-height: 100px;
  }
  .fz-13{
    font-size: 13px;
  }
</style>