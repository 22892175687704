import { createRouter, createWebHistory } from 'vue-router'
import Auth from '@/pages/Auth'
import Home from '@/pages/Home'
import Registration from '@/pages/Registration'
import Login from '@/pages/Login'
import AuthRecover from '@/pages/AuthRecover'
import ResetPassword from '@/pages/ResetPassword'
import Activation from '@/pages/Activation'
import store from '@/store/index'
import Error404 from '@/pages/Error404'
import SuppliersList from '@/pages/users/SuppliersList'
import SupplierAccount from '@/pages/users/suppliers/SupplierAccount'
import HowConnect from '@/pages/users/HowConnect'
import MyPackage from '@/pages/users/MyPackage'
import Tariffs from '@/pages/users/Tariffs'
import SaveSupplier from '@/pages/users/suppliers/SaveSupplier'
import EditSupplier from '@/pages/users/suppliers/EditSupplier'
import OpenSupplier from '@/pages/users/suppliers/OpenSupplier'
import UploadPriceList from '@/pages/users/suppliers/UploadPriceList'
import MyProfile from '@/pages/users/MyProfile'
import RequisitesYur from '@/pages/users/RequisitesYur'
import ConnectedStores from '@/pages/users/suppliers/ConnectedStores'
import BankTransfer from '@/pages/users/BankTransfer'
import InvoiceBalance from '@/pages/users/InvoiceBalance'
import MyOrders from '@/pages/users/MyOrders'
import MyBalanceHistory from '@/pages/users/MyBalanceHistory'
import GiveAccessStores from '@/pages/users/suppliers/GiveAccessStores'
import RequestedAccessStores from '@/pages/users/suppliers/RequestedAccessStores'
import StoresAccessed from '@/pages/users/suppliers/StoresAccessed'
import ProductsList from '@/pages/users/products/ProductsList'
import Categories from '@/pages/users/products/Categories'
import Thanks from '@/pages/users/Thanks'
import Payment from '@/pages/users/Payment'
import Support from '@/pages/users/Support'
import Product from '@/pages/users/products/Product'
import TextProduct from '@/pages/users/neurnetwork/TextProduct'
import TextRewrite from '@/pages/users/neurnetwork/TextRewrite'
import MyText from '@/pages/users/neurnetwork/MyText'
import NeuralNetworkToken from '@/pages/users/neurnetwork/NeuralNetworkToken'
import PartnerInformation from '@/pages/users/partner/PartnerInformation'
import PartnerPromocode from '@/pages/users/partner/PartnerPromocode'
import PartnerReferrals from '@/pages/users/partner/PartnerReferrals'
import PartnerPayments from '@/pages/users/partner/PartnerPayments'
import PartnerReferralAccrual from '@/pages/users/partner/PartnerReferralAccrual'
import PartnerReferralPayments from '@/pages/users/partner/PartnerReferralPayments'



const routes = [
  {path: '/', name: 'Auth', component: Auth },
  {path: '/?ref=:ref', redirect: '/' },
  {path: '/:pathMatch(.*)*', name: 'Error404', component: Error404 },
  {path: '/feed', name: 'Home', component: Home},
  {path: '/registration', name: 'Registrations', component: Registration},
  {path: '/login', name: 'Login', component: Login},
  {path: '/auth-recover', name: 'AuthRecover', component: AuthRecover},
  {path: '/reset-password/:link', name: 'ResetPassword', component: ResetPassword},
  {path: '/activation/:link', name: 'Activation', component: Activation},
  {path: '/catalogs', name: 'SuppliersList', component: SuppliersList},
  {path: '/suppliers-account', name: 'SupplierAccount', component: SupplierAccount},
  {path: '/save-supplier', name: 'SaveSupplier', component: SaveSupplier},
  {path: '/edit-supplier/:id', name: 'EditSupplier', component: EditSupplier},
  {path: '/open-supplier/:id', name: 'OpenSupplier', component: OpenSupplier},
  {path: '/how-connect', name: 'HowConnect', component: HowConnect},
  {path: '/my-package', name: 'MyPackage', component: MyPackage},
  {path: '/tariffs', name: 'Tariffs', component: Tariffs},
  {path: '/upload-price-list', name: 'UploadPriceList', component: UploadPriceList},
  {path: '/my-profile', name: 'MyProfile', component: MyProfile},
  {path: '/payment-details-yur', name: 'RequisitesYur', component: RequisitesYur},
  {path: '/connected_stores', name: 'ConnectedStores', component: ConnectedStores},
  {path: '/give-access-stores', name: 'GiveAccessStores', component: GiveAccessStores},
  {path: '/requested-access-stores', name: 'RequestedAccessStores', component: RequestedAccessStores},
  {path: '/stores-accessed', name: 'StoresAccessed', component: StoresAccessed},
  {path: '/bank-transfer', name: 'BankTransfer', component: BankTransfer},
  {path: '/invoice-balance', name: 'InvoiceBalance', component: InvoiceBalance},
  {path: '/my-orders', name: 'MyOrders', component: MyOrders},
  {path: '/my-balance-history', name: 'MyBalanceHistory', component: MyBalanceHistory},
  {path: '/products', name: 'ProductsList', component: ProductsList},
  {path: '/categories', name: 'Categories', component: Categories},
  {path: '/thanks', name: 'Thanks', component: Thanks},
  {path: '/payment', name: 'Payment', component: Payment},
  {path: '/support', name: 'Support', component: Support},
  {path: '/product', name: 'Product', component: Product},
  {path: '/create-text-product', name: 'TextProduct', component: TextProduct},
  {path: '/text-rewrite', name: 'TextRewrite', component: TextRewrite},
  {path: '/my-text', name: 'MyText', component: MyText},
  {path: '/neural-network-token', name: 'NeuralNetworkToken', component: NeuralNetworkToken},
  {path: '/partner-information', name: 'PartnerInformation', component: PartnerInformation},
  {path: '/partner-promocode', name: 'PartnerPromocode', component: PartnerPromocode},
  {path: '/partner-referrals', name: 'PartnerReferrals', component: PartnerReferrals},
  {path: '/partner-payments', name: 'PartnerPayments', component: PartnerPayments},
  {path: '/partner-accrual', name: 'PartnerReferralAccrual', component: PartnerReferralAccrual},
  {path: '/partner-referral-payments', name: 'PartnerReferralPayments', component: PartnerReferralPayments},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  }
})


router.beforeEach(async (to, from) => {
   if (!store.state.isAuth && to.name !== 'Login' && to.name !== 'Auth' && to.name !== 'Registrations' && to.name !== 'AuthRecover' && to.name !== 'Activation' && to.name !== 'Error404' && to.name !== 'ResetPassword') {
    return { name: 'Auth' }
  } else if (store.state.isAuth && to.name === 'Login' || store.state.isAuth && to.name === 'Registrations' || store.state.isAuth && to.name === 'AuthRecover' || store.state.isAuth && to.name === 'ResetPassword') {
    return { name: 'Home' }
  }
})

export default router
