<template>
  <div v-if="loading" class="card-body"><loading-small /></div>
  <div v-else v-for="text in textList" :key="text.name" class="card-body">
    <div class="media mb-5">
      <div class="media-body">
        <h5 class="mt-0 mb-1">{{ text.name }}</h5>
        <div v-html="text.text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'text-list',
  props: {
    textList: Array,
    loading: Boolean
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),

    
  },

  computed: {
    ...mapState({}),
  },
}
</script>
<style>
  
</style>