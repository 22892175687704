<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <i class="fa fa-viber"></i>
              <h5><a class="text-danger" href="mailto:info@startcommerce.ru">info@startcommerce.ru</a></h5><br>
              <h5><a href="tel:+79950993928">+7 (995) 099-39-28</a> <span class="sc-icon"><a href="https://api.whatsapp.com/send/?phone=79950993928"><i class="fa fa-whatsapp"></i></a></span></h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
export default {
  components: {
    Header,
  },
  methods: {
  },

  computed: {
  },

  mounted(){
    document.title = "Поддержка - StartCommerce"
  },
  
}
</script>
<style>
  .sc-icon {
    margin-left:20px;
    font-size:25px
  }
</style>