<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Дать доступ к прайс-листам</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        
        <div class="col-lg-12">
          <div class="card card-block">
            <div class="card-header d-flex justify-content-between pb-0">
              <div class="header-title">
              <h4 class="card-title mb-0">Дать доступ магазину к закрытым прайс-листам</h4>
              </div>
            </div>
            <div class="card-body">
              <p>Вы можете дать доступ магазину к закрытым прайс-листам, ниже в строке поиска введите электронную почту пользователя</p>
              <div v-if="errors.length" class="form-group col-12">
                <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errors">{{error}}</p>
              </div>
              <div v-if="errorSearchInput" class="form-group col-12">
                <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{errorSearchInput}}</p>
              </div>
              <div class="form-row mb-3">
                <div class="col-md-6">
                  <my-input 
                  :model-value="inputAccessUser"
                  @update:model-value="setInputAccessUser"
                  type="text"
                  />
                </div>
                <div class="col-md-1">
                  <my-button class="btn btn-warning btn-with-icon" @click="actionSearchUser">Найти</my-button>
                </div>
              </div>
              <table v-if="searchUser.email" class="table table-bordered table-responsive-md table-striped text-center">
                <tbody>
                  <tr>
                    <td>
                      <div class="h-avatar is-medium">
                        <img class="avatar rounded-circle" v-if="searchUser.image" :src="`https://start-commerce.ru/u${searchUser.id}/user/${searchUser.image}`" />
                        <img class="avatar rounded-circle" v-else src="/images/user/1.jpg" />
                      </div>
                    </td>
                    <td contenteditable="true">{{searchUser.name}}</td>
                    <td contenteditable="true">{{searchUser.email}}</td>
                    <td v-if="searchUser.phone" contenteditable="true">{{searchUser.phone}}</td>
                    <td class="text-left">
                      <div v-if="statusSupplier[0].status_price || (statusSupplier[0].status_price_sum && statusSupplier[0].status_price_sum_opt && statusSupplier[0].status_price_count)" class="text-success">Доступ разрешен ко всем данным прайс-листа</div>
                      <div v-else>
                        <div class="custom-control custom-switch custom-switch-color custom-control-inline d-block">
                          <input type="checkbox" class="custom-control-input bg-success" id="customSwitch011" @change="setStatusPricePrice">
                          <label class="custom-control-label" for="customSwitch011">
                            Доступ ко всем данным
                          </label>
                        </div>
                        <div v-if="!statusSupplier[0].status_price_sum_opt" class="custom-control custom-switch custom-switch-color custom-control-inline d-block">
                          <input type="checkbox" class="custom-control-input bg-success" :disabled="statusPrice.price" id="customSwitch099" :checked="statusPrice.opt" @change="setStatusPriceSumOpt">
                          <label class="custom-control-label" for="customSwitch099">
                            Доступ к оптовым ценам
                          </label>                 
                        </div>
                        <div v-if="!statusSupplier[0].status_price_sum" class="custom-control custom-switch custom-switch-color custom-control-inline d-block">
                          <input type="checkbox" class="custom-control-input bg-success" :disabled="statusPrice.price" id="customSwitch022" :checked="statusPrice.sum" @change="setStatusPriceSum">
                          <label class="custom-control-label" for="customSwitch022">
                            Доступ к розничным ценам
                          </label>                 
                        </div>
                        <div v-if="!statusSupplier[0].status_price_count" class="custom-control custom-switch custom-switch-color custom-control-inline d-block">
                          <input type="checkbox" class="custom-control-input bg-success" id="customSwitch033" :disabled="statusPrice.price" :checked="statusPrice.count" @change="setStatusPriceCount">
                          <label class="custom-control-label" for="customSwitch033">
                            Доступ к остаткам на складе
                          </label>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="form-group col-12">
                        <label for="pwd">Выберите поставщика <my-required-field /></label>
                        <my-select-selected
                          class="custom-select"
                          :model-value="statusPrice.idSupplier"
                          @update:model-value="setIdSupplier"
                          :options="options"
                          :option="statusPrice.idSupplier"
                        />
                      </div>
                    </td>
                    <td class="w160">
                      <div class="table-remove"><button type="button" @click="actionAccessUser" class="btn btn-primary btn-sm mr-2 btn-block">Дать доступ</button></div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import MyProfileForm from '@/components/MyProfileForm'
import MyEditPasswordForm from '@/components/MyEditPasswordForm'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    MyProfileForm,
    MyEditPasswordForm
  },
  methods: {
    ...mapMutations({setInputAccessUser: 'supplier/setInputAccessUser', setStatusPricePrice: 'supplier/setStatusPricePrice', setStatusPriceSum: 'supplier/setStatusPriceSum', setStatusPriceCount: 'supplier/setStatusPriceCount', setIdSupplier: 'supplier/setIdSupplier', setStatusPriceSumOpt: 'supplier/setStatusPriceSumOpt'}),
    
    ...mapActions({actionSearchUser: 'supplier/actionSearchUser', actionAccessUser: 'supplier/actionAccessUser'}),
  },

  computed: {
    ...mapState({
      inputAccessUser: state => state.supplier.inputAccessUser,
      errors: state => state.supplier.errors,
      errorSearchInput: state => state.supplier.errorSearchInput,
      searchUser: state => state.supplier.searchUser,
      statusPrice: state => state.supplier.statusPrice,
      options: state => state.suppliers.map(p => {return {'name': `${p.id}: (${p.name})`}}),
      statusSupplier: state => state.suppliers.filter(p => state.supplier.statusPrice.idSupplier ? p.id === Number(state.supplier.statusPrice.idSupplier.split(':')[0]) : {}),
      message: state => state.supplier.message,
    })
  },

  mounted(){
    document.title = "Дать доступ к закрытым прайс-листам - StartCommerce"
    if(this.statusSupplier.length){
      this.setIdSupplier(`${this.statusSupplier[0].id}: (${this.statusSupplier[0].name})`)
    }
  }
}
</script>

<style>
  .w160{
    width: 160px;
  }
</style>