<template>
  <div id="list-content" class="animate__animated animate__fadeIn active">
    <div class="list-content">
      <div class="list-item">
        <div v-if="loading" class="text-center"><loading-small /></div>
        <div v-for="supplier in suppliers" :key="supplier.id">
          <div>
            <div :class=" selected_providers.find(v => Number(v) == Number(supplier.id)) == Number(supplier.id) ? 'draggable-item todo-list-card border-color-left-green' : 'draggable-item todo-list-card border-color-left-blue'">
              <div class="item-body">
                <div class="pr-3">
                  <div class="custom-control custom-checkbox ">
                    <my-checkbox-val 
                      class="custom-control-input m-0"
                      :id="`supplierCheck-${supplier.id}`"
                      :model-value="selected_providers.find(v => Number(v) == Number(supplier.id)) == Number(supplier.id) ? true : false" @update:model-value="selectSupplier"
                      :val="supplier.id"
                    />
                    <label :for="`supplierCheck-${supplier.id}`" class="custom-control-label"></label>
                  </div>
                </div>
                <div>
                  <h5>{{ supplier.name }}</h5>
                  <p class="text-ellipsis short-1 mb-0">{{ supplier.description }}</p>
                </div>
                <div class="action-list">
                 
                </div>
                <div class="pr-3" v-if="supplier.city">{{ supplier.city }}</div>
                <div class="pr-3" v-else>Без региона </div>
                <div class="pr-3">
                  <a href="#" @click="editSupplier(supplier)" title="Редактировать">
                    <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
                    </svg>
                  </a>
                </div>
                <div class="pr-3">
                  <a class="badge bg-danger" data-toggle="modal" data-target="#exampleModal" @click="setIdRemove(supplier.id)" href="#" title="Удалить">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Вы уверены, что хотите удалить каталог?</h5>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="setIdRemove('')" data-dismiss="modal">Нет</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="actionRemoveSupplier()">Да</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'suppliers-list',
  data(){
    return {
      idSupplier: 0
    }
  },

  props: {
    suppliers: Array,
    loading: Boolean,
    myClass: String
  },
  methods: {
    ...mapMutations({setSelectedProvidersData: 'setSelectedProvidersData', setText: 'suppliersList/setText', setSupp: 'supplier/setSupp', setIdRemove: 'supplier/setIdRemove'}),
    ...mapActions({actionEditSelectedProviders: 'actionEditSelectedProviders', actionRemoveSupplier: 'supplier/actionRemoveSupplier'}),

    editSupplier(e){
      const data = {id: e.id,name: e.name, description: e.description, city: e.city, categories: e.categories}
      this.setSupp(data)
      this.$router.push('/save-supplier')
    },
    
    selectSupplier(e){
      let selectedProviders = this.selected_providers.map(v => v);
      if(e.status === true){
        selectedProviders.push(e.val)
      } else {
        selectedProviders = selectedProviders.filter((v) => Number(v) !== Number(e.val))
      }
      this.setSelectedProvidersData(selectedProviders)
      this.actionEditSelectedProviders()
    },

    addAccessPP(e){
      this.idSupplier = e
    }
  },

  computed: {
    ...mapState({
      id_user: state => state.user.id,
      selected_providers: state => state.user.selected_providers,
      statusTrial: state => state.user.user_package,
      text: state => state.suppliersList.text,
    }),
  },
}
</script>

<style>
  
</style>