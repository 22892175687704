<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Профили поставщика</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Профили поставщика</h4>
            </div>                     
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="card card-block card-stretch">
                <div class="card-body p-0">
                  <div class="card-body">
                     <p v-if="!suppliers.length" class="mb-2">У вас нет ни одного профиля поставщика.</p>
                     <my-button v-if="!suppliers.length" class="btn btn-lg btn-danger mt-2" @click="$router.push('/save-supplier')">Создать профиль поставщика</my-button>
                     <my-button v-else class="btn btn-lg btn-danger mt-2" @click="saveSupplier">Создать еще профиль поставщика</my-button>
                  </div>  
                  <account-suppliers-list :suppliers="suppliers" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import AccountSuppliersList from '@/components/AccountSuppliersList'
import {mapState} from 'vuex'
export default {
  components: {
    Header,
    AccountSuppliersList
  },
  methods: {

    saveSupplier(){
      const data = {name: '', description: '', email: '', phone: '', region: '', showcase: false, city: '',  sites: [], categories: []}
      this.$store.commit('supplier/setId', '')
      this.$store.commit('supplier/setAvatar', '')
      this.$store.commit('supplier/setSupp', data)
      this.$store.commit('supplier/setErrors', [])
      this.$router.push('/save-supplier')
    },

  },
  computed: {
    ...mapState({
      suppliers: state => state.suppliers,
    }),
  },

  mounted(){
    document.title = "Профили поставщика - StartCommerce"
  }
}
</script>