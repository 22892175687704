<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Токен</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Токен нейросети для работы по API</h4>
                </div> 
              </div>

              <div class="card">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-spacing mb-0">
                      <tr class="white-space-no-wrap">
                        <td>
                          <h6 class="mb-0 text-uppercase text-secondary">Токен:</h6>
                        </td>
                        <td class="pl-0 py-3">
                          <span v-if="loading" class="card-body"><loading-small /></span>
                          <span v-else class="token">{{ token ? token.token : '' }}</span><br>
                         
                        </td>
                        <td v-if="token" class="text-right">
                            <copy-token :token="token.token" />
                            <button class="btn btn-primary btn-sm mr-2 ml-3" @click="actionCreateNeurnetworkToken">Сгенерировать новый токен</button>
                        </td>
                        <td v-else class="text-right">
                            <button class="btn btn-primary btn-sm mr-2" @click="actionCreateNeurnetworkToken">Сгенерировать токен</button>
                        </td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td>
                          <h6 class="mb-0 text-uppercase text-secondary">Дата окончания действия токена</h6>
                        </td>
                        <td class="pl-0 py-3">
                          <span v-if="token">{{ new Date(token.date).toLocaleString() }}</span>
                        </td>
                        <td></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import TextList from '@/components/TextList'
import CopyToken from '@/components/CopyToken'
import {mapState, mapActions} from 'vuex'
export default {
  components: {
    Header,
    TextList,
    CopyToken
  },
  methods: {
    ...mapActions({actionCreateNeurnetworkToken: 'neurnetwork/actionCreateNeurnetworkToken'}),
  },

  computed: {

    ...mapState({
      token: state => state.user.user_token_neurnetwork,
      message: state => state.message,
      loading: state => state.neurnetwork.loading, 
    }),
  },

  mounted(){
    document.title = 'Токен - StartCommerce"'
  }
}
</script>