<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
      </div>
      <div v-if="error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{error}}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Каталоги</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12"> 
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Каталоги</h4>
                </div>
                <div class="create-workform">
                  <div class="d-flex flex-wrap align-items-center justify-content-between">
                    <div class="modal-product-search d-flex">
                      <router-link to="/save-supplier" class="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mr-2"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg> Создать каталог 
                      </router-link>
                    </div>
                  </div>
                </div>                
              </div>
              <suppliers-list :suppliers="suppliers" :loading="loadingSmallTwo" myClass="" />
            </div>
            <div class="card-body">
              <inform-supplier />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import MySelect from "@/components/UI/MySelect"
import SuppliersList from '@/components/SuppliersList'
import InformSupplier from '@/components/InformSupplier.vue'
import {mapState, mapActions} from 'vuex'
export default {
  components: {
    Header,
    MySelect,
    SuppliersList,
    InformSupplier
  },
  methods: {
    ...mapActions({actionSuppliersList: 'suppliersList/actionSuppliersList'}),
  },

  computed: {

    ...mapState({
      message: state =>  state.supplier.message,
      suppliers: state => state.suppliers,
      loadingSmallTwo: state => state.suppliersList.loadingSmall,
      error: state => state.error
    }),
  },

  mounted(){
    document.title = 'Список поставщиков - Start Commerce"'
  },

}
</script>

<style>
  .flip-list-move {
    transition: transform 0.8s ease;
  }
  .supp_select{
    height:30px;
    line-height:20px
  }
  @media (max-width: 1000px) {
    .adap-sup-list .adap-sup-list-block{
      flex: none !important;
      width: 100% !important;
      max-width: 100% !important;
    }
    .adap-sup-list-block div{
      text-align: left !important;
      margin-bottom: 15px
    }
  }

</style>