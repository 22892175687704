<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Заказы</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Заказы</h4>
            </div>                    
          </div>
          <div class="card card-block card-stretch">
            <div class="card-body p-0"> 
              <div class="row">
                <div class="col-sm-12">
                  <table class="table data-table mb-0 no-footer">
                    <thead class="table-color-heading">
                      <tr class="text-light">
                        <th class="dates sorting">№</th>
                        <th class="dates sorting">Дата</th>
                        <th class="dates sorting">Описание</th>
                        <th class="dates sorting">Оплата</th>
                        <th class="dates sorting">Сумма</th>
                        <th class="dates sorting">Статус</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="loadingSmall"><td colspan="6"><loading-small /></td></tr>
                      <tr v-else v-for="order in sortPagination.orders" :key="order.id" class="white-space-no-wrap">
                        <td class="w70">#{{order.id}}</td>
                        <td><my-date :date="new Date(order.createdAt)" /></td>
                        <td>{{order.name}}</td>
                        <td>{{order.payment_method }}</td>
                        <td>{{Number(order.payable).toLocaleString()}} <i class="fa fa-rub"></i></td>
                        <td>
                          <div v-if="order.status === false && order.status_payment === true" class="text-warning">Оплачен</div>
                          <div v-else-if="order.status === false && order.status_payment === false" class="text-danger">Закрыт</div>
                          <div v-else-if="order.status === true" class="text-success">Ожидает оплаты</div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="!loadingSmall && sortPagination.count > orders.limit" class="col-lg-12">
                  <pagination :pages="Math.ceil(sortPagination.count / orders.limit)" :model-value="orders.page" @update:model-value="setPage"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
export default {
  components: {Header},
  methods: {
    ...mapActions({actionGetOrders: 'orders/actionGetOrders'}),
    ...mapMutations({setPage: 'orders/setPage', setOrdersList: 'orders/setOrdersList'})
  },

  computed: {
    ...mapState({orders: state => state.orders, loadingSmall: state => state.loadingSmall}),
    ...mapGetters({sortPagination: 'orders/sortPagination'})
  },

  mounted(){
    this.actionGetOrders()
    document.title = "Заказы - StartCommerce"
  },

  unmounted(){
    this.setOrdersList([])
  }
}
</script>

<style>
  .w70{
    width: 70px;
  }
</style>