<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Магазины, имеющие доступ</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        
        <div class="col-lg-12">
          
          <div class="card card-block">
            <div class="card-header d-flex justify-content-between pb-0">
              <div class="header-title">
              <h4 class="card-title mb-0">Магазины, имеющие доступ к закрытым прайс-листам</h4>
              </div>
            </div>
            <div class="card-body">
              <div v-for="supplier in suppliers" :key="supplier.id">
                <div v-if="supplier.access_private_provider.find(v => v.status === true)">
                  <h5>{{supplier.name}}</h5>
                  <table class="table table-bordered table-responsive-md table-striped text-center">
                    <tbody>
                      <tr v-for="accessUser in supplier.access_private_provider.filter(p => p.status === true)" :key="accessUser.id">
                        <td >
                          <div class="h-avatar is-medium">
                            <img class="avatar rounded-circle" v-if="accessUser.user.image" :src="`https://start-commerce.ru/u${accessUser.user.id}/user/${accessUser.user.image}`" />
                            <img class="avatar rounded-circle" v-else src="/images/user/1.jpg" />
                          </div>
                        </td>
                        <td>{{accessUser.user.name}}</td>
                        <td class="w200">{{accessUser.user.email}}</td>
                        <td class="w170">{{accessUser.user.phone}}</td>
                        <td class="text-left">
                          <div v-if="supplier.status_price || (supplier.status_price_sum && supplier.status_price_sum_opt && supplier.status_price_count)" class="text-success">Доступ разрешен ко всем данным</div>
                          <div v-else>
                            <div class="custom-control custom-switch custom-switch-color custom-control-inline d-block">
                              <input type="checkbox" :checked="accessUser.status_price" class="custom-control-input bg-success" :id="`customSwitch011${accessUser.id}`" @change="actionStatusPrice({'id': accessUser.id, 'id_supplier': accessUser.id_supplier})">
                              <label class="custom-control-label" :for="`customSwitch011${accessUser.id}`">
                                Доступ ко всем данным
                              </label>
                            </div>
                            <div v-if="!supplier.status_price_sum_opt" class="custom-control custom-switch custom-switch-color custom-control-inline d-block">
                              <input type="checkbox" class="custom-control-input bg-success" :disabled="accessUser.status_price" :id="`customSwitch099${accessUser.id}`" :checked="accessUser.status_price === true ? false : accessUser.status_price_sum_opt" @change="actionStatusPriceSumOpt({'id': accessUser.id, 'id_supplier': accessUser.id_supplier})">
                              <label class="custom-control-label" :for="`customSwitch099${accessUser.id}`">
                                Доступ к оптовым ценам
                              </label>                 
                            </div>
                            <div v-if="!supplier.status_price_sum" class="custom-control custom-switch custom-switch-color custom-control-inline d-block">
                              <input type="checkbox" class="custom-control-input bg-success" :disabled="accessUser.status_price" :id="`customSwitch022${accessUser.id}`" :checked="accessUser.status_price === true ? false : accessUser.status_price_sum" @change="actionStatusPriceSum({'id': accessUser.id, 'id_supplier': accessUser.id_supplier})">
                              <label class="custom-control-label" :for="`customSwitch022${accessUser.id}`">
                                Доступ к розничным ценам
                              </label>                 
                            </div>
                            <div v-if="!supplier.status_price_count" class="custom-control custom-switch custom-switch-color custom-control-inline d-block">
                              <input type="checkbox" class="custom-control-input bg-success" :id="`customSwitch033${accessUser.id}`" :disabled="accessUser.status_price" :checked="accessUser.status_price === true ? false : accessUser.status_price_count" @change="actionStatusPriceCount({'id': accessUser.id, 'id_supplier': accessUser.id_supplier})">
                              <label class="custom-control-label" :for="`customSwitch033${accessUser.id}`">
                                Доступ к остаткам на складе
                              </label>
                            </div>
                          </div>
                        </td>
                        <td class="w160">
                          <div class="table-remove"><button type="button" @click="actionAccessStatusRemove({'id': accessUser.id, 'id_supplier': accessUser.id_supplier})" class="btn btn-danger btn-sm mr-2 btn-block">Удалить все доступы</button></div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import {mapState, mapActions} from 'vuex'
export default {
  components: {
    Header,
  },
  methods: {
    ...mapActions({actionStatusPrice: 'supplier/actionStatusPrice', actionStatusPriceSum: 'supplier/actionStatusPriceSum', actionStatusPriceCount: 'supplier/actionStatusPriceCount', actionAccessStatusRemove: 'supplier/actionAccessStatusRemove', actionStatusPriceSumOpt: 'supplier/actionStatusPriceSumOpt'}),
  },

  computed: {
    ...mapState({
      errors: state => state.supplier.errors,
      message: state => state.supplier.message,
      suppliers: state => state.suppliers
    })
  },

  mounted(){
    document.title = "Магазины, имеющие доступ к закрытым прайс-листам - StartCommerce"
  }

 
}
</script>

<style>
  .w160{
    width: 160px;
  }
  .w170{
    width: 170px;
  }
  .w200{
    width: 200px;
  }
</style>