import { axiosGetBalanceHistory } from '@/api/index'

export const balanceHistoryModule = {
  state: () => ({
    balanceHistoryList: [],
    limit: 30,
    page: 1
  }),

  getters: {
    sortPagination(state){
      const startIndex = state.page === 1 ? -1 : (state.page * state.limit) - (state.limit + 1)
      return {
        count: state.balanceHistoryList.length,
        balanceHistory: state.balanceHistoryList.filter((v, index) => index > startIndex && index < state.page * state.limit)
      } 
    }
  },

  mutations: {
    setBalanceHistoryList(state, v){state.balanceHistoryList = v},
    setPage(state, v){state.page = v},
  },

  actions: {
    async actionGetBalanceHistory({commit, rootState, dispatch}){
      try {
        commit('setLoadingSmall', true, { root: true })
        const response = await axiosGetBalanceHistory(rootState.token)
        commit('setBalanceHistoryList', response)
        setTimeout(function () {
          commit('setLoadingSmall', false, { root: true })
        }, 500);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetBalanceHistory')
        }
        console.log(e.response.data)
      }
    },
    
  },
  namespaced: true
}