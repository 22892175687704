<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item"><router-link to="/partner-information">Партнерская программа</router-link></li>
                  <li class="breadcrumb-item"><router-link to="/partner-referrals">Рефералы</router-link></li>
                  <li class="breadcrumb-item active">Партнерские начисления</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Партнерские начисления</h4>
                </div> 
              </div>

              <div v-if="partner" class="row">
                <div class="col-lg-12">
                  <div class="card card-block">
                    <div class="card-body p-0">
                      <div class="table-responsive">
                        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                          <div class="row">
                            <div class="col-sm-12">
                              <table class="table data-table mb-0 dataTable no-footer sp th-pl">
                                <thead class="table-color-heading">
                                  <tr>
                                    <th>Наименование</th>
                                    <th>Сумма зачисления</th>
                                    <th>Партнерский процент</th>
                                    <th>Сумма партнеского вознаграждения, списанная с промокода</th>
                                    <th>Остаток суммы промокода</th>
                                  </tr>
                                </thead>
                                <tbody v-if="loading" class="text-center"><tr><td colspan="5"><loading-small /></td></tr></tbody>
                                <tbody v-else>
                                  <tr v-for="accrual in accrualList" :key="accrual.id">
                                    <td>{{ accrual.name }}</td>
                                    <td>{{ parseFloat(accrual.sum).toFixed(2) }} <i class="fa fa-rub"></i></td>
                                    <td>{{ accrual.percent }}%</td>
                                    <td>{{ parseFloat(accrual.percent_promocode).toFixed(2) }} <i class="fa fa-rub"></i></td>
                                    <td>{{ parseFloat(accrual.sum_promocode).toFixed(2) }} <i class="fa fa-rub"></i></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!loading && count > limit" class="col-lg-12">
                  <pagination :pages="Math.ceil(count / limit)" :model-value="page" @update:model-value="actionPage" />
                </div>
              </div>
              <div v-else class="row">
                <div class="col-sm-12 col-lg-12">
                  <p>У вас нет статуса партнера, <router-link to="/partner-information" class="svg-icon">Стать партнером</router-link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
  },
  methods: {
    ...mapMutations({setAccrualList: 'partner/setAccrualList', setRefId: 'partner/setRefId', setPage: 'partner/setPage', setCount: 'partner/setCount'}),
    ...mapActions({actionPartnerAccrualList: 'partner/actionPartnerAccrualList'}),

    actionPage(p){
      this.setPage(p)
      this.actionPartnerAccrualList()
    }
  },

  computed: {
    ...mapState({
      accrualList: state => state.partner.accrualList,
      partner: state => state.user.partner,
      count: state => state.partner.count,
      page: state => state.partner.page,
      limit: state => state.partner.limit,
      loading: state => state.loadingSmall,
    }),
  },

  mounted(){
    this.actionPartnerAccrualList();
    document.title = 'Начисления партнеру - StartCommerce"'
  },

  unmounted(){
    this.setAccrualList([])
    this.setRefId(0)
    this.setPage(1)
    this.setCount(0)
  }
}
</script>