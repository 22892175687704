<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item"><router-link to="/suppliers-account">Профили поставщика</router-link></li>
                  <li class="breadcrumb-item active">Поставщик {{supplier.name}}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Поставщик {{supplier.name}}</h4>
            </div>                     
          </div>

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3 mb-3">
                  <div class="card-body rounded bg-light">
                    <div class="d-flex justify-content-center">
                      <img v-if="supplier.image" :src="`https://start-commerce.ru/u${supplier.id_user}/supplier/${supplier.image}`" class="img-fluid" />
                      <i v-else class="fa fa-image i-img2"></i>
                    </div>
                      <p v-if="supplier.status_public === true" class="mb-0 mt-2 text-center font-weight-bold yes">Доступен для публичного просмотра</p>
                      <p v-else class="mb-0 mt-2 text-center font-weight-bold no">Недоступен для публичного просмотра</p>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="row g-3">
                    <div class="col-md-6 mb-3">
                      <span class="font-weight-bold text-muted text-uppercase">Название</span>
                      <div>{{supplier.name}}</div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <span class="font-weight-bold text-muted text-uppercase">Сайт(ы)</span>
                      <div v-for="site in supplier.sites">
                        {{site}}
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <span class="font-weight-bold text-muted text-uppercase">Телефон</span>
                      <div>{{supplier.phone}}</div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <span class="font-weight-bold text-muted text-uppercase">Адрес электронной почты</span>
                      <div>{{supplier.email}}</div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <span class="font-weight-bold text-muted text-uppercase">Регион</span>
                      <div>{{supplier.region}}</div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <span class="font-weight-bold text-muted text-uppercase">Город</span>
                      <div>{{supplier.city}}</div>
                    </div>
                    <div class="col-md-6 mb-3">
                      
                    </div>
                    <div class="col-md-12 mb-3">
                      <span class="font-weight-bold text-muted text-uppercase">Описание</span>
                      <div>{{supplier.description}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>       
          
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import Header from '@/components/Header'
export default {
  components: {
    Header
  },

  computed: {
    ...mapState({
      supplier: state => state.supplier.supplier,
    }),
  },

  mounted(){
    document.title = "Аккаунты поставщика - Start Commerce"
  }
}
</script>

<style>
.yes{
  color:#1f5f17;
}
.no{
  color: #a7abb1;
}
</style>