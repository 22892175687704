<template>
  <div class="iq-sidebar  sidebar-default ">
    <div class="iq-sidebar-logo d-flex align-items-end justify-content-between">
      <a href="https://startcommerce.ru" class="header-logo">
        <img v-if="!active" src="/images/logo.png" class="img-fluid rounded-normal light-logo" alt="logo">
        <img v-else src="/images/logo_min.png" class="img-fluid rounded-normal light-logo lp-img" alt="logo">
        <span></span>            
      </a>
      <div class="side-menu-bt-sidebar-1" @click="sidebarMain">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-light wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
    </div>
    <div class="data-scrollbar" data-scroll="1" data-scrollbar="true" style="overflow: hidden; outline: none;">
      <div class="scroll-content">
        <nav class="iq-sidebar-menu">
          <ul class="side-menu">
            <li @click="hideMain" v-bind:class="[this.$route.path === '/' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/feed" class="svg-icon">
                <i class="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                </i>
                <span class="ml-2">На главную </span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/my-profile' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/my-profile" class="svg-icon">
                <i class="">
                  <svg class="svg-icon mr-0 text-secondary" id="h-01-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </i>
                <span class="ml-2">Профиль</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/tariffs' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/tariffs" class="svg-icon">
                <i class="fa fa-rub" aria-hidden="true"></i>
                <span class="ml-2">Тарифы и цены</span>
              </router-link>
            </li>
          </ul>
          <ul v-if="loading" class="side-menu">
            <li class="sidebar-layout">
              <span class="text-uppercase small font-weight-bold"><br><loading-small /></span>
            </li>
          </ul>
          
          <ul v-else class="side-menu">
            <li class="px-3 pt-3 pb-2">
              <span class="text-uppercase small font-weight-bold">Нейросеть</span>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/neural-network-token' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/neural-network-token" class="svg-icon">
                <i class="fa fa-exchange" aria-hidden="true"></i>
                <span class="ml-2">Токен</span>
              </router-link>
            </li>
            <li class="sidebar-layout">
              <a href="#" :class="`svg-icon ${neuro ? 'not-collapsed' : 'collapsed'}`" @click="setNeuro" :aria-expanded="neuro">
                <i class="fa fa-file-text-o" aria-hidden="true"></i>
                <span class="ml-2">Генерация текста</span>
                <svg xmlns="http://www.w3.org/2000/svg" height="14" width="15" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="svg-icon iq-arrow-right arrow-active"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
              </a>
              <ul :class="`  ${neuro ? 'show' : ''}`" :style="`${neuro ? '' : 'display: none;'}`">
                <li @click="hideMain" v-bind:class="[this.$route.path === '/create-text-product' ? 'sidebar-layout active-menu' : 'sidebar-layout']">
                  <router-link to="/create-text-product" class="svg-icon">
                    <i class="fa fa-file-text-o" aria-hidden="true"></i>
                    <span class="ml-2">Описание товара</span>
                  </router-link>
                </li>
                <li @click="hideMain" v-bind:class="[this.$route.path === '/text-rewrite' ? 'sidebar-layout active-menu' : 'sidebar-layout']">
                  <router-link to="/text-rewrite" class="svg-icon">
                    <i class="fa fa-files-o" aria-hidden="true"></i>
                    <span class="ml-2">Рерайт текста</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/my-text' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/my-text" class="svg-icon">
                <i class="fa fa-list-alt" aria-hidden="true"></i>
                <span class="ml-2">Мои тексты</span>
              </router-link>
            </li>
            <li class="px-3 pt-3 pb-2">
              <span class="text-uppercase small font-weight-bold">Магазинам</span>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/catalogs' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/catalogs" class="svg-icon">
                <i class="fa fa-th-list" aria-hidden="true"></i>
                <span class="ml-2">Мои каталоги</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/my-package' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/my-package" class="svg-icon">
                <i class="fa fa-shopping-basket" aria-hidden="true"></i>
                <span class="ml-2">Мой тариф</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/categories' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/categories" class="svg-icon">
                <i class="fa fa-list-ol" aria-hidden="true"></i>
                <span class="ml-2">Категории</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/products' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/products" class="svg-icon">
                <i class="fa fa-th" aria-hidden="true"></i>
                <span class="ml-2">Товары</span>
              </router-link>
            </li>
            <!--<li @click="hideMain" v-bind:class="[this.$route.path === '/bundle-products' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/bundle-products" class="svg-icon">
                <i class="fa fa-th-list" aria-hidden="true"></i>
                <span class="ml-2">Связанные товары</span>
              </router-link>
            </li>-->
              
           
            <!--<li class="sidebar-layout">
              <a href="#app1" class="collapsed svg-icon" data-toggle="collapse" aria-expanded="false">
                <i class="fa fa-align-justify" aria-hidden="true"></i>
                <span class="ml-2">Маркетплейсы</span>
                <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon iq-arrow-right arrow-active" width="15" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
              </a>
              <ul id="app1" class="submenu collapse show" data-parent="#iq-sidebar-toggle">
                <li @click="hideMain" v-bind:class="[this.$route.path === '/products' ? 'active sidebar-layout' : 'sidebar-layout']">
                  <router-link to="/products" class="svg-icon">
                    <i class="fa fa-th-list" aria-hidden="true"></i>
                    <span class="ml-2">Яндекс.Маркет</span>
                  </router-link>
                  </li>
                  <li @click="hideMain" v-bind:class="[this.$route.path === '/products' ? 'active sidebar-layout' : 'sidebar-layout']">
                    <router-link to="/products" class="svg-icon">
                      <i class="fa fa-th-list" aria-hidden="true"></i>
                      <span class="ml-2">OZON</span>
                    </router-link>
                  </li>
                  <li @click="hideMain" v-bind:class="[this.$route.path === '/products' ? 'active sidebar-layout' : 'sidebar-layout']">
                    <router-link to="/products" class="svg-icon">
                      <i class="fa fa-th-list" aria-hidden="true"></i>
                      <span class="ml-2">Wildberries</span>
                    </router-link>
                  </li>
                  <li @click="hideMain" v-bind:class="[this.$route.path === '/products' ? 'active sidebar-layout' : 'sidebar-layout']">
                    <router-link to="/products" class="svg-icon">
                      <i class="fa fa-th-list" aria-hidden="true"></i>
                      <span class="ml-2">AliExpress</span>
                    </router-link>
                  </li>
                </ul>
            </li>-->
            <li class="px-3 pt-3 pb-2">
              <span class="text-uppercase small font-weight-bold">Партнерам</span>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/partner-information' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/partner-information" class="svg-icon">
                <i class="fa fa-hand-o-right" aria-hidden="true"></i>
                <span class="ml-2">Информация</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/partner-promocode' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/partner-promocode" class="svg-icon">
                <i class="fa fa-percent" aria-hidden="true"></i>
                <span class="ml-2">Промокоды</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/partner-referrals' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/partner-referrals" class="svg-icon">
                <i class="fa fa-hand-rock-o" aria-hidden="true"></i>
                <span class="ml-2">Рефералы</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/partner-payments' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/partner-payments" class="svg-icon">
                <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>
                <span class="ml-2">Начисления и выплаты</span>
              </router-link>
            </li>

            <li class="px-3 pt-3 pb-2">
              <span class="text-uppercase small font-weight-bold">Поддержка</span>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/how-connect' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/how-connect" class="svg-icon">
                <i class="fa fa-question" aria-hidden="true"></i>
                <span class="ml-2">Интеграция</span>
              </router-link>
            </li>
            <li @click="hideMain" v-bind:class="[this.$route.path === '/support' ? 'active sidebar-layout' : 'sidebar-layout']">
              <router-link to="/support" class="svg-icon">
                <i class="fa fa-life-ring" aria-hidden="true"></i>
                <span class="ml-2">Поддержка</span>
              </router-link>
            </li>
          </ul>
          <br><br><br><br><br><br><br><br>
        </nav>
      </div>
    </div>
  </div>

  
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div  v-on:click="sidebarMain">
          <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </div>
        <div class="d-flex align-items-center">
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"  aria-label="Toggle navigation" @click="clickNav()">
              <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
          </button>
          <div v-if="loading" class="side-menu">      
            <span class="text-uppercase small font-weight-bold"><br><loading-small /></span>      
          </div>
          <div v-else :class="`navbar-collapse collapse ${show}`">
            <ul class="navbar-nav ml-auto navbar-list align-items-center">
              
              <li class="nav-item nav-icon dropdown"> 
                <a href="#" class="search-toggle dropdown-toggle" id="notification-dropdown" >
                  <span>{{ user.balance ? Number(user.balance.account).toLocaleString() : 0}} ₽  <span class="mt-3 badge border border-success text-success mt-3" data-toggle="modal" data-target="#topUpBalanceModal"> Пополнить</span></span>
                  <button v-if="user.top_up_balance && user.top_up_balance.payment_method === 'Безнал'" class="btn btn-dark btn-sm ml-2" @click="$router.push('/invoice-balance')">Счет</button>
                  <span class="bg-primary"></span>
                </a> 
              </li>
              <li class="nav-item nav-icon search-content">
                <a href="#" class="search-toggle rounded" id="dropdownSearch" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    
                </a>
                <div class="iq-search-bar iq-sub-dropdown dropdown-menu" aria-labelledby="dropdownSearch">
                    <form action="#" class="searchbox p-2">
                        <div class="form-group mb-0 position-relative">
                        <input type="text" class="text search-input font-size-12" placeholder="type here to search...">
                        <a href="#" class="search-link">
                            <svg xmlns="http://www.w3.org/2000/svg" class="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </a> 
                        </div>
                    </form>
                </div>
            </li>
            <li class="nav-item nav-icon dropdown">
                <a href="#" class="nav-item nav-icon dropdown-toggle pr-0 search-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  
                    <img v-if="user_image" :src="`https://start-commerce.ru/u${user_id}/user/${user_image}`" class="img-fluid avatar-rounded" alt="user" />
                    <img v-else src="/images/user/1.jpg" class="img-fluid avatar-rounded" alt="user" />
                    <span class="mb-0 ml-2 user-name">{{user_name}}</span>
                </a>
                <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <li class="dropdown-item d-flex svg-icon">
                        <svg class="svg-icon mr-0 text-secondary" id="h-01-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <router-link to="/my-profile">Мой профиль</router-link>
                    </li>
                    
                    <div>
                      <li class="dropdown-item d-flex svg-icon">
                        <i class="svg-icon mr-0 text-secondary reh1">#</i>
                        <router-link to="/my-orders" class="reh11">Заказы</router-link>
                      </li>
                      <li class="dropdown-item d-flex svg-icon">
                        <i class="fa fa-credit-card svg-icon mr-0 text-secondary" aria-hidden="true"></i>
                        <router-link to="/payment-details-yur" class="reh22">Реквизиты для оплаты</router-link>
                      </li>
                      <li class="dropdown-item d-flex svg-icon">
                        <i class="fa fa-history svg-icon mr-0 text-secondary" aria-hidden="true"></i>
                        <router-link to="/my-balance-history" class="reh33">История расчетов</router-link>
                      </li>
                    </div>
                    <li class="dropdown-item  d-flex svg-icon border-top">
                        <svg class="svg-icon mr-0 text-secondary" id="h-05-p" width="20" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                        </svg>
                        <a href="#" @click="actionLogout">Выход</a>
                        
                    </li>
                </ul>
            </li> 
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <!--<div style="text-align: center; font-size: 16; padding: 10px 15px; background-color: #f42b3d; color: #FFF;">Уважаемые пользователи! В связи с изменением организационно-правовой формы и проведением технических работ по подключению платежных систем, начиная с 30 мая 2024 года, возможность пополнения баланса ваших учетных записей будет приостановлена на срок от 7 до 10 рабочих дней. Приносим извинения за возможные неудобства.</div>-->
    <div v-if="!user_status" class="alert alert-danger" role="alert">
        <div class="iq-alert-text text-center"><b>Подтвердите аккаунт</b>, письмо с ссылкой для подтверждения было отправлено на Вашу электронную почту <b>{{user_email}}</b> Если письмо не пришло - обязательно напишите нам <a href="mailto:info@startcommerce.ru">info@startcommerce.ru</a>. В письме укажите адрес электронной почты, которую Вы использовали при регистрации на нашем сервисе.</div>
    </div>
  </div>
  <top-up-balance-modal />
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'
import TopUpBalanceModal from '@/components/TopUpBalanceModal'
export default {
  components: {
    TopUpBalanceModal,
  },
  data(){
    return {
      show: '',
      active: false,
    }
  },
  methods: {
    sidebarMain() {
      this.active = !this.active;
      let siteBody = document.body;
      this.active ? siteBody.classList.add("sidebar-main") : siteBody.classList.remove("sidebar-main");
    },

    hideMain(){
      let siteBody = document.body;
      siteBody.classList.remove("sidebar-main");
    },

    clickNav(){
      this.show ? this.show = '' : this.show = 'show'
    },

    ...mapActions(['actionLogout', 'actionUser', 'actionCheckTariff']),
    ...mapMutations(['setNeuro'])
      
  },

  computed: {
    ...mapState({
      user_id: state => state.user.id,
      user_status: state => state.user.status,
      user_email: state => state.user.email,
      user_name: state => state.user.name,
      user_image: state => state.user.image,
      user: state => state.user,
      loading: state => state.loading,
      neuro: state => state.neuro,
    })
  },

  mounted(){
    jQuery('.data-scrollbar').each(function () {
      const attr = $(this).attr('data-scroll');
      if (typeof attr !== typeof undefined && attr !== false){
      let Scrollbar = window.Scrollbar;
      const a = jQuery(this).data('scroll');
      Scrollbar.init(document.querySelector('div[data-scroll= "' + a + '"]'));
      }
    })
  }

}
</script>

<style>
/*.content-page{margin-top: 100px;} */
 .mt-3{margin-left: 20px;}
 .red{color: red}
 .f-fold{font-size:30px}
 .reh1{
  font-size:22px !important;
  padding-right: 5px;
 }
 .reh11{
  padding-top: 3px !important;
 }
 .reh22{
  position: relative;
  left: 4px;
  top: -3px;
 }
 .reh33{
  position: relative;
  left: 7px;
  top: -3px;
 }
 .lp-img{
  height: auto !important;
 }
 .iq-sidebar-menu .side-menu li .active-menu{
  color: #fff;
    text-decoration: none;
    background-color: hsla(0,0%,100%,.08) !important;
 }
 .iq-sidebar-menu .side-menu li .active-menu a {
  color: #fff;
 }
</style>
