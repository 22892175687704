<template>
  <div class="modal fade" id="topUpBalanceModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Пополнить баланс</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div  v-if="!/^\d+$/.test(topUpBalance) && topUpBalance" class="">
            <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded"> Укажите только цифры</p>
          </div>
          <div class="input-group mb-4">
            <my-input
            type="number"
            :model-value="topUpBalance"
            @update:model-value="setSumTopUpBalance"
            />
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2"><i class="fa fa-rub"></i></span>
            </div>
          </div>

          <div class="card-body">
            <div class="custom-control custom-radio custom-control-inline mb-2">
              <input type="radio" id="customRadio12" name="customRadio-2" value="Безнал" class="custom-control-input" v-model="balanceMethod">
              <label class="custom-control-label" for="customRadio12"> Безналичный расчет </label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="customRadio13" name="customRadio-2" value="Картой" class="custom-control-input" v-model="balanceMethod">
              <label class="custom-control-label" for="customRadio13"> Банковская карта, Yandex Pay, MIR Pay </label>
            </div>
          </div>
          
        </div>
        <div class="modal-footer">
          <button v-if="!/^\d+$/.test(topUpBalance) && topUpBalance" type="button" class="btn btn-danger btn-block" disabled>Пополнить</button>
          <button v-else type="button" class="btn btn-danger btn-block" data-dismiss="modal" @click="actionTopUpBalance(balanceMethod)">Пополнить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'top-up-balance-modal',
  data() {
    return {
      balanceMethod: 'Безнал',
      error: ''
    }
  },
  methods: {
    ...mapMutations({setSumTopUpBalance: 'setSumTopUpBalance'}),
    ...mapActions({actionTopUpBalance: 'actionTopUpBalance'}),

  },
  computed: {
    ...mapState({
      topUpBalance: state => state.sumTopUpBalance,
    }),
  },


}
</script>
<style>
  .clink-danger{
    color: red;
    cursor: pointer
  }
  .ct-a{
    text-align: center;
    color: red;
  }
</style>
