<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Мой тариф</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Мой тариф</h4>
            </div>                    
          </div>
          <div v-if="user.user_package" class="card card-block card-stretch">
            <div class="card-header justify-content-between">
              <div class="header-title">
                <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule">
                  <h4 class="card-title">
                    Тариф {{user.user_package.tariff}} 
                    <span v-if="user.user_package.status_trial === true" class="mr-2">(тестовый период)</span> 
                    <button v-if="!user.payment" @click="$router.push('/tariffs')" class="btn btn-primary btn-sm mr-2">Изменить тариф</button> 
                    <button v-if="user.user_package.status_trial === true" data-toggle="modal" data-target="#paymentModal" class="btn btn-danger btn-sm mr-2" @click="paymentObj">Продлить тариф</button>
                    <button v-if="user.payment && user.payment.payment_method === 'Безнал' && user.user_package.status === false" class="btn btn-dark btn-sm" @click="$router.push('/bank-transfer')">Счет на оплату № A-{{user.payment.id}}</button>
                  </h4>
                  <div v-if="user.payment && user.user_package.status === false" class="text-success">
                    В процессе оплаты ({{user.payment.payment_method}})<br>С момента оплаты и до зачисления средств на счет может пройти трое суток.
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table table-spacing mb-0">
                  <tbody>
                    <tr class="white-space-no-wrap">
                      <td class="w210"><h6 class="mb-0 text-uppercase text-secondary">№</h6></td>
                        <td class="pl-0 py-3">
                          000{{ user.user_package.id }}
                        </td>
                        <td>
                        </td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td class="w210"><h6 class="mb-0 text-uppercase text-secondary">Статус</h6></td>
                        <td class="pl-0 py-3">
                          <p v-if="user.user_package.status_trial === true || user.user_package.status === true" class="mb-0 text-success font-weight-bold">Активен</p>
                          <p v-else class="mb-0 text-danger font-weight-bold">Отключен</p>
                        </td>
                        <td>
                          <div v-if="user.user_package.status_trial === false && user.user_package.status === false">
                            <button data-toggle="modal" data-target="#paymentModal" @click="paymentObj" class="btn btn-danger btn-sm">Продлить тариф</button>
                          </div>
                        </td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td class="w210"><h6 class="mb-0 text-uppercase text-secondary">Автоматическое продление</h6></td>
                        <td class="pl-0 py-3">
                          <p v-if="loadingSmall"><loading-small /></p>
                          <p v-else class="custom-control custom-checkbox custom-checkbox-color-check custom-control-inline cat-bot">
                            <my-checkbox
                              class="custom-control-input bg-primary"
                              id="autoPay"
                              :model-value="user.user_package.automatic_payment" @update:model-value="sAutomaticPayment"
                            /><label class="custom-control-label autoP" for="autoPay">
                              <span v-if="user.user_package.automatic_payment" class="mb-0 text-success">вкл</span>
                              <span v-else class="mb-0 text-danger">выкл</span>
                            </label>
                            <span class="ml-5">
                              <my-checkbox
                                class="custom-control-input bg-primary"
                                id="periodPay"
                                :model-value="user.user_package.period_auto_pay === 1 ? false : true" @update:model-value="sAutomaticPeriod"
                              /><label class="custom-control-label autoP" for="periodPay">Продлить на год</label>
                          </span>
                          </p>
                        </td>
                        <td>
                          
                        </td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td><h6 class="mb-0 text-uppercase text-secondary">Каталоги (поставщики)</h6></td>
                      <td class="pl-0 py-3">
                        <span v-if="user.user_package.allowed_number">Выбрано {{user.selected_providers.length}} из {{user.user_package.allowed_number}}</span>
                        <span v-else>Без ограничений</span>
                        <span class="cf12" v-if="user.user_package.allowed_number > 0 && user.user_package.allowed_number < user.selected_providers.length"><br>Выбранных каталогов больше, чем предусмотрено данным тарифом.<br> Уберите лишние каталоги или измените тариф.</span>
                      </td>
                      <td></td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td><h6 class="mb-0 text-uppercase text-secondary">Стоимость тарифа</h6></td>
                      <td class="pl-0 py-3">
                        <span v-if="user.user_package.status_trial === true">0 <i class="fa fa-rub"></i></span>
                        <span v-else>{{Number(user.user_package.sum.split('.')[0]).toLocaleString()}} <i class="fa fa-rub"></i></span>
                      </td>
                      <td></td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td><h6 class="mb-0 text-uppercase text-secondary">Дополнительные каталоги</h6></td>
                      <td class="pl-0 py-3">
                        <div>
                          <button v-if="(user.user_package.addition.count - user.user_package.addition.statusCount) > 0" type="button" class="mt-2 mr-2 btn btn-dark btn-with-icon btn-sm" @click="actionAddAdditionalSupplier('no')"><i class="ri-bill-fill"></i>- 1</button>
                          <button type="button" class="mt-2 btn btn-success btn-with-icon btn-sm" @click="actionAddAdditionalSupplier('yes')"><i class="ri-bill-fill"></i>+ 1</button>
                          <span v-if="supplierCount > 0" class="ml-2 addition-count"> = Каталоги: <b>{{ supplierCount }}</b> / цена: {{ (supplierCount * user.user_package.addition.price).toLocaleString() }} <i class="fa fa-rub"></i></span>
                          <span class="cf13"><br>Цена за каждый каталог {{ user.user_package.addition.price.toLocaleString() }} <i class="fa fa-rub"></i> за 1 месяц.</span>
                        </div>
                      </td>
                      <td> 
                        <div v-if="user.user_package.status && user.user_package.addition.count != user.user_package.addition.statusCount">
                          <button v-if="(supplierCount * user.user_package.addition.price) < userBalance" data-toggle="modal" data-target="#paymentDopSuppliersModal" class="btn btn-danger btn-sm">Оплатить с баланса {{ (supplierCount * user.user_package.addition.price).toLocaleString() }} <i class="fa fa-rub"></i></button>
                          <p v-else>Не хватает средств на балансе<br> для оплаты дополнительных каталогов.<br> <span class="mb-0 text-red font-weight-bold c-pointer" data-toggle="modal" data-target="#topUpBalanceModal">Пополнить баланс</span></p>
                        </div>
                      </td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td><h6 class="mb-0 text-uppercase text-secondary">Дополнительные каталоги<br> (оплаченные)</h6></td>
                      <td class="pl-0 py-3">
                        <p class="mb-0 text-success font-weight-bold">{{ user.user_package.addition.statusCount }}</p>
                      </td>
                      <td></td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td><h6 class="mb-0 text-uppercase text-secondary">Токен:</h6></td>
                      <td class="pl-0 py-3">
                        <span class="token" v-if="user.user_package.status_trial === true || user.user_package.status === true">{{user.user_package.token}}</span>
                        <span v-else>—</span>
                      </td>
                      <td><span  v-if="user.user_package.status_trial === true || user.user_package.status === true"><copy-token :token="user.user_package.token" /></span></td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td><h6 class="mb-0 text-uppercase text-secondary">Оплаченный период</h6></td>
                      <td class="pl-0 py-3">
                        <span v-if="user.user_package.status_trial === true || user.user_package.status === true">
                          {{user.user_package.days}} 
                          <span v-if="user.user_package.days === 0">дней</span>
                          <span v-else-if="/[1]/.test(user.user_package.days)">день</span>
                          <span v-else-if="user.user_package.days <= 4">дня</span>
                          <span v-else>дней</span>
                        </span>
                        <span v-else>—</span>
                      </td>
                      <td></td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td><h6 class="mb-0 text-uppercase text-secondary">Дата окончания тарифа</h6></td>
                      <td class="pl-0 py-3">
                        <span v-if="user.user_package.status_trial === true || user.user_package.status === true">{{endDate.toLocaleString()}}</span>
                        <span v-else>—</span>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <payment-modal />
            <div class="modal fade" id="paymentDopSuppliersModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Оплата дополнительных каталогов к тарифу {{ user.user_package.tariff }}</h5>
                  </div>
                  <div class="modal-body">
                    <p>С вашего баланса будет списана сумма <b>{{ (supplierCount * user.user_package.addition.price).toLocaleString() }}  <i class="fa fa-rub"></i></b></p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal" @click="actionPaymentDopSuppliers">Оплатить</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Отмена</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="card card-block card-stretch">
            <div class="card-body p-0">
              <div class="table-responsive">
                <table class="table table-spacing mb-0">
                  <tbody>
                    <tr class="white-space-no-wrap">
                      <td><h6 class="mb-0 text-uppercase text-secondary">У вас нет выбранного тарифа</h6></td>
                      <td class="pl-0 py-3">
                        <router-link to="/tariffs" class="btn btn-secondary">Выбрать тариф</router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card card-block card-stretch card-height">
            <div class="card-body p-0">
              <div class="table-responsive">
                <package-suppliers />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import PackageSuppliers from '@/components/PackageSuppliers'
import PaymentModal from '@/components/PaymentModal'
import CopyToken from '@/components/CopyToken'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    PackageSuppliers,
    PaymentModal,
    CopyToken
  },
  methods: {
    ...mapMutations({setTemporaryPayment: 'setTemporaryPayment', setTemporaryPeriod: 'setTemporaryPeriod'}),
    ...mapActions({actionCheckTariff: 'actionCheckTariff', actionAddAdditionalSupplier: 'actionAddAdditionalSupplier', actionPaymentDopSuppliers: 'actionPaymentDopSuppliers', actionTemporaryPayment: 'actionTemporaryPayment', actionTemporaryPeriod: 'actionTemporaryPeriod'}),
    
    paymentObj(){
      const tariff = this.tariff.find(p => p.tariff === this.user.user_package.tariff)
      const dopPriceSupplier = this.user.user_package.addition.price * this.user.user_package.addition.count
      const payment = {...this.payment, balance: this.userBalance, sum: Number(this.user.user_package.sum) + dopPriceSupplier, tariffSum: Number(this.user.user_package.sum), option: 'На месяц', dopPriceSupplier, percent: tariff.percent, paymentMethod: this.userBalance >= this.user.user_package.sum ? 'Баланс' : 'Безнал'}
      console.log(tariff)
      this.$store.commit('setPaymentObj', payment)
    },

    sAutomaticPayment(e){
      this.setTemporaryPayment(e)
      this.actionTemporaryPayment()
    },

    sAutomaticPeriod(e){
      this.setTemporaryPeriod(e)
      this.actionTemporaryPeriod()
    }
  },

  computed: {
    ...mapState({
      message: state => state.message,
      user: state => state.user,
      payment: state => state.payment,
      supplierCount: state => state.user.user_package.addition.count - state.user.user_package.addition.statusCount,
      tariff: state => state.tariff,
      userBalance: state => state.user.balance ? Number(state.user.balance.account) : 0,
      endDate: state => new Date(state.user.user_package.end_date),
      loadingSmall: state => state.loadingSmall,
      arrDay: state => {
        const date1 = new Date(new Date().toUTCString())
        const date2 = new Date(state.user.user_package.end_date)
        const day = Math.round((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24))
        const percent = ((state.user.user_package.days - day) / state.user.user_package.days) * 100
        return {
          'day': day,
          'percent': percent
        }
      },
    }),
  },

  mounted(){
    this.actionCheckTariff()
    document.title = "Мой тариф - StartCommerce"
  }
}
</script>

<style>
  .addition-count{
    font-size:20px;
    position: relative;
    top:7px
  }
  .cf13 {
    color: #3cb72c !important;
    font-size: 13px;
  }
  .c-pointer{
    cursor: pointer;
  }
  table td .custom-checkbox .autoP:before, table td .custom-checkbox .autoP:after{
    top: 0.1rem;
  }
</style>