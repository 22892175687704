<template>
  <footer class="iq-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 text-right">
          <span class="mr-1">
            Copyright © {{year}} <b>StartCommerce</b>. Все права защищены.
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'my-footer',
  
  data(){
    return {
      year: null
    }
  },
  
  mounted(){
    this.year = new Date().getFullYear()
  }

}
</script>
