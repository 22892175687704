<template>
  <div v-if="loading">
    <loading />
  </div>
</template>

<script>
import VueCookies from 'vue-cookies'
import {mapState, mapActions, mapMutations} from 'vuex'
import Loading from '@/components/Loading'
export default {
  name: 'auth',
  components: {
    Loading
  },
  methods: {

    ...mapMutations({
      setUsersLogin: 'setUsersLogin',
      setUsersPassword: 'setUsersPassword'
    }),

    ...mapActions({
     actionAuth: 'actionAuth',
     actionUser: 'actionUser', actionSupplier: 'actionSupplier', actionCategories: 'suppliersList/actionCategories', actionCheckTariff: 'actionCheckTariff'
    }),
    
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
      token: state => state.token
    }),
  },

  mounted(){
    if(this.$route.query.ref){
      //VueCookies.set('ref_code', this.$route.query.ref, '183d' )
      VueCookies.set('ref_code', this.$route.query.ref, '183d', 'null', '.startcommerce.ru' )
    }
    this.actionAuth(this.$route.redirectedFrom ? this.$route.redirectedFrom.fullPath : false);
  }
}
</script>