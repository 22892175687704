<template>
  <nav>
    <ul class="pagination justify-content-center">
      <li v-if="modelValue > 1" class="page-item">
        <a class="page-link" href="#" aria-label="Previous" @click.prevent="setPage(modelValue - 1)"><span aria-hidden="true">«</span></a>
      </li>
      <li v-for="pageNumber in pages" :key="pageNumber" :class="{'page-item': true, 'active': modelValue === pageNumber ? true : false }">
        <span v-if="modelValue === 1">
          <span v-if="modelValue === pageNumber"><span v-if="pageNumber >= modelValue && pageNumber < modelValue + 3" class="page-link">{{pageNumber}}</span></span>
          <span v-else><a v-if="pageNumber >= modelValue && pageNumber < modelValue + 3" class="page-link" href="#" @click.prevent="setPage(pageNumber)">{{pageNumber}}</a></span>
        </span>
        <span v-else>
          <span v-if="modelValue === pageNumber"><span v-if="pageNumber >= modelValue - 1 && pageNumber < modelValue + 2" class="page-link">{{pageNumber}}</span></span>
          <span v-else><a v-if="pageNumber >= modelValue - 1 && pageNumber < modelValue + 2" class="page-link" href="#" @click.prevent="setPage(pageNumber)">{{pageNumber}}</a></span>
        </span>
      </li>
      <li v-if="modelValue < pages" class="page-item">
        <a class="page-link" href="#" aria-label="Next" @click.prevent="setPage(modelValue + 1)"><span aria-hidden="true">»</span></a>
      </li>
    </ul>
  </nav>
</template>

<script>
  export default {
    name: 'pagination',
    props: {
      pages: Number,
      modelValue: Number
    },

    methods: {
      setPage(elem){
        this.$emit('update:modelValue', elem)
      }
    },

  }
  </script>