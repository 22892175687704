<template>
  <Header></Header>
  <div class="content-page">
  <div class="container-fluid">
    <div v-if="message" class="form-group col-12 mess">
      <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
    </div>
    <div v-if="user.role === 1">
      <div class="row">
        <div class="col-md-12 mb-4 mt-1">
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <h4 class="font-weight-bold">Нейросеть</h4>
          </div>
        </div>
        <div class="col-lg-12 col-md-12">
          <div class="row">
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="align-center">
                    <div>
                      <h5 class="mb-2 text-secondary">Создать описание товара</h5>
                      <div class="d-flex flex-wrap justify-content-start align-items-center">
                        <router-link to="/create-text-product" type="button" class="btn btn-primary btn-block btn-sm">
                          Открыть раздел "Описание товара"
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="align-center">
                    <div>
                      <h5 class="mb-2 text-secondary">Рерайт текста</h5>
                      <div class="d-flex flex-wrap justify-content-start align-items-center">
                        <router-link to="/text-rewrite" type="button" class="btn btn-primary btn-block btn-sm">
                          Открыть раздел "Рерайт текста"
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <div class="align-center">
                    <div>
                      <h5 class="mb-2 text-secondary">Мои тексты</h5>
                      <div class="d-flex flex-wrap justify-content-start align-items-center">
                        <router-link to="/my-text"  type="button" class="btn btn-primary btn-block btn-sm">
                          Смотреть все мои сгенерированные тексты
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!user.user_package" class="col-md-12 mb-4 mt-1">
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <h4 class="font-weight-bold">Автоматизация загрузки прайс-листов поставщиков</h4>
          </div>
        </div>
        <div v-if="!user.user_package" class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="align-center">
                <div>
                  <h5 class="mb-2 text-secondary">Создать каталог и узнать как подключить поставщиков</h5>
                  <div class="d-flex flex-wrap justify-content-start align-items-center">
                    <router-link to="/catalogs" type="button" class="btn btn-primary btn-sm">Подробнее</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!user.user_package" class="col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="align-center">
                <div>
                  <h5 class="mb-2 text-secondary">Мой тариф</h5><br>
                  <div class="d-flex flex-wrap justify-content-start align-items-center">
                    <router-link to="/my-package" type="button" class="btn btn-primary btn-sm">Подробнее</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!user.user_package" class="col-md-12 mb-4 mt-1">
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <h4 class="font-weight-bold">Интеграция</h4>
          </div>
        </div>
        <div v-if="!user.user_package" class="row col-lg-12">
          <div class="col-sm-6 col-lg-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Получать данные по API</h5>
                <p class="card-text">Вы можете настроить api самостоятельно или обратиться к нам за помощью.</p>
                <a href="https://start-commerce.ru/api/docs" target="_blank" class="btn btn-primary btn-block">Перейти в инструкцию API</a>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-lg-6">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Готовый плагин для вашей CMS</h5>
                <p class="card-text">Плагины по обновлению цени и остатков по API: </p>
                <p><a href="https://www.webasyst.ru/store/plugin/shop/startcommerce/" target="_blank">Webasyst (Shop-Script)</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="user.user_package" class="row">
        <div class="col-lg-8 col-md-12">
          <div class="row">
            
            <div class="col-md-12">
              <div class="card card-block card-stretch card-height">
                <div class="card-header d-flex justify-content-between">
                  <div class="header-title">
                    <h4 class="card-title">Тариф {{user.user_package.tariff}} 
                      <span v-if="user.user_package.status_trial === true" class="mr-2">(тестовый период)</span> 
                      <button v-if="user.user_package.status_trial === true" data-toggle="modal" data-target="#paymentModal" class="btn btn-danger btn-sm">Оплатить</button>
                      <button v-if="user.user_package.status_trial === false && user.user_package.status === false && !user.payment" data-toggle="modal" data-target="#paymentModal" class="btn btn-danger btn-sm">Продлить тариф</button>
                    </h4>
                  </div>
                </div>
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-spacing mb-0">
                      <tbody>
                        <tr class="white-space-no-wrap">
                          <td class="w210"><h6 class="mb-0 text-uppercase text-secondary">Статус</h6></td>
                          <td class="pl-0 py-3">
                            <p v-if="user.user_package.status_trial === true || user.user_package.status === true" class="mb-0 text-success font-weight-bold">Активен</p>
                            <p v-else class="mb-0 text-danger font-weight-bold">Отключен</p>
                          </td>
                        </tr>
                        <tr class="white-space-no-wrap">
                          <td><h6 class="mb-0 text-uppercase text-secondary">Каталоги</h6></td>
                          <td class="pl-0 py-3">
                            <span v-if="user.user_package.allowed_number">Выбрано {{user.selected_providers.length}} из {{user.user_package.allowed_number}}</span>
                            <span v-else>Без ограничений</span>
                            <span class="cf12" v-if="user.user_package.allowed_number > 0 && user.user_package.allowed_number < user.selected_providers.length"><br>Выбранных поставщиков больше, чем предусмотрено данным тарифом.<br> Уберите лишних поставщиков или измените тариф.</span>
                          </td>
                        </tr>
                        <tr class="white-space-no-wrap">
                          <td><h6 class="mb-0 text-uppercase text-secondary">Дата окончания тарифа</h6></td>
                          <td class="pl-0 py-3">
                            <span v-if="user.user_package.status_trial === true || user.user_package.status === true">{{endDate.toLocaleString()}}</span>
                            <span v-else>—</span>
                          </td>
                        </tr>
                        <tr class="white-space-no-wrap">
                          <td><h6 class="mb-0 text-uppercase text-secondary">Токен:</h6></td>
                          <td class="pl-0 py-3">
                            <span class="token" v-if="user.user_package.status_trial === true || user.user_package.status === true">{{user.user_package.token}}</span>
                            <span v-else>—</span>
                            <span v-if="user.user_package.status_trial === true || user.user_package.status === true" class="token-bt"><copy-token :token="user.user_package.token" /></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="">
                        <p class="mb-2 text-secondary">Настройка API</p>
                        <div class="d-flex flex-wrap justify-content-start align-items-center">
                          <a href="https://start-commerce.ru/api/docs" target="_blank" class="btn btn-success btn-sm">Подробнее</a>
                          
                        </div>                            
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="">
                        <p class="mb-2 text-secondary">Готовые плагины</p>
                        <div class="d-flex flex-wrap justify-content-start align-items-center">
                          <router-link to="/how-connect" class="btn btn-success btn-sm">Подробнее</router-link>
                          
                        </div>                            
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <payment-modal />
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="card bg-primary">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div v-if="!user.payment || user.user_package.status_trial === true || user.user_package.status === true" class="fit-icon-2 text-info text-center">
                    <div id="circle-progress" class="circle-progress-01 circle-progress circle-progress-light" data-min-value="0" data-max-value="100" :data-value="user.user_package.status_trial === true || user.user_package.status === true ?arrDay.percent : 100" data-type="percent"></div>
                  </div>
                  <div class="ml-3">
                    <div class="row">
                      
                      <div class="col-lg-12">
                        <h5 v-if="!user.payment || user.user_package.status_trial === true || user.user_package.status === true" class="text-white font-weight-bold"><small>Осталось</small> {{user.user_package.status_trial === true || user.user_package.status === true ? arrDay.day : 0}} <small> дн. из {{user.user_package.days}}</small></h5>
                        <h5 v-else class="text-white">В процессе оплаты ({{user.payment.payment_method}})</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="card card-block card-stretch card-height">
            <div class="card-header card-header-border d-flex justify-content-between">
              <div class="header-title">
                  <h4 class="card-title">Выбранные каталоги</h4>
              </div>
            </div>
            <div class="card-body-list">
              <package-suppliers-home />
            </div>
            <div class="d-flex justify-content-end align-items-center border-top-table p-3">
              <router-link to="/suppliers-list" class="btn btn-secondary btn-sm">Выбрать каталоги</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<my-footer />
</template>


<script>
import {mapState, mapActions} from 'vuex'
import Header from '@/components/Header'
import Tariff from '@/components/Tariff'
import CopyToken from '@/components/CopyToken'
import PaymentModal from '@/components/PaymentModal'
import PackageSuppliersHome from '@/components/PackageSuppliersHome'
import AccountSuppliersList from '@/components/AccountSuppliersList'
import InformTariff from '@/components/InformTariff.vue'
export default {
components: {
  Header,
  Tariff,
  PackageSuppliersHome,
  PaymentModal,
  AccountSuppliersList,
  CopyToken,
  InformTariff
},

methods: {
  ...mapActions({actionLogout: 'actionLogout', actionUser: 'actionUser', actionSupplier: 'actionSupplier', actionCategories: 'suppliersList/actionCategories', actionCheckTariff: 'actionCheckTariff'}),

  copyToken(){
    navigator.clipboard.writeText(this.user.user_package.token).then(() => {
        this.setMessage('Токен скопирован в буфер обмена')
        setTimeout(() => this.setMessage(''), 2000)
      })
  }
    
},

computed: {
  ...mapState({
    message: state => state.message,
    user: state => state.user,
    endDate: state => new Date(state.user.user_package.end_date),
    arrDay: state => {
      const date1 = new Date(new Date().toUTCString())
      const date2 = new Date(state.user.user_package.end_date)
      const day = Math.round((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24))
      const percent = ((state.user.user_package.days - day) / state.user.user_package.days) * 100
      return {
        'day': day,
        'percent': percent
      }
    },
  }),
},

mounted(){
  document.title = 'Сервис - Start Commerce'
  const progressBar = document.getElementsByClassName('circle-progress')
  if (typeof progressBar[1] !== 'undefined'){
    //progressBar[1].remove();
  }
  Array.from(progressBar, (elem) => {
    const minValue = elem.getAttribute('data-min-value')
    const maxValue = elem.getAttribute('data-max-value')
    const value = elem.getAttribute('data-value')
    const  type = elem.getAttribute('data-type')

    if (elem.getAttribute('id') !== '' && elem.getAttribute('id') !== null) {
      new CircleProgress('#'+elem.getAttribute('id'), {
      min: minValue,
      max: maxValue,
      value: value,
      textFormat: type,
      });
    }
  })
},
  

}
</script>
<style>
.w210{
  width: 210px;
}
.token{
  max-width: 300px;
  display:inline-block;
  overflow: auto;
}
.token-bt{
  float: right;
}
.cf12{
  color: red !important;
  font-size: 13px;
}
</style>