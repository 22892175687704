<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="categories.message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ categories.message }}</p>
      </div>
      <div v-if="categories.loadMess" class="form-group col-12 mess">
        <p class="bg-info-light pl-3 pr-3 pt-2 pb-2 rounded">{{ categories.loadMess }} <loading-small /></p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Категории</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Категории</h4>
                </div>
                <button type="button" class="btn mt-2 mr-1 btn-outline-primary position-relative d-flex align-items-center justify-content-between btn-sm add-plus" @click="addCategory()">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                  </svg> Создать категорию
                </button>  
              </div>
              <div id="list-content" class="animate__animated animate__fadeIn active mt-5 w-100">
                <div class="list-content">
                  <div class="list-item">
                    <div v-if="loading" class="text-center"><loading-small /></div>
                    <div v-for="(category, index) in sortPagination.categories" :key="category.id">
                      <div>
                        <div class="draggable-item todo-list-card ">
                          <div class="item-body">
                            <div v-if="category.edit">
                            <my-input-array 
                              :model-value="category.name" @update:model-value="nameCategory"
                              :id="index"
                              type="text"
                            />
                            </div>
                            <div v-else><h5 @click="setEditCategory(category.id)">{{ category.name }}</h5></div>
                            <div class="action-list"></div>
                            <div class="pr-3">
                              <a class="badge bg-danger" data-toggle="modal" data-target="#exampleModal" @click="setIdRemove(category.id)" href="#" title="Удалить">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!loading && sortPagination.count > categories.limit" class="col-lg-12">
                <pagination :pages="Math.ceil(sortPagination.count / categories.limit)" :model-value="categories.page" @update:model-value="setPage"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button v-if="categories.save" type="button" @click="actionSave" class="btn mt-2 mr-1 btn-success btn-save">Сохранить изменения</button>
  </div>
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Вы уверены, что хотите удалить категорию?</h5>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="setIdRemove('')" data-dismiss="modal">Нет</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="removeCategory">Да</button>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import MySelect from "@/components/UI/MySelect"
import ProductsList from '@/components/ProductsList'
import Excel from '@/components/Excel'
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
import { v4 as uuidv4 } from 'uuid'
export default {
  components: {
    Header,
    MySelect,
    ProductsList,
    Excel
  },
  methods: {
    ...mapMutations({setPage: 'categories/setPage', setSave: 'categories/setSave', setEditCategory: 'categories/setEditCategory', setNameCategory: 'categories/setNameCategory', setRemoveCategory: 'categories/setRemoveCategory', setAddCategory: 'categories/setAddCategory', setIdRemove: 'categories/setIdRemove', setCategories: 'categories/setCategories'}),
    ...mapActions({actionCategories: 'categories/actionCategories', actionSave: 'categories/actionSave', actionRemoveCategory: 'categories/actionRemoveCategory'}),

    removeCategory(){
      this.setRemoveCategory()
      this.actionSave()
    },

    nameCategory(e){
      this.setNameCategory(e)
      this.setSave(true)
    },

    addCategory(){
      this.setAddCategory({id: uuidv4(), name: '', edit: true});
      this.setSave(true)
    }
  },

  computed: {
    ...mapState({categories: state => state.categories, loading: state => state.categories.loading}),
    ...mapGetters({sortPagination: 'categories/sortPagination'})
  },

  mounted(){
    this.actionCategories()
    document.title = 'Категории - StartCommerce"'
  },

  unmounted(){
    this.setCategories([])
    this.setSave(false)
  }
}
</script>

<style>
  .btn-save{
    position: fixed;
    bottom: 20px;
    z-index: 9999;
  }
  .flip-list-move {
    transition: transform 0.8s ease;
  }
  .supp_select{
    height:30px;
    line-height:20px
  }
  .add-plus svg{
    width: 24px;
  }
  @media (max-width: 1000px){
    .adap-prod-list .adap-prod-list-block{
      max-width: 100% !important;
      width: 100% !important;
      flex: none !important;
      padding: 0;
      margin-bottom: 10px;
    }

    .adap-prod-list-block label{
      padding-bottom: 5px;
    }
  }
</style>