import { axiosSaveSupplier, axiosRemoveSupplier, axiosEditSupplier} from '@/api/index'
import router from "@/router";

export const supplierModule = {
  state: () => ({
    errors: [],
    message: '',
    supp: {
      id: 0,
      name: '',
      description: '',
      city: '',
    },
    idRemove: 0,
    closedId: 0,
    temporary: {
      num: 0,
      str: '',
      obj: {}
    },
  }),

  mutations: {
    setErrors(state, error){state.errors = error},
    setMessage(state, message){state.message = message},
    setSuppId(state, v){state.supp.id = v},
    setSuppName(state, v){state.supp.name = v},
    setSupp(state, data){state.supp.id = data.id, state.supp.description = data.description, state.supp.name = data.name, state.supp.city = data.city},
    setDescription(state, v){state.supp.description = v},
    setCity(state, v){state.supp.city = v},
    setClosedId(state, v){state.closedId = v},
    setIdRemove(state, v){state.idRemove = v},
    setTemporaryNum(state, v){state.temporary.num = v},
    setTemporaryStr(state, v){state.temporary.str = v},
    setTemporaryObj(state, v){state.temporary.obj = v},
  },

  actions: {

    async actionSaveSupplier({state, commit, rootState, dispatch}){
      let errors = []
      try {
        if(!state.supp.name){errors.push('Укажите название.')}

        if(errors.length){
          commit('setErrors', errors)
          return
        }
        commit('setErrors', '')

        const response = await axiosSaveSupplier(rootState.token, state.supp)
        if(response){
          if(response.status == 'error'){
            errors.push(response.message)
            commit('setErrors', errors)
            return
          }
          commit('setErrors', '')
          commit('setClosedId', response.id)
          let selectedProviders = rootState.user.selected_providers.map(v => v);
          selectedProviders.push(response.id)
          commit('setSelectedProvidersData', selectedProviders, { root: true })
          await dispatch('actionEditSelectedProviders', null, { root: true })
          await dispatch('products/actionCreateCatalog', response.id, { root: true })
        }
        commit('setSelectedProvidersData', [], { root: true })
        await dispatch('actionUser', null, { root: true })
        await dispatch('actionSupplier', null, { root: true })
        const data = {id: 0, name: '', description: '', city: ''}
        commit('setSupp', data)
        router.push('/catalogs')
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionSaveSupplier')
        }
        console.log(e.response.data)
      }
    },

    async actionRemoveSupplier({rootState, commit, state, dispatch}){
      try {
        await dispatch('products/actionRemoveCatalog', state.idRemove, { root: true })
        await axiosRemoveSupplier(rootState.token, state.idRemove)
        let selectedProviders = rootState.user.selected_providers.map(v => v);
        selectedProviders = selectedProviders.filter(v => v !== state.idRemove)
        commit('setSelectedProvidersData', selectedProviders, { root: true })
        await dispatch('actionEditSelectedProviders', null, { root: true })
        commit('setSelectedProvidersData', [], { root: true })
        commit('setIdRemove', 0)
        await dispatch('actionUser', null, { root: true })
        await dispatch('actionSupplier', null, { root: true })
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionRemoveSupplier')
        }
        console.log(e.response.data)
      }
    },

    async actionEditSupplier({state, commit, rootState, dispatch}){
      let errors = []
      try {
        if(!state.supp.name){errors.push('Укажите название.')}
  
        if(errors.length){
          commit('setErrors', errors)
          return
        }
        commit('setErrors', '')

        const response = await axiosEditSupplier(rootState.token, state.supp)
        if(response.status == 'error'){
          errors.push(response.message)
          commit('setErrors', errors)
          return
        }
        commit('setErrors', '')
        await dispatch('actionSupplier', null, { root: true })
        commit('setMessage', response.message)
        const data = {id: 0, name: '', description: '', city: ''}
        commit('setSupp', data)
        router.push('/catalogs')
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionEditSupplier')
        }
        console.log(e.response.data)
      }
    },

  },
  namespaced: true
}