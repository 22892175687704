import { axiosCreatePartner, axiosPromocodeList, axiosCreatePromocode, axiosStatusPromocode, axiosRemovePromocode, axiosReferralsList, axiosPartnerRequestPayments, axiosPartnerAccrualList, axiosPartnerReferralPaymentsList, axiosEditPartnerPaymentDetails, axiosGetPartnerPaymentDetails } from '@/api/index'

export const partnerModule = {
  state: () => ({
    promocodeList: [],
    referralsList: [],
    accrualList: [],
    referralPaymentsList: [],
    count: 0,
    requestPayments: 0,
    refId: 0,
    paymentDetails: {
      name: '',
      address: '',
      city: '',
      region: '',
      postcode: '',
      country: 'Россия',
      inn: '',
      kpp: '',
      checkingAccount: '',
      bankCity: '',
      bank: '',
      bic: '',
      corrCheck: ''
    },
    promocode: {
      name: null,
      sum: 1,
      percent: 1,
      sumResult: 0
    },
    promocodeSum: [{id: 1, name: 100},{id: 2, name: 500},{id: 3, name: 1000},{id: 4, name: 1500},{id: 5, name: 2000},{id: 6, name: 3000},{id: 7, name: 4000},{id: 8, name: 5000},{id: 9, name: 6000},{id: 10, name: 7000},{id: 11, name: 8000},{id: 12, name: 9000},{id: 13, name: 10000}],
    promocodePercent: [{id: 1, name: 0},{id: 2, name: 5},{id: 3, name: 10},{id: 4, name: 15},{id: 5, name: 20},{id: 6, name: 25},{id: 7, name: 30},{id: 8, name: 35}],
    message: '',
    error: '',
    temporary: {
      num: 0,
    },
    page: 1,
    limit: 30
  }),

  getters: {
    
  },

  mutations: {
    setPaymentDetails(state, v){state.paymentDetails = v},
    setPaymentDetailsName(state, v){state.paymentDetails.name = v},
    setPaymentDetailsAddress(state, v){state.paymentDetails.address = v},
    setPaymentDetailsCity(state, v){state.paymentDetails.city = v},
    setPaymentDetailsRegion(state, v){state.paymentDetails.region = v},
    setPaymentDetailsPostcode(state, v){state.paymentDetails.postcode = v},
    setPaymentDetailsInn(state, v){state.paymentDetails.inn = v},
    setPaymentDetailsKpp(state, v){state.paymentDetails.kpp = v},
    setPaymentDetailsCheckingAccount(state, v){state.paymentDetails.checkingAccount = v},
    setPaymentDetailsBankCity(state, v){state.paymentDetails.bankCity = v},
    setPaymentDetailsBank(state, v){state.paymentDetails.bank = v},
    setPaymentDetailsBic(state, v){state.paymentDetails.bic = v},
    setPaymentDetailsCorrCheck(state, v){state.paymentDetails.corrCheck = v},
    setPaymentDetailsCountry(state, v){state.paymentDetails.country = v},


    setPromocodeList(state, v){state.promocodeList = v},
    setPromocodeName(state, v){state.promocode.name = v},
    setAccrualList(state, v){state.accrualList = v},
    setReferralPaymentsList(state, v){state.referralPaymentsList = v},
    setCount(state, v){state.count = v},
    setRequestPayments(state, v){state.requestPayments = Number(v)},
    setRefId(state, v){state.refId = Number(v)},
    setPromocodeSum(state, v){state.promocode.sum = Number(v)},
    setPromocodePercent(state, v){state.promocode.percent = Number(v)},
    setPromocodeSumResult(state, v){state.promocode.sumResult = v},
    setPromocode(state, v){state.promocode = v},
    setReferralsList(state, v){state.referralsList = v},
    setMessage(state, v){state.message = v},
    setError(state, v){state.error = v},
    setTemporaryNum(state, v){state.temporary.num = v},
    setPage(state, v){state.page = v},
  },

  actions: {
    // Создаем партнера
    async actionCreatePartner({rootState, state, commit, dispatch}){
      try {
        await axiosCreatePartner(rootState.token, {data: state.paymentDetails})
        await dispatch('actionUser', null, { root: true })
      } catch (e) {
        if(e.response.status === 422){
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 3000);
        }
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionCreatePartner')
        }
        console.log(e.response.data)
      }
    },

    // Редактируем платежные реквизиты
    async actionEditPartnerPaymentDetails({rootState, state, commit, dispatch}){
      try {
        const response = await axiosEditPartnerPaymentDetails(rootState.token, {data: state.paymentDetails})
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
        await dispatch('actionGetPartnerPaymentDetails')
      } catch (e) {
        if(e.response.status === 422){
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 3000);
        }
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionEditPartnerPaymentDetails')
        }
        console.log(e.response.data)
      }
    },

    // Получить платежные реквизиты
    async actionGetPartnerPaymentDetails({rootState, commit, dispatch}){
      try {
        commit('setLoadingSmall', true, { root: true })
        const response = await axiosGetPartnerPaymentDetails(rootState.token)
        commit('setPaymentDetails', response)
        setTimeout(function () {
          commit('setLoadingSmall', false, { root: true })
        }, 500);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionGetPartnerPaymentDetails')
        }
        console.log(e.response.data)
      }
    },

    // Получаем промокоды
    async actionPromocodeList({rootState, commit, dispatch}){
      try {
        commit('setLoadingSmall', true, { root: true })
        const response = await axiosPromocodeList(rootState.token)
        commit('setPromocodeList', response)
        setTimeout(function () {
          commit('setLoadingSmall', false, { root: true })
        }, 500);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionPromocodeList')
        }
        console.log(e.response.data)
      }
    },

    // Создаем промокод
    async actionCreatePromocode({rootState, state, commit, dispatch}){
      try {
        const data = {name: state.promocode.name, sum: state.promocode.sum, percent: state.promocode.percent}
        const response = await axiosCreatePromocode(rootState.token, data)
        if(response.status == 'error'){
          commit('setError', response.message)
          setTimeout(function () {
            commit('setError', '')
          }, 3000);
          return
        }
        commit('setPromocode', {name: null, sum: 1, percent: 1, sumResult: 0})
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
        await dispatch('actionPromocodeList')
      } catch (e) {
        if(e.response.status === 404){
          commit('setError', e.response.data.message)
          setTimeout(function () {
            commit('setError', '')
          }, 3000);
        }
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionCreatePromocode')
        }
        console.log(e.response.data)
      }
    },

    // Меняем статус промокоду
    async actionStatusPromocode({rootState, state, commit, dispatch}, id){
      try {
        commit('setTemporaryNum', id)
        const response = await axiosStatusPromocode(rootState.token, state.temporary.num)
        commit('setMessage', response.message)
        commit('setTemporaryNum', 0)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionStatusPromocode', state.temporary.num)
        }
        console.log(e.response.data)
      }
    },

    // Удаляем промокод
    async actionRemovePromocode({rootState, state, commit, dispatch}, id){
      try {
        commit('setTemporaryNum', id)
        await axiosRemovePromocode(rootState.token, state.temporary.num)
        commit('setTemporaryNum', 0)
        await dispatch('actionPromocodeList')
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionRemovePromocode', state.temporary.num)
        }
        console.log(e.response.data)
      }
    },

    //Получаем рефералов
    async actionReferralsList({rootState, commit, dispatch}){
      try {
        commit('setLoadingSmall', true, { root: true })
        const response = await axiosReferralsList(rootState.token)
        commit('setReferralsList', response)
        setTimeout(function () {
          commit('setLoadingSmall', false, { root: true })
        }, 500);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionReferralsList')
        }
        console.log(e.response.data)
      }
    },

    // Запрос на вывод средств
    async actionPartnerRequestPayments({rootState, state, commit, dispatch}){
      try {
        const response = await axiosPartnerRequestPayments(rootState.token, state.requestPayments)
        if(response.status == 'error'){
          commit('setError', response.message)
          setTimeout(function () {
            commit('setError', '')
          }, 3000);
          return
        }
        await dispatch('actionUser', null, { root: true })
        commit('setRequestPayments', 0)
        commit('setMessage', response.message)
        setTimeout(function () {
          commit('setMessage', '')
        }, 3000);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionPartnerRequestPayments')
        }
        console.log(e.response.data)
      }
    },

    // Получаем все начисления партнера
    async actionPartnerAccrualList({rootState, state, commit, dispatch}){
      try {
        if(!state.refId){
          commit('setAccrualList', [])
        }
        commit('setLoadingSmall', true, { root: true })
        const response = await axiosPartnerAccrualList(rootState.token, state.refId, state.page, state.limit)
        commit('setAccrualList', response.data)
        commit('setCount', response.count)
        setTimeout(function () {
          commit('setLoadingSmall', false, { root: true })
        }, 500);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionPartnerAccrualList')
        }
        console.log(e.response.data)
      }
    },

    // Получаем все платежи реферала
    async actionPartnerReferralPaymentsList({rootState, state, commit, dispatch}){
      try {
        if(!state.refId){
          commit('setReferralPaymentsList', [])
        }
        commit('setLoadingSmall', true, { root: true })
        const response = await axiosPartnerReferralPaymentsList(rootState.token, state.refId, state.page, state.limit)
        commit('setReferralPaymentsList', response.data)
        commit('setCount', response.count)
        setTimeout(function () {
          commit('setLoadingSmall', false, { root: true })
        }, 500);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionPartnerReferralPaymentsList')
        }
        console.log(e.response.data)
      }
    },
    
  },
  namespaced: true
}