<template>
  <form enctype="multipart/form-data" @submit.prevent>
    <div class="row">
      <div v-if="errors.length" class="form-group col-12">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errors">{{error}}</p>
      </div>
      <div class="form-group col-12 col-sm-7">
        <label for="pwd">Старый пароль: <my-required-field /></label>
        <my-input
          :model-value="editPassword.oldPassword" @update:model-value="setOldPassword"
          type="password"
          required
          placeholder="***********"
        />
      </div>
      <div class="form-group col-12 col-sm-7">
        <label for="pwd">Новый пароль <my-required-field /></label>
        <my-input
          :model-value="editPassword.newPassword" @update:model-value="setNewPassword"
          type="password"
          placeholder="***********"
        />
      </div>
      <div class="form-group col-12 col-sm-7">
        <label for="pwd">Повторите пароль <my-required-field /></label>
        <my-input
          :model-value="editPassword.repeatPassword" @update:model-value="setRepeatPassword"
          type="password"
          placeholder="***********" 
        />
      </div>
    </div>
     <my-button type="submit" class="btn btn-primary mr-2 mr-2 pl-5 pr-5" @click="actionEditPassword">Изменить</my-button>
  </form>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'my-edit-password-form',
  methods: {
    ...mapMutations({
      setOldPassword: 'setOldPassword', 
      setNewPassword: 'setNewPassword', 
      setRepeatPassword: 'setRepeatPassword'}),

    ...mapActions({actionEditPassword: 'actionEditPassword'}),
    
  },
  computed: {
    ...mapState({
      editPassword: state => state.editPassword,
      errors: state => state.errorsPass,
    }),
  }
}
</script>