<template>
  <form @submit.prevent>
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <div v-if="errors.length">
            <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errors">{{error}}</p>
          </div>
          
          <label class="text-secondary">Электронная почта</label>
          <my-input
            :model-value="login.email" @update:model-value="setLoginEmail"
            type="email"
            required
            placeholder="Ваш е-mail"
          />
        </div>
      </div>
      <div class="col-lg-12 mt-2">
        <div class="form-group">
          <div class="d-flex justify-content-between align-items-center">
            <label class="text-secondary">Пароль</label>
            <label><router-link to="auth-recover">Забыли пароль?</router-link></label>
          </div>
          <my-input 
            :model-value="login.password" @update:model-value="setLoginPassword"
            type="password"
            placeholder="Ваш пароль"
          />
          <div v-if="errors.empty">{{errors}}</div>
        </div>
      </div>
    </div>
    <my-button type="submit" class="btn-primary btn-block mt-2" @click="actionLogin">Войти</my-button>
    <div class="col-lg-12 mt-3">
      <p class="mb-0 text-center">Нет аккаунта? <router-link to="/registration">Регистрация</router-link></p>
    </div>
  </form>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'login-form',
  methods: {
    ...mapMutations(['setLoginEmail', 'setLoginPassword', 'setErrors']),

    ...mapActions(['actionLogin']),  
  },
  computed: {
    ...mapState({
      login: state => state.login,
      errors: state => state.errors
    }),
  },

  unmounted(){
    this.setErrors('')
  }
}
</script>