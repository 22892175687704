<template>
  <input type="checkbox" v-bind:checked="modelValue" v-on:change="updateInput" />
</template>

<script>
export default {
  name: 'my-checkbox',
  props: {
    modelValue: Boolean,
  },

  methods: {
    updateInput(event){
      this.$emit('update:modelValue', event.target.checked)
    }
  }

}
</script>