<template>
  <button class="btn btn-success position-relative d-flex align-items-center justify-content-between" @click="newExcel">
    <i class="fa fa-file-excel-o pr-2" aria-hidden="true"></i>Скачать в Excel
  </button>
</template>


<script>
import * as XLSX from 'xlsx'
export default {
  name: 'excel',
  props: {
    products: [String, Array],
  },

  methods: {
    
    newExcel(){
      const catalog = {'!ref': `A1:H${this.products.length + 1}`}
      catalog.A1 = {t: 's', v: 'Артикул'}
      catalog.B1 = {t: 's', v: 'Артикул поставщика'}
      catalog.C1 = {t: 's', v: 'Название'}
      catalog.D1 = {t: 's', v: 'Бренд'}
      catalog.E1 = {t: 's', v: 'Поставщик'}
      catalog.F1 = {t: 's', v: 'Оптовая цена'}
      catalog.G1 = {t: 's', v: 'Розничная цена'}
      catalog.H1 = {t: 's', v: 'Количество'}

      let i = 2
      this.products.forEach(p => {
        catalog[`A${i}`] = {t: 's', v: p.sku}
        catalog[`B${i}`] = {t: 's', v: p.sku_supplier}
        catalog[`C${i}`] = {t: 's', v: p.name}
        catalog[`D${i}`] = {t: 's', v: p.brand ? p.brand : ''}
        catalog[`E${i}`] = {t: 's', v: p.supplier.name}
        catalog[`F${i}`] = {t: 's', v: p.wholesale_price ? p.wholesale_price.split('.')[0] : ''}
        catalog[`G${i}`] = {t: 's', v: p.retail_price ? p.retail_price.split('.')[0] : ''}
        catalog[`H${i}`] = {t: 's', v: p.count}
        i++
      })

      const workbook = XLSX.utils.book_new(); 

      const newDate = new Date();
      const date = `${newDate.getDate()}/${newDate.getMonth()}/${newDate.getFullYear()}_${newDate.getHours()}${newDate.getMinutes()}${newDate.getSeconds()}`

      console.log(date)

      
      XLSX.utils.book_append_sheet(workbook, catalog, 'Sheet 1');
      XLSX.writeFile(workbook, `${date}.xlsx`);
    }
  }
  
}
</script>