<template>
  <button class="btn btn-info btn-sm" @click="copyToken">Копировать токен</button>
</template>

<script>
import {mapMutations} from 'vuex'
export default {
  name: 'copy-token',
  props: {
    token: String,
  },
  methods: {
    ...mapMutations({setMessage: 'setMessage'}),

    copyToken(){
      navigator.clipboard.writeText(this.token).then(() => {
          this.setMessage('Токен скопирован в буфер обмена')
          setTimeout(() => this.setMessage(''), 2000)
        })
    }
  }


}
</script>

