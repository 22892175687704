<template>
  <select v-model="modelValue" @change="changeOption">
    <option v-if="option" :key="option" :value="option">{{ option }}</option>
    <option
      v-for="option in options"
      :key="option.id"
      :value="option.id"
      >
      {{ option.name }}
      </option>
    </select>
</template>

<script>
export default {
  name: 'my-select-id',
  props: {
    modelValue: {
      type: [String, Number]
    },
    options: {
      type: Array,
      default: () => []
    },
    option: {
       type: String
    }
  },
  methods: {
    changeOption(event) {
      this.$emit('update:modelValue', event.target.value);
    }
  }
}
</script>