<template>
  <div class="alert alert-primary" role="alert">
    <div class="iq-alert-text">
      <h5><b>Если вы хотите атоматизировать загрузку прайс-листов от ваших поставщиков, а также обновлять цены и остатки с помощью нашего API, вам необходимо выбрать подходящий тариф.</b></h5><br>


    </div>
  </div>  
</template>


<script>
export default {
  name: 'inform-tariff',
}
</script>
<style>
 
</style>