<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Загрузка прайс-листов</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Загрузка прайс-листов</h4>
            </div>                     
          </div>
        </div>
        <div class="col-lg-12">
          <div class="card-body">
            <div v-if="!suppliers.length" class="alert alert-danger" role="alert">
              <div class="iq-alert-text">Перед тем как загружать прайс-листы, необходимо создать <router-link to="/suppliers-account"> профиль поставщика</router-link></div>
            </div>
            <div class="alert alert-primary" role="alert">
              <div class="iq-alert-text"> Нам важно получать от вас данные прайс-листа, состоящие из следующих пунктов: Наименование, арткул, оптовая цена и/или розничная цена и/или наличие товара. Все остальные данные: описание, характеристика и т.д. — являются необязательными, и могут передаваться на ваше усмотрение.  </div>
            </div>         
          </div>
        </div>
        <div class="col-lg-12">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-header d-flex justify-content-between">
                <div class="header-title">
                  <h5 class="card-title">Отправка прайс-листа на почту</h5>
                </div>
              </div>
              <div class="card-body">
                <p>Вы можете регулярно отправлять ваш прайс-лист на наш адрес электронной почты <a href="mailto:price@startcommerce.ru">price@startcommerce.ru</a>, а далее мы сами позаботимся о размещении данных.</p>
                <p>В письме обязательно укажите ваш индивидуальный код поставщика. <router-link to="/suppliers-account"> Посмотреть код</router-link></p>
              </div>
            </div>       
          </div>
          <div class="col-lg-12">
            <div class="card col-lg-12">
              <div class="card-header d-flex justify-content-between">
                <div class="header-title">
                  <h5 class="card-title">Передача по ссылке</h5>
                </div>
              </div>
              <div class="card-body">
                <p>Если у вас есть ссылка в yml, xml и других форматах, где обновляются данные ваших товаров, добавьте ссылку на файл.</p>

                <div class="list-content">
                  <div class="list-item">
                    <div id="list-draggable-item-1">
                      <div v-for="priceData in prices">
                        <div v-if="priceData.data.length" class="draggable-item todo-list-card border-color-left-blue">
                          <div>
                            <h5 class="mb-3">{{ priceData.name }}</h5>
                            <div class="mb-3" v-for="price in priceData.data" :key="price.id" @submit.prevent>
                              <label class="text-secondary">{{price.name}}</label>
                              <div class="row">
                                <my-input
                                class="col-lg-10"
                                :model-value="price.api_url" :data-id="price.id" @input="setSuppName"
                                type="text"
                                />
                                
                                <div class="col-lg-1">
                                  <button type="button" class="btn btn-outline-success" @click="actionEditPrice({'id': price.id, 'code_supplier': price.code_supplier})"><i class="fa fa-floppy-o"></i></button>
                                </div>
                                <div class="col-lg-1">
                                  <button type="button" class="btn btn-outline-danger" @click="actionRemovePrice(price.id)"><i class="fa fa-times "></i></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <upload-price-form />
              </div>
            </div>       
          </div>
          <div class="col-lg-12">
            <div class="card col-lg-12">
              <div class="card-header d-flex justify-content-between">
                <div class="header-title">
                  <h5 class="card-title">Передача по API</h5>
                </div>
              </div>
              <div class="card-body">
                <p>Если у вас есть API, мы можем подключиться к нему и получать данные товаров.</p>
              </div>
            </div>       
          </div>
          <div class="col-lg-12">
            <div class="card col-lg-12">
              <div class="card-header d-flex justify-content-between">
                <div class="header-title">
                  <h5 class="card-title">Парсинг</h5>
                </div>
              </div>
              <div class="card-body">
                <p>Если вас устроит такой варинт, мы можем парсить каталог вашего сайта в определенное вермя для сбора данных вашего товара.</p>
              </div>
            </div>       
          </div>
          <div class="col-lg-12">
            <div class="card col-lg-12">
              <div class="card-header d-flex justify-content-between">
                <div class="header-title">
                  <h5 class="card-title">Иной способ</h5>
                </div>
              </div>
              <div class="card-body">
                <p>Просто свяжиетсь с нами, и мы вместе подберем удобный способ сбора данных вашего товара.</p>
              </div>
            </div>       
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import UploadPriceForm from '@/components/UploadPriceForm'
import {mapState, mapActions,} from 'vuex'
export default {
  components: {
    Header,
    UploadPriceForm
  },

  methods: {
    ...mapActions({actionEditPrice: 'supplier/actionEditPrice', actionRemovePrice: 'supplier/actionRemovePrice'}),
    
    setSuppName(e){
      let prices = []
      const suppliers = this.suppliers.map(priceData => {
        prices = priceData.prices.map(p => p.id == e.target.getAttribute('data-id') ? {...p, api_url: e.target.value} : p)
        priceData.prices = prices
        return priceData
      })
      this.$store.commit('setSuppliers',  suppliers)
    }
  },

  computed: {
    ...mapState({
      options: state => state.suppliers.map(p => {return {'name': p.name, 'code': p.code}}),
      prices: state => state.suppliers.map(p => {return {'name': p.name, 'data': p.prices}}),
      suppliers: state => state.suppliers,
      message: state => state.supplier.message
    }),
  },

  mounted(){
    document.title = "Загрузка прйс-листов - StartCommerce"
  }
  
}
</script>
<style>
.mess{
  position:fixed;
  z-index: 999;
  top:80px;
  width: auto;
}
</style>