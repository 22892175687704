<template>
  <form @submit.prevent>
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <div v-if="errors.length">
            <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errors">{{error}}</p>
          </div>
          <div v-if="message">
            <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{message}}</p>
          </div>
          <div v-if="!message">
            <label class="text-secondary">Электронная почта</label>
            <my-input
              :model-value="recoverEmail" @update:model-value="setRecoverEmail"
              type="email"
              required
              placeholder="Введите адрес электронной почты"
            />
          </div>
          
        </div>
      </div>
    </div>
    <my-button v-if="!message" type="submit" class="btn-primary btn-block mt-2" @click="actionRecover">Отправить</my-button>
    <div class="row">
      <p class="col-lg-6 text-left"><router-link to="/login">Войти</router-link></p>
      <p class="col-lg-6 text-right"><router-link to="/registration">Регистрация</router-link></p>
    </div>
  </form>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'auth-recover-form',
  methods: {
    ...mapMutations(['setRecoverEmail', 'setErrors', 'setMessage']),
    ...mapActions(['actionRecover']),  
  },
  computed: {
    ...mapState({
      recoverEmail: state => state.recoverEmail,
      errors: state => state.errors,
      message: state => state.message,
    }),
  },

  unmounted(){
    this.setErrors('')
    this.setMessage('')
  }
}
</script>