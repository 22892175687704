<template>
  <div class="modal fade" id="paymentModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Оплатить: Тариф {{user.user_package.tariff}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="defaultState">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <my-select
          class="custom-select"
          :model-value="payment.option"
          @update:model-value="editPaymentTariff"
          :options="payment.options"
          />
          <h6 class="ct-a mt-1">Экономия {{(payment.discount * 12).toLocaleString()}} <i class="fa fa-rub"></i></h6>

          <div class="card-body">
            <p v-if="payment.option === 'На год'">Стоимость тарифа: <b>{{payment.tariffSum.toLocaleString()}}</b> <i class="fa fa-rub"></i> / {{payment.option}}</p>
            <p v-if="payment.option === 'На месяц'">Стоимость тарифа: <b>{{payment.tariffSum.toLocaleString()}}</b> <i class="fa fa-rub"></i> / {{payment.option}}</p>
            <p v-if="user.user_package.addition.count > 0">Дополнительные поставщики: <b>{{ payment.dopPriceSupplier.toLocaleString() }}</b> <i class="fa fa-rub"></i></p>
            <h5>И  того: {{ (payment.tariffSum + payment.dopPriceSupplier).toLocaleString() }} <i class="fa fa-rub"></i></h5>
          </div>

          <h4 v-if="payment.balance >= payment.sum && payment.balance !== 0" class="mt-3">Спишется с баланса: <b> {{payment.sum.toLocaleString()}}</b> <i class="fa fa-rub"></i></h4>


          <div class="card-body">
            <div v-if="payment.balance < payment.sum" class="">
              
              <h5> Не хватает {{(payment.sum - payment.balance).toLocaleString()}} руб. на балансе  </h5>
            </div>
           
            <div v-if="payment.balance >= payment.sum" class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="customRadio7" name="customRadio-1" value="Баланс" class="custom-control-input" checked="" v-model="payment.paymentMethod">
              <label class="custom-control-label" for="customRadio7"> Списать с баланса </label>
            </div>
          </div>
          
        </div>
        <div class="modal-footer">
          <button v-if="payment.balance >= payment.sum" type="button" class="btn btn-danger btn-block" data-dismiss="modal" @click="actionPaymentTariff({'tariff': user.user_package.tariff, 'option': payment.option, 'paymentMethod': payment.paymentMethod, 'name': `Продление тарифа ${user.user_package.tariff}`})">Оплатить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
  name: 'payment-modal',
  
  methods: {
    ...mapActions({actionPaymentTariff: 'actionPaymentTariff'}),

    defaultState(){
      //this.dopPriceSupplier = 0
    },

    editPaymentTariff(value){
      let interimPayment = {}
      let discount = 0
      let dopPriceSupplier = this.user.user_package.addition.price * this.user.user_package.addition.count
      let sum = Number(this.user.user_package.sum) + dopPriceSupplier
      let tariffSum = Number(this.user.user_package.sum)
      if(value === 'На месяц'){
        interimPayment = {...this.payment, discount, sum, tariffSum, dopPriceSupplier, paymentMethod: this.payment.balance >= sum ? 'Баланс' : 'Безнал', option: value}
        this.$store.commit('setPaymentObj', interimPayment)
      } else if(value === 'На год'){
        discount = this.user.user_package.sum * this.payment.percent / 100
        dopPriceSupplier = this.user.user_package.addition.price * this.user.user_package.addition.count * 12
        sum = Number((this.user.user_package.sum - discount) * 12) + dopPriceSupplier
        tariffSum = Number((this.user.user_package.sum - discount) * 12)
        interimPayment = {...this.payment, discount, sum, dopPriceSupplier, tariffSum, paymentMethod: this.payment.balance >= sum ? 'Баланс' : 'Безнал', option: value}
        this.$store.commit('setPaymentObj', interimPayment)
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      payment: state => state.payment
      
    }),
  }
}
</script>
<style>
  .clink-danger{
    color: red;
    cursor: pointer
  }
  .ct-a{
    text-align: center;
    color: red;
  }
</style>
