<template>
  <form enctype="multipart/form-data" @submit.prevent>
    <div class="row">
      <div v-if="message">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ message }}</p>
      </div>

      <div class="form-group col-12 col-sm-6">
        <label for="pwd">Название организации / ФИО самозанятого, ИП <my-required-field /> </label>
        <my-input
          :model-value="paymentDetails.name" @update:model-value="setPaymentDetailsName"
          type="text"
          placeholder=""  
        />
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="pwd">Адрес <my-required-field /></label>
        <my-input
          :model-value="paymentDetails.address" @update:model-value="setPaymentDetailsAddress"
          type="text"
          placeholder=""  
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="pwd">Город <my-required-field /></label>
        <my-input
          :model-value="paymentDetails.city" @update:model-value="setPaymentDetailsCity"
          type="text"
          required
          placeholder=""  
        />
      </div>

      <div class="form-group col-12 col-sm-4">
        <label for="pwd">Страна <my-required-field /></label>
        <my-input
          model-value="Россия" @update:model-value="setPaymentDetailsCountry"
          type="text"
          disabled="disabled"  
        />
      </div>

      <div class="form-group col-12 col-sm-4">
        <label for="pwd">Область <my-required-field /> </label>
        <my-input
          :model-value="paymentDetails.region" @update:model-value="setPaymentDetailsRegion"
          type="text"
          placeholder=""  
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="pwd">Почтовый индекс <my-required-field /></label>
        <my-input
          :model-value="paymentDetails.postcode" @update:model-value="setPaymentDetailsPostcode"
          type="text"
          placeholder=""  
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="pwd">ИНН <my-required-field /> </label>
        <my-input
          :model-value="paymentDetails.inn" @update:model-value="setPaymentDetailsInn"
          type="text"
          placeholder=""  
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="pwd">КПП </label>
        <my-input
          :model-value="paymentDetails.kpp" @update:model-value="setPaymentDetailsKpp"
          type="text"
          placeholder=""  
        />
      </div>
      <div class="form-group col-12 col-sm-12">
        <label for="pwd">Банковские реквизиты: </label>
      </div>

      <div class="form-group col-12 col-sm-4">
        <label for="pwd">Расчетный счет <my-required-field /> </label>
        <my-input
          :model-value="paymentDetails.checkingAccount" @update:model-value="setPaymentDetailsCheckingAccount"
          type="text"
          placeholder=""  
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="pwd">Город банка <my-required-field /> </label>
        <my-input
          :model-value="paymentDetails.bankCity" @update:model-value="setPaymentDetailsBankCity"
          type="text"
          placeholder=""  
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="pwd">Банк <my-required-field /> </label>
        <my-input
          :model-value="paymentDetails.bank" @update:model-value="setPaymentDetailsBank"
          type="text"
          placeholder=""  
        />
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="pwd">БИК <my-required-field /> </label>
        <my-input
          :model-value="paymentDetails.bic" @update:model-value="setPaymentDetailsBic"
          type="text"
          placeholder=""  
        />
      </div>
      <div class="form-group col-12 col-sm-6">
        <label for="pwd">Корр. счет <my-required-field /> </label>
        <my-input
          :model-value="paymentDetails.corrCheck" @update:model-value="setPaymentDetailsCorrCheck"
          type="text"
          placeholder=""  
        />
      </div>
  

    </div>
     <my-button v-if="partner" type="submit" class="btn btn-primary mr-2" @click="actionEditPartnerPaymentDetails">Сохранить</my-button>
  </form>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'partner-payment-details-form',
 
  methods: {
    ...mapMutations({setPaymentDetailsName: 'partner/setPaymentDetailsName', setPaymentDetailsCity: 'partner/setPaymentDetailsCity', setPaymentDetailsAddress: 'partner/setPaymentDetailsAddress', setPaymentDetailsRegion: 'partner/setPaymentDetailsRegion', setPaymentDetailsPostcode: 'partner/setPaymentDetailsPostcode', setPaymentDetailsInn: 'partner/setPaymentDetailsInn', setPaymentDetailsKpp: 'partner/setPaymentDetailsKpp', setPaymentDetailsCheckingAccount: 'partner/setPaymentDetailsCheckingAccount', setPaymentDetailsBankCity: 'partner/setPaymentDetailsBankCity', setPaymentDetailsBank: 'partner/setPaymentDetailsBank', setPaymentDetailsCorrCheck: 'partner/setPaymentDetailsCorrCheck', setPaymentDetailsBic: 'partner/setPaymentDetailsBic', setPaymentDetailsCountry: 'partner/setPaymentDetailsCountry'}),

    ...mapActions({actionEditPartnerPaymentDetails: 'partner/actionEditPartnerPaymentDetails'}),
    
  },

  computed: {
    ...mapState({
      paymentDetails: state => state.partner.paymentDetails,
      partner: state => state.user.partner,
      message: state => state.partner.message,
    }),
  },
}
</script>

<style>

</style>