<template>
  <form enctype="multipart/form-data" @submit.prevent>
    <div class="row">
      <div v-if="errors.length" class="form-group col-12">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded" v-for="error in errors" :key="error">{{ error }}</p>
      </div>
      
      <div class="form-group col-12 col-sm-4">
        <label for="pwd">Название: </label>
        <my-input
          :model-value="product.name" @update:model-value="setProductName"
          type="text"
          placeholder=""  
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="pwd">Ссылка на фото: <my-required-field /><my-required-field /></label>
        <my-input
          :model-value="product.image" @update:model-value="setProductImage"
          type="text"
          placeholder=""  
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="pwd">Артикул: <my-required-field /></label>
        <my-input
          :model-value="product.sku_supplier" @update:model-value="setProductSkuSupplier"
          type="text"
          required
          placeholder=""  
        />
      </div>
      <div class="form-group col-12 col-sm-3">
        <label for="customFile">Цена-1:</label>
        <my-input
          :model-value="product.retail_price" @update:model-value="setProductRetailPrice"
          type="text"
          placeholder=""
        />
      </div>
      <div class="form-group col-12 col-sm-3">
        <label for="customFile">Цена-2:</label>
        <my-input
          :model-value="product.price_two" @update:model-value="setProductPriceTwo"
          type="text"
          placeholder=""
        />
      </div>
      <div class="form-group col-12 col-sm-3">
        <label for="customFile">Цена-3:</label>
        <my-input
          :model-value="product.price_three" @update:model-value="setProductPriceThree"
          type="text"
          placeholder=""
        />
      </div>
      <div v-if="product.sku" class="form-group col-12 col-sm-3">
        <label for="customFile">Каталог:</label>
          <my-input
            :model-value="product.catalog"
            type="text"
            @update:model-value="setProductCatalog"
          />
      </div>
      <div v-else class="form-group col-12 col-sm-3">
        <label for="customFile">Каталог:</label>
          <my-select
            :model-value="product.catalog"
            :options="catalogs"
            @update:model-value="setProductCatalog"
            class="form-control"
          />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="customFile">Количество:</label>
        <my-input
          :model-value="product.count" @update:model-value="setProductCount"
          type="text"
          placeholder=""
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="customFile">Бренд:</label>
        <my-input
          :model-value="product.brand" @update:model-value="setProductBrand"
          type="text"
          placeholder=""
        />
      </div>
      <div class="form-group col-12 col-sm-4">
        <label for="customFile">Штрих-код:</label>
        <my-input
          :model-value="product.barcode" @update:model-value="setProductBarcode"
          type="text"
          placeholder=""
        />
      </div>
      <div class="form-group col-12">
        <label class="" for="customFile">Описание:</label>
        <my-textarea
          :model-value="product.description" @update:model-value="setProductDescription"
          class="form-text"
        />
      </div>
    </div>
     <my-button type="submit" class="btn btn-primary mr-2" @click="actionSaveProduct">Сохранить</my-button>
  </form>
  <div class="mt-3 mb-5">
    <span class="text-secondary fx-12"><my-required-field /> <span>Обязательное поле </span></span><br>
    <span class="text-secondary fx-12"><my-required-field /><my-required-field /> <span>Поле не является обязательным. Фото на сервер не загружаем, фото товара будет отображаться по ссылке, которую вы добавляете.</span></span><br>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'product-form',
  props: {
    product: Object,
    errors: Array,
    catalogs: Array
  },
  methods: {
    ...mapMutations({setProductImage: 'products/setProductImage', setProductName: 'products/setProductName', setProductSkuSupplier: 'products/setProductSkuSupplier', setProductRetailPrice: 'products/setProductRetailPrice', setProductPriceTwo: 'products/setProductPriceTwo', setProductPriceThree: 'products/setProductPriceThree', setProductCount: 'products/setProductCount', setProductBrand: 'products/setProductBrand', setProductBarcode: 'products/setProductBarcode', setProductDescription: 'products/setProductDescription', setProductCatalog: 'products/setProductCatalog', setProduct: 'products/setProduct'}),

    ...mapActions({actionSaveProduct: 'products/actionSaveProduct', actionEditProduct: 'products/actionEditProduct'}),
    
  },

  unmounted(){
    this.setProduct({})
  }
}
</script>

<style>
.hover:after{
  content: attr(data-title);
  display: none;
  position: absolute;
  bottom: 130%;
  left: 0px;
  color: #3aaeda;
  padding: 5px;
  text-align: center;
  -moz-box-shadow: 0 1px 1px rgba(0,0,0,.16);
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.16);
  box-shadow: 0 1px 1px rgba(0,0,0,.16);font-size: 12px;}
  .hover:hover:after{display: block;
  }
  .file-inp{
    line-height:1.5
  }
  .site{
    margin-right:10px;
    font-weight: bold;
  }
  .alert2{
    border: none;
    display: block;
  }
  .color-red{
    color: red;
    cursor: pointer;
    position: relative;
    top:-8px;
    font-size: 12px;
  }
  .img{
    width: 120px;
  }
  .fx-12{
    font-size: 12px;
  }
  .i-img{
    font-size:90px;
    color: #db7e06;
  }
  .form-text{
    line-height: 17px;
    min-height: 250px;
  }
  #multiple{position: absolute; background-color: #FFF; z-index: 999;}
  .cat-bot{margin-bottom: 10px}
  @media (max-width: 440px) {
    .cat-bot{width:100%}
  }
</style>