<template>    
  <div v-if="suppliers.length" class="table-responsive card">
    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
      <div class="row">
        <div class="col-sm-12">
          <table class="table data-table mb-0 dataTable no-footer">
            <thead class="table-color-heading">
              <tr class="text-center">
                <th>Название</th>
                <th>Публичный просмотр</th>
                <th>Видимость данных прайс-листов.</th>
                <th>Код поставщика</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="supplier in suppliers" class="white-space-no-wrap">
                <td>
                  <div class="active-project-1 d-flex align-items-center mt-0 ">
                    <div class="h-avatar is-medium">
                      <img v-if="supplier.image" class="avatar rounded-circle" alt="user-icon" :src="`https://start-commerce.ru/u${supplier.id_user}/supplier/${supplier.image}`" />
                        <i v-else class="fa fa-image i-img2"></i>
                    </div>
                    <div class="data-content">
                      {{supplier.name}}
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <div class="custom-control custom-switch custom-switch-text custom-switch-text custom-control-inline">
                    <div class="custom-switch-inner">
                      <input type="checkbox" class="custom-control-input bg-danger" :id="`customSwitch-${supplier.id}`" :checked="supplier.status_public === true ? true : false">
                      <label class="custom-control-label" :for="`customSwitch-${supplier.id}`" data-on-label="Yes" data-off-label="No" @click="actionSupplierStatusPublic(supplier.id)">
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="custom-control custom-switch custom-switch-color custom-control-inline d-block">
                    <input type="checkbox" class="custom-control-input bg-success" :id="`customSwitch01-${supplier.id}`" :checked="supplier.status_price === true ? true : false">
                    <label class="custom-control-label" :for="`customSwitch01-${supplier.id}`" @click="actionSupplierStatusPrice(supplier.id)">
                      Все данные прайс-листа 
                      <i v-if="supplier.status_price === false" class="fa fa-ban c-red" aria-hidden="true"></i>
                      <i v-else class="fa fa-check-circle-o c-green" aria-hidden="true"></i>
                    </label>
                  </div>
                  <div class="custom-control custom-switch custom-switch-color custom-control-inline d-block">
                    <input type="checkbox" class="custom-control-input bg-success" :disabled="supplier.status_price === true ? true : false" :id="`customSwitch05-${supplier.id}`" :checked="supplier.status_price_sum_opt === true && supplier.status_price != true ? true : false">
                    <label v-if="supplier.status_price != true" class="custom-control-label" :for="`customSwitch05-${supplier.id}`" @click="actionSupplierStatusPriceSumOpt(supplier.id)">
                      Только оптовая цена
                      <i v-if="supplier.status_price_sum_opt === false || supplier.status_price === true" class="fa fa-ban c-red" aria-hidden="true"></i>
                      <i v-else class="fa fa-check-circle-o c-green" aria-hidden="true"></i>
                    </label>
                    <label v-else class="custom-control-label" :for="`customSwitch05-${supplier.id}`">
                      Только оптовая цена
                      <i v-if="supplier.status_price_sum_opt === false || supplier.status_price === true" class="fa fa-ban c-red" aria-hidden="true"></i>
                      <i v-else class="fa fa-check-circle-o c-green" aria-hidden="true"></i>
                    </label>
                  </div>
                  <div class="custom-control custom-switch custom-switch-color custom-control-inline d-block">
                    <input type="checkbox" class="custom-control-input bg-success" :disabled="supplier.status_price === true ? true : false" :id="`customSwitch02-${supplier.id}`" :checked="supplier.status_price_sum === true && supplier.status_price != true ? true : false">
                    <label v-if="supplier.status_price != true" class="custom-control-label" :for="`customSwitch02-${supplier.id}`" @click="actionSupplierStatusPriceSum(supplier.id)">
                      Только розничная цена
                      <i v-if="supplier.status_price_sum === false || supplier.status_price === true" class="fa fa-ban c-red" aria-hidden="true"></i>
                      <i v-else class="fa fa-check-circle-o c-green" aria-hidden="true"></i>
                    </label>
                    <label v-else class="custom-control-label" :for="`customSwitch02-${supplier.id}`">
                      Только розничная цена
                      <i v-if="supplier.status_price_sum === false || supplier.status_price === true" class="fa fa-ban c-red" aria-hidden="true"></i>
                      <i v-else class="fa fa-check-circle-o c-green" aria-hidden="true"></i>
                    </label>
                  </div>
                  <div class="custom-control custom-switch custom-switch-color custom-control-inline d-block">
                    <input type="checkbox" class="custom-control-input bg-success" :id="`customSwitch03-${supplier.id}`" :disabled="supplier.status_price === true ? true : false" :checked="supplier.status_price_count === true && supplier.status_price != true ? true : false">
                    <label v-if="supplier.status_price != true" class="custom-control-label" :for="`customSwitch03-${supplier.id}`" @click="actionSupplierStatusPriceCount(supplier.id)">
                      Только остаток на складе
                      <i v-if="supplier.status_price_count === false || supplier.status_price === true" class="fa fa-ban c-red" aria-hidden="true"></i>
                      <i v-else class="fa fa-check-circle-o c-green" aria-hidden="true"></i>
                    </label>
                    <label v-else class="custom-control-label" :for="`customSwitch03-${supplier.id}`">
                      Только остаток на складе
                      <i v-if="supplier.status_price_count === false || supplier.status_price === true" class="fa fa-ban c-red" aria-hidden="true"></i>
                      <i v-else class="fa fa-check-circle-o c-green" aria-hidden="true"></i>
                    </label>
                  </div>
                </td>
                <td>
                    {{supplier.code}}
                </td>
                <td>
                  <div class="d-flex justify-content-end align-items-center">
                    <a href="#" @click="openSupplier(supplier)" title="Открыть">
                      <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                      </svg>
                    </a>
                    <a href="#" @click="editSupplier(supplier)" title="Редактировать">
                      <svg xmlns="http://www.w3.org/2000/svg" class="text-secondary mx-4" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
                      </svg>
                    </a>
                    <a class="badge bg-danger" data-toggle="modal" data-target="#exampleModal" @click="setIdRemove(supplier.id)" href="#" title="Удалить">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                      </svg>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Вы уверены, что хотите удалить поставщика?</h5>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="setIdRemove('')" data-dismiss="modal">Нет</button>
                  <button type="button" class="btn btn-primary" data-dismiss="modal" @click="actionRemoveSupplier(idRemove)">Да</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SupplierAccountForm from '@/components/SupplierAccountForm'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'account-suppliers-list',
  components: {
    SupplierAccountForm
  },

  props: {
    suppliers: [String, Array]
  },

  methods: {
    ...mapActions({actionRemoveSupplier: 'supplier/actionRemoveSupplier', actionSupplierStatusPublic: 'supplier/actionSupplierStatusPublic', actionSupplierStatusPrice: 'supplier/actionSupplierStatusPrice', actionSupplierStatusPriceSum: 'supplier/actionSupplierStatusPriceSum', actionSupplierStatusPriceCount: 'supplier/actionSupplierStatusPriceCount', actionSupplierStatusPriceSumOpt: 'supplier/actionSupplierStatusPriceSumOpt'}),

    ...mapMutations({setIdRemove: 'supplier/setIdRemove'}),

    editSupplier(supplierData){
      const data = {
        name: supplierData.name,
        description: supplierData.description,
        email: supplierData.email,
        showcase: supplierData.showcase,
        phone: supplierData.phone,
        region: supplierData.region,
        city: supplierData.city, 
        sites: supplierData.sites ? supplierData.sites : [],
        categories: supplierData.categories ? supplierData.categories : []
      }
      console.log(data)
      this.$store.commit('supplier/setSupp', data)
      this.$store.commit('supplier/setErrors', [])
      this.$store.commit('supplier/setId', supplierData.id)
      this.$store.commit('supplier/setAvatar', supplierData.image)
      this.$router.push(`/edit-supplier/${supplierData.id}`)
    },

    openSupplier(supplier){
      this.$store.commit('supplier/setSupplier', supplier)
      this.$router.push(`/open-supplier/${supplier.id}`)
    }

  },
  computed: {
    ...mapState({
      idRemove: state => state.supplier.idRemove,
    }),
  },

}
</script>

<style>
table.dataTable{
  margin-top:0 !important;
}
.avatar{
  width: 60px;
}
.i-img2{
  font-size:50px;
  color: #db7e06;
}
.c-red{
  color: red
}
.c-green{
  color: green
}
</style>