<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Подключенные магазины</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="card card-block">
            <div class="card-header d-flex justify-content-between pb-0">
              <div class="header-title">
              <h4 class="card-title mb-0">Подключенные магазины</h4>
              </div>
            </div>
            <div class="card-body">
              <table v-if="userPackage.length" class="table table-bordered table-responsive-md table-striped text-center">
                <tbody>
                  <tr v-if="loadingSmall"><td><loading-small /></td></tr>
                  <tr v-else v-for="user in userPackage" :key="user.id">
                    <td>
                      <div class="h-avatar is-medium">
                        <img class="avatar rounded-circle" v-if="user.image" :src="`https://start-commerce.ru/u${user.id}/user/${user.image}`" />
                        <img class="avatar rounded-circle" v-else src="/images/user/1.jpg" />
                      </div>
                    </td>
                    <td>{{user.name}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.phone}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
  },
  methods: {
    ...mapMutations({setUserPackage: 'supplier/setUserPackage'}),
    ...mapActions({actionsSuppliersAccessPP: 'supplier/actionsSuppliersAccessPP'}),
  },

  computed: {
    ...mapState({
      userPackage: state => state.supplier.userPackage,
      loadingSmall: state => state.loadingSmall
    })
  },

  mounted(){
    this.actionsSuppliersAccessPP()
    document.title = "Подключенные магазины - Start Commerce"
  },

  unmounted(){
    this.setUserPackage([])
  }
}
</script>