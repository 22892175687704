<template>
  <div v-if="loading">
    <loading />
  </div>
  <section class="login-content">
    <div class="container h-100">
      <div class="row align-items-center justify-content-center h-100">
        <div class="col-md-5">
          <div class="card p-5">
            <div class="auth-logo">
              <a href="https://startcommerce.ru/"><img src="/images/96.png" class="img-fluid rounded-normal light-logo" alt="logo"></a>
            </div>
            <h3 class="mb-3 text-center">Сброс пароля</h3>
            <p class="text-center small text-secondary mb-3">Введите свой адрес электронной почты, мы отправим вам письмо с инструкциями по сбросу пароля.</p>
            <auth-recover-form />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
  import {mapState, mapActions} from 'vuex'
  import AuthRecoverForm from '@/components/AuthRecoverForm'
  import Loading from '@/components/Loading'
  export default {
    components: {
      AuthRecoverForm,
      Loading
    },

    methods: {
    ...mapActions({actionAuthorization: 'actionAuthorization'})
  },

  computed: {
    ...mapState({
      loading: state => state.loading,

    }),
  },

  mounted(){
    document.title = "StartCommerce"
    this.actionAuthorization()
  }
}
</script>