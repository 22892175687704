<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item"><router-link to="/products">Товары</router-link></li>
                  <li v-if="!product.sku" class="breadcrumb-item active">Создать товар</li>
                  <li v-else class="breadcrumb-item active">Редактировать товар</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 v-if="!product.sku" class="font-weight-bold">Создать товар</h4>
              <h4 v-else class="font-weight-bold">Редактировать товар</h4>
            </div>                     
          </div>
          <div class="row">
            <div class="card">
              <div class="card-body">
                <div v-if="loadSave" class="form-group col-12 mess">
                  <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded"><loading-small /></p>
                </div>
                <product-form :product="product" :errors="errors" :catalogs="catalogs" />
              </div>
            </div>       
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import ProductForm from '@/components/ProductForm'
import {mapState, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    ProductForm
  },
  methods: {
    ...mapMutations({setProductCatalog: 'products/setProductCatalog'})
  },

  computed: {
    ...mapState({
      product: state => state.products.product,
      loadSave: state => state.products.loadSave,
      errors: state => state.products.errors,
      catalogs: state => state.suppliers.map(e => {return {id: e.id, name: e.name}}),
    }),
  },

  mounted(){
    if(this.catalogs.length){
      this.setProductCatalog(this.catalogs[0].name)
    }
    document.title = "Создать профиль поставщика - StartCommerce"
  }
}
</script>