<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item"><router-link to="/suppliers-list">Прайс-листы поставщиков</router-link></li>
                  <li class="breadcrumb-item active">Редактировать прайс-лист поставщика</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="font-weight-bold">Редактировать прайс-лист поставщика</h4>
            </div>                     
          </div>
          <div class="row">
            <div class="card">
              <div class="card-body">
                <supplier-account-form />
              </div>
            </div>       
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>

<script>
import Header from '@/components/Header'
import SupplierAccountForm from '@/components/SupplierAccountForm'
export default {
  components: {
    Header,
    SupplierAccountForm
  },

  mounted(){
    document.title = "Редактировать данные поставщика - Start Commerce"
  }
}
</script>