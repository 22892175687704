<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ message }}</p>
      </div>
      <div v-if="error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{ error }}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item"><router-link to="/partner-information">Партнерская программа</router-link></li>
                  <li class="breadcrumb-item active">Промокоды партнера</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Промокоды партнера</h4>
                </div> 
              </div>

              <div v-if="partner" class="row">
                <div class="col-md-12">
                  <div class="card-body">
                    <h5 class="mb-0 font-weight-bold">Создать промокод</h5>
                  </div>
                </div>
                <div class="iq-accordion career-style faq-style col-sm-12 col-md-12">
                  <div class="card iq-accordion-block">
                    <div class="row container-fluid accordion-details">
                      <div class="col-sm-12 col-md-4">
                        <div class="dataTables_length">
                          <label>Название промокода:</label>
                          <my-input
                            :model-value="promocode.name" @update:model-value="setPromocodeName"
                            type="text"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="dataTables_length">
                          <label>Сумма пополнения:</label>
                          <my-select-id 
                          :model-value="promocode.sum" @update:model-value="editSum"
                          :options="promocodeSum"
                          class="custom-select custom-select-sm form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="dataTables_length">
                          <label>Поделись скидкой:</label>
                          <my-select-id 
                          :model-value="promocode.percent" @update:model-value="editPercent"
                          :options="promocodePercent"
                          option=""
                          class="custom-select custom-select-sm form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="dataTables_length">
                          <label>Бонус рефералу:</label>
                          <input class="form-control" type="text" :value="promocode.sumResult" disabled>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="dataTables_length">
                          <button class="btn btn-primary mt-4" @click="actionCreatePromocode" type="submit">Сохранить</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="card-body">
                    <h5 class="mb-0 font-weight-bold">Промокоды</h5>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="card card-block card-stretch">
                    <div class="table-responsive">
                      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                        <div v-if="loading" class="text-center"><loading-small /></div>
                        <table v-else class="table data-table mb-0 dataTable no-footer sp">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Код</th>
                              <th>Сумма пополнения</th>
                              <th>Бонус рефералу</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="white-space-no-wrap" v-for="p in promocodeList" :key="p.id">
                              <td>
                                <div class="custom-control custom-switch custom-switch-color custom-control-inline">
                                  <input type="checkbox" :id="`check-status-promocode${p.id}`" :checked="p.status" class="custom-control-input bg-primary">
                                  <label @click="actionStatusPromocode(p.id)" :for="`check-status-promocode${p.id}`" class="custom-control-label"></label>
                                </div>
                              </td>
                              <td>{{ p.name }}</td>
                              <td>{{ parseFloat(p.sum).toFixed(2) }} <i class="fa fa-rub"></i></td>
                              <td>{{ parseFloat(p.sum_bonus).toFixed(2) }} <i class="fa fa-rub"></i></td>
                              <td><button type="button" @click="actionRemovePromocode(p.id)" class="btn mt-2 mr-1 btn-outline-danger">Удалить</button></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-sm-12 col-lg-12">
                  <div class="alert alert-primary" role="alert">
                    <div class="iq-alert-text">
                      <p>Что такое промокоды и как они работают вы можете посмотреть в <a href="https://startcommerce.ru/dogovor-ob-uchastii-v-partnerskoj-programme/" target="_blank">п.п.2.5., 2.6.5. Договора об участии в партнерской программе</a></p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row">
                <div class="col-sm-12 col-lg-12">
                  <p>У вас нет статуса партнера, <router-link to="/partner-information" class="svg-icon">Стать партнером</router-link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
  },
  methods: {
    ...mapMutations({setPromocodeName: 'partner/setPromocodeName', setPromocodeSum: 'partner/setPromocodeSum', setPromocodePercent: 'partner/setPromocodePercent', setPromocodeSumResult: 'partner/setPromocodeSumResult', setPromocodeList: 'partner/setPromocodeList'}),
    ...mapActions({actionPromocodeList: 'partner/actionPromocodeList', actionCreatePromocode: 'partner/actionCreatePromocode', actionStatusPromocode: 'partner/actionStatusPromocode', actionRemovePromocode: 'partner/actionRemovePromocode'}),

    editSum(e){
      this.setPromocodeSum(e)
      const sum = this.promocodeSum.find(s => s.id == e)
      const percent = this.promocodePercent.find(p => p.id == this.promocode.percent)
      this.setPromocodeSumResult(sum.name * percent.name / 100)
    },

    editPercent(e){
      this.setPromocodePercent(e)
      const sum = this.promocodeSum.find(s => s.id == this.promocode.sum)
      const percent = this.promocodePercent.find(p => p.id == e)
      this.setPromocodeSumResult(sum.name * percent.name / 100)
    }
  },

  computed: {

    ...mapState({
      promocode: state => state.partner.promocode,
      promocodeSum: state => state.partner.promocodeSum,
      promocodePercent: state => state.partner.promocodePercent,
      promocodeList: state => state.partner.promocodeList,
      partner: state => state.user.partner,
      message: state => state.partner.message,
      error: state => state.partner.error,
      loading: state => state.loadingSmall,
    }),
  },

  mounted(){
    this.actionPromocodeList();
    document.title = 'Промокоды - StartCommerce"'
  },

  unmounted(){
    this.setPromocodeList([]);
  }
}
</script>

<style>
 
</style>