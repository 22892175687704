<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ message }}</p>
      </div>
      <div v-if="error" class="form-group col-12 mess">
        <p class="bg-danger-light pl-3 pr-3 pt-2 pb-2 rounded">{{ error }}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item"><router-link to="/partner-information">Партнерская программа</router-link></li>
                  <li class="breadcrumb-item active">Начисления и выплаты</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Начисления и выплаты</h4>
                </div> 
              </div>
              <div v-if="partner" class="card card-block card-stretch">
                <div class="card-body">
                  <table class="table-responsive">
                    <tbody>
                      <tr class="white-space-no-wrap">
                        <td class="w210"><h6 class="mb-0 text-uppercase text-secondary">Сумма начислений</h6></td>
                        <td class="pl-0 py-3">{{ parseFloat(partner.sum_accruals).toFixed(2) }} <i class="fa fa-rub"></i></td>
                        <td></td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td class="w210"><h6 class="mb-0 text-uppercase text-secondary">Сумма выплат</h6></td>
                        <td class="pl-0 py-3">{{ parseFloat(partner.sum_payments).toFixed(2) }} <i class="fa fa-rub"></i></td>
                        <td></td>
                      </tr>
                      <tr class="white-space-no-wrap">
                        <td class="w210"><h6 class="mb-0 text-uppercase text-secondary">Сумма на вывод</h6></td>
                        <td class="pl-0 py-3">{{ parseFloat(partner.request_payments).toFixed(2) }} <i class="fa fa-rub"></i></td>
                        <td></td>
                      </tr>
                      
                      <tr class="white-space-no-wrap">
                        <td class="w210">
                          <my-input
                            :model-value="requestPayments" @update:model-value="setRequestPayments"
                            type="number"
                          />
                        </td>
                        <td class="pl-0 py-3"></td>
                        <td><div><button data-toggle="modal" @click="actionPartnerRequestPayments" class="btn btn-primary btn-sm">Запрос на вывод средств</button></div></td>
                      </tr>
                      
                  </tbody>
                  </table>
                </div>
                <div class="col-lg-12 mt-5">
                  <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                    <div class="d-flex align-items-center justify-content-between">
                      <h5 class="font-weight-bold">Платежные реквизиты</h5>
                    </div> 
                  </div>
                  <div class="card">
                    <div class="card-body">
                      <div v-if="loading"><loading-small /></div>
                      <div v-else><partner-payment-details-form /></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row">
                <div class="col-sm-12 col-lg-12">
                  <p>У вас нет статуса партнера, <router-link to="/partner-information" class="svg-icon">Стать партнером</router-link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import PartnerPaymentDetailsForm from '@/components/PartnerPaymentDetailsForm'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    PartnerPaymentDetailsForm,
  },
  methods: {
    ...mapMutations({setRequestPayments: 'partner/setRequestPayments', setPaymentDetails: 'partner/setPaymentDetails'}),
    ...mapActions({actionPartnerRequestPayments: 'partner/actionPartnerRequestPayments', actionGetPartnerPaymentDetails: 'partner/actionGetPartnerPaymentDetails'}),
  },

  computed: {

    ...mapState({
      requestPayments: state => state.partner.requestPayments,
      partner: state => state.user.partner,
      message: state => state.partner.message,
      error: state => state.partner.error,
      loading: state => state.loadingSmall,
    }),
  },

  mounted(){
    this.actionGetPartnerPaymentDetails();
    document.title = 'Начсления и выплты - StartCommerce"'
  },

  unmounted(){
    this.setPaymentDetails({});
  }

}
</script>

<style>

</style>