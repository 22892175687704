<template>
  <Header></Header>
  <div class="content-page">
    <div class="container-fluid">
      <div v-if="data.message" class="form-group col-12 mess">
        <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded">{{ data.message }}</p>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex flex-wrap align-items-center justify-content-between mb-4">
            <div class="d-flex align-items-center justify-content-between">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item"><router-link to="/feed"><i class="fa fa-home"></i></router-link></li>
                  <li class="breadcrumb-item active">Товары</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                <div class="d-flex align-items-center justify-content-between">
                  <h4 class="font-weight-bold">Товары</h4>
                </div>
                <div v-if="catalogs.length" class="create-workform">
                  <div class="d-flex flex-wrap align-items-center justify-content-between">
                    <div class="modal-product-search d-flex">
                      <div class="mr-3 position-relative">
                        <div class="form-group mb-0">
                          <my-input 
                              :model-value="data.searchSkuSupplier" @update:model-value="setSearchSkuSupplier"
                              @keyup.enter="actionGetProductsUser"
                              type="text"
                              placeholder="Поиск по артикулу поставщика"
                              class="form-control"
                            />
                          <a href="#" @click="actionGetProductsUser" class="search-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                          </a>
                        </div>
                      </div>
                      <div class="mr-3 position-relative">
                        <div class="form-group mb-0">
                          <my-input 
                              :model-value="data.searchSku" @update:model-value="setSearchSku"
                              @keyup.enter="actionGetProductsUser"
                              type="text"
                              placeholder="Поиск по артикулу StartCommerce"
                              class="form-control"
                            />
                          <a href="#" @click="actionGetProductsUser" class="search-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                          </a>
                        </div>
                      </div>
                      
                      <router-link to="/product" class="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mr-2"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg> Создать товар 
                      </router-link>
                    </div>
                  </div>
                </div>  
              </div>
              <div v-if="catalogs.length" class="row">
                <div class="iq-accordion career-style faq-style col-sm-12 col-md-12">
                  <div class="card iq-accordion-block">
                    <div class="active-faq clearfix">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-sm-12">
                            <a role="contentinfo" @click="setFilter" class="accordion-title collapsed">
                              <h5> Фильтры </h5> 
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <transition name="fade">
                      <div v-if="data.filter" class="row container-fluid accordion-details collapse show">
                        <div class="col-sm-12 col-md-4">
                          <div class="dataTables_length">
                            <label>Поиск по названию товара:</label>
                            <my-input 
                              :model-value="data.searchName" @update:model-value="setSearchName"
                              type="text"
                            />
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div class="dataTables_length">
                            <label>Поиск по бренду:</label>
                            <my-input 
                              :model-value="data.searchBrand" @update:model-value="setSearchBrand"
                              type="text"
                            />
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div class="dataTables_length">
                            <label>Поиск по штрих-коду:</label>
                            <my-input 
                              :model-value="data.searchBarcode" @update:model-value=" setSearchBarcode"
                              type="text"
                            />
                          </div>
                        </div>
                        <div v-if="data.searchName || data.searchBrand || data.searchBarcode" class="col-sm-12 mt-3 mb-2">
                          <div class="row">
                            <div class="col-sm-12 col-md-2">
                              <button @click="actionGetProductsUser" type="button" class="btn mt-2 mr-1 btn-success btn-sm btn-block">Применить</button>
                            </div>
                            <div class="col-sm-12 col-md-2">
                              <button @click="editNotFilter" type="button" class="btn mt-2 mr-1 btn-outline-danger btn-sm btn-block">Сбросить</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
                <!--<div class="iq-accordion career-style faq-style col-sm-12 col-md-5">
                  <div class="card iq-accordion-block">
                    <div class="active-faq clearfix">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-sm-12">
                            <a role="contentinfo" @click="setFilter" class="accordion-title collapsed">
                              <h5> Наценка </h5> 
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <transition name="fade">
                      <div class="row container-fluid accordion-details collapse show">
                        <div class="col-sm-12">
                          <h6 class="mb-3"> Цена-1 </h6>
                          <span class="text-secondary txt-example">Для наценки укажите коэфициент и/или фиксированную цену, если указан коэфициент и фиксированная наценка, наценки плюсуются. Далее укажите интервал от какой до какой суммы будет примеенна наценка или оставьте по умолчанию от 0 до ∞. Можно добавлять новые диапазоны </span>
                          <div v-for="(p, index) in data.markup.price1" :key="index" class="row">
                            <div class="col-sm-12 col-md-6 input-group mb-2">
                              <div class="input-group-prepend"><span class="input-group-text input-group-text-p">кэф.</span></div>
                              <my-input-array 
                                :model-value="p.coefficient" @update:model-value="setMarkupPrice1C"
                                :id="index"
                                type="number"
                                class="custom-select-sm form-control form-control-sm"
                              />
                            </div>
                            <div class="col-sm-12 col-md-6 input-group mb-2">
                              <div class="input-group-prepend"><span class="input-group-text input-group-text-p">фикс.</span></div>
                              <my-input-array 
                                :model-value="p.fix" @update:model-value="setMarkupPrice1Fix"
                                :id="index"
                                type="number"
                                class="custom-select-sm form-control form-control-sm"
                              />
                            </div>
                            <div class="col-sm-12 col-md-6 input-group mb-2">
                              <div class="input-group-prepend"><span class="input-group-text input-group-text-p">от</span></div>
                              <my-input-array 
                                :model-value="p.from" @update:model-value="setMarkupPrice1F"
                                :id="index"
                                type="number"
                                class="custom-select-sm form-control form-control-sm"
                              />
                            </div>
                            <div class="col-sm-12 col-md-6 input-group mb-2">
                              <div class="input-group-prepend"><span class="input-group-text input-group-text-p">до</span></div>
                              <my-input-array 
                                :model-value="p.to" @update:model-value="setMarkupPrice1T"
                                placeholder="∞"
                                :id="index"
                                type="number"
                                class="custom-select-sm form-control form-control-sm"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <button type="button" @click="setMarkupPrice1New({coefficient: 0, fix: 0, from: 0, to: ''})" class="btn mt-2 mr-1 btn-outline-success btn-sm btn-block">+</button>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>-->
              </div>
              <div class="card card-block card-stretch">
                <div class="card-body p-0">
                  <div class="d-flex justify-content-between align-items-center p-3">
                    <h5 class="font-weight-bold">Товары</h5>
                    <div>
                      <!--<button class="btn btn-success btn-sm">
                        <i class="fa fa-file-excel-o" aria-hidden="true"></i> Скачать Excel
                      </button>-->
                      <div class="nav-item nav-icon dropdown" style="position: relative;">
                        <div class="ml-3 icon-param-prod" @click="setShowParam">
                          <i class="fa fa-cog" aria-hidden="true"></i>
                          <span class="bg-primary"></span>
                        </div>
                        <div :class="`iq-sub-dropdown dropdown-menu para-settings ${data.showParam ? 'show' : ''}` ">
                          <div class="card shadow-none m-0 border-0">
                            <div class="p-3 card-header-border p-3">
                              <h6 class="text-center"> Настройки списка товаров </h6>
                            </div>
                            <div class="s-param-prod">
                              <div class="card-body" style="">
                                <div class="custom-checkbox">
                                  <ul class="dropdown-menu-1 overflow-auto list-style-1 mb-0">
                                    <li v-for="sL in data.productParamStat" :key="sL.id" class="dropdown-item-1 float-none">
                                      <label>
                                        <my-checkbox 
                                          :model-value="data.productParam.find(v => v == sL.id) ? true : false" @update:model-value="data.productParam.find(v => v == sL.id) ? setProductParamDel(sL.id) : setProductParamAdd(sL.id)"
                                        />
                                        {{ sL.name }}
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="card-footer text-muted">
                                <button type="button" class="btn mr-2 btn-primary btn-sm btn-block" @click="actionProductParam">Сохранить</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                      
                     
                      <div class="row">
                        <div class="col-sm-12 col-md-6">
                          <div class="dataTables_length" id="DataTables_Table_0_length">
                            <label>Показать:  
                              <my-select
                                :model-value="data.limit"
                                :options="numbering"
                                @update:model-value="editLimit"
                                class="custom-select custom-select-sm form-control form-control-sm"
                              />
                              </label>
                              <span class="ml-3"> Всего товаров: <b>{{ data.length.toLocaleString() }}</b></span>
                          </div>
                        </div>
                 
                        <div class="col-sm-12 col-md-6">
                          <div id="DataTables_Table_0_filter" class="dataTables_filter">
                            <label>Каталоги: 
                              <my-select
                                :model-value="data.selectedSupplier"
                                :options="catalogs"
                                @update:model-value="editCat"
                                class="wauto custom-select custom-select-sm form-control form-control-sm"
                              />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <div v-if="data.loadDel" class="form-group col-12 mess">
                            <p class="bg-success-light pl-3 pr-3 pt-2 pb-2 rounded"><loading-small /></p>
                          </div>
                          <div v-if="catalogs.length">
                            <products-list :products="data.productsList" :flagParam="data.flagParam" :productParam="data.productParam" :productParamStat="data.productParamStat" :loading="data.loading" :searchName="data.searchName" />
                          </div>
                          <div v-else>
                            <div class="alert alert-primary" role="alert">
                              <div class="iq-alert-text">
                                <p><b><i>Чтобы создать или импортировать товар, вам необходимо создать как минимум один каталог в разделе <router-link to="/catalogs">Каталоги</router-link></i></b></p>
                              </div>
                            </div>  
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!data.loading && data.length > data.limit" class="col-lg-12">
              <pagination :pages="Math.ceil(data.length / data.limit)" :model-value="data.page" @update:model-value="editPage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-footer />
</template>


<script>
import Header from '@/components/Header'
import MySelect from "@/components/UI/MySelect"
import ProductsList from '@/components/ProductsList'
import Excel from '@/components/Excel'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  components: {
    Header,
    MySelect,
    ProductsList,
    Excel
  },
  methods: {
    ...mapMutations({setFilter: 'products/setFilter', setSearchSkuSupplier: 'products/setSearchSkuSupplier', setSearchSku: 'products/setSearchSku', setLimit: 'products/setLimit', setSelectedSupplier: 'products/setSelectedSupplier', setSearchBrand: 'products/setSearchBrand',  setSearchBarcode: 'products/setSearchBarcode', setSearchName: 'products/setSearchName', setProductParamAdd: 'products/setProductParamAdd', setProductParamDel: 'products/setProductParamDel', setShowParam: 'products/setShowParam', setPage: 'products/setPage'}),
    ...mapActions({actionProductParam: 'products/actionProductParam', actionGetProductParam: 'products/actionGetProductParam', actionGetProductsUser: 'products/actionGetProductsUser', actionCatalogParam: 'products/actionCatalogParam'}),

    editCat(e){
      this.actionCatalogParam(e)
    },

    editLimit(e){
      this.setLimit(e)
      this.actionGetProductsUser()
    },

    editNotFilter(){
      this.setSearchName('')
      this.setSearchBrand('')
      this.setSearchBarcode('')
      this.setFilter()
      this.actionGetProductsUser()
    },

    editPage(e){
      this.setPage(e)
      this.actionGetProductsUser()
    }

  },

  computed: {

    ...mapState({
      data: state => state.products,
      loading: state => state.products.loading,
      numbering: state => state.numbering,
      limit: state => state.products.limit,
      page: state => state.products.page,
      catalogs: state => state.suppliers.map(e => {return {id: e.id, name: e.name}}),
      typeSettings: state => state.user.type_settings,
    }),
  },

  mounted(){
    this.actionGetProductParam()
    if(this.catalogs && this.catalogs.length){
      this.setSelectedSupplier(this.typeSettings && this.typeSettings.catalog ? this.typeSettings.catalog : this.catalogs[0].name)
    }
    document.title = 'Все товары - StartCommerce"'
  },

  unmounted(){
    //this.setProductsList([])
  }
}
</script>

<style>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  
  .flip-list-move {
    transition: transform 0.8s ease;
  }
  .supp_select{
    height:30px;
    line-height:20px
  }
  .wauto{
      width: auto;
    }
    .fa-file-excel-o{
      padding-right: 10px;
    }
  .input-group-text-p{
    padding: 0px 10px;
  }
  .txt-example{
    font-size:12px;
    padding-bottom: 5px;
    display: block;
  }
  .para-settings{
    left: -239px;
    width: 280px;
    top:40px;
  }
  .s-param-prod{
    display: block;
    overflow-y: scroll;
    height: 150px;
  }
  .icon-param-prod i{
    font-size: 23px;
    color: #3378ff;
  }
  .icon-param-prod{
    position: relative;
    top:4px
  }
  @media (max-width: 1000px){
    .adap-prod-list .adap-prod-list-block{
      max-width: 100% !important;
      width: 100% !important;
      flex: none !important;
      padding: 0;
      margin-bottom: 10px;
    }

    .adap-prod-list-block label{
      padding-bottom: 5px;
    }
  }
</style>