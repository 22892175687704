<template>
  <table class="table data-table mb-0 dataTable no-footer sp">
    <thead class="table-color-heading">
      <tr v-if="flagParam" class="text-light">
        <th v-for="pp in productParam" :key="pp"><label class="text-muted mb-0">{{ productParamStat.find(f => f.id == pp).name }}</label></th>
        <th scope="col" class="text-right"> Действия </th>
      </tr>
      <tr v-else>
        <th><label class="text-muted mb-0">Фото</label></th>
        <th><label class="text-muted mb-0">Название</label></th>
        <th><label class="text-muted mb-0">Бренд</label></th>
        <th><label class="text-muted mb-0">Штрих-код</label></th>
        <th v-if="searchName">Каталог</th>
        <th scope="col" class="text-right"> Действия </th>
      </tr>
      
    </thead>
    <tbody>
      <transition-group name="flip-list">
        <tr v-if="loading"><td colspan="9"><loading-small /></td></tr>
        <tr v-else-if="flagParam" v-for="product in products" :key="product.sku" class="white-space-no-wrap">
          <td v-for="pp in productParam" :key="pp">
            <div v-if="pp == 'image'">
              <img v-if="product.fields && product.fields.image" class="avatar rounded-circle" alt="user-icon" :src="`${product.fields.image}`" />
              <i v-else class="fa fa-image i-img2"></i>
            </div>
            <div class="v250" v-if="pp == 'name'">{{ product.fields ? product.fields.name : '' }}</div>
            <div v-if="pp == 'sku_supplier'">{{ product.sku_supplier }}</div>
            <div v-if="pp == 'sku'">{{ product.sku }}</div>
            <div v-if="pp == 'price'">{{ product.retail_price ? Number(product.retail_price).toLocaleString() : '' }}</div>
            <div v-if="pp == 'price_two'">{{ product.price_two ? Number(product.price_two).toLocaleString() : '' }} </div>
            <div v-if="pp == 'price_three'">{{ product.price_three ? Number(product.price_three).toLocaleString() : '' }}</div>
            <div v-if="pp == 'count'">{{ product.count ? product.count : '' }}</div>
            <div v-if="pp == 'brand'">{{ product.fields ? product.fields.brand : '' }}</div>
            <div v-if="pp == 'barcode'">{{ product.fields ? product.fields.barcode : '' }}</div>
          </td>
          <td>
            <div class="d-flex justify-content-end align-items-center">
              <span class="" title="Смотреть" data-target="#exampleModal" @click="viewCatalog(product.sku)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-secondary"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
              </span>
              <a href="#" class="" title="Редактировать" @click="editProduct(product.sku)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-secondary mx-4"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
              </a>
              <button title="Удалить" href="#" class="badge bg-danger border-none" @click="actionRemoveProduct(product.sku)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
              </button>
            </div>
          </td>
        </tr>
        <tr v-else v-for="product in products" :key="product.sku_supplier" class="white-space-no-wrap">
          <td>
            <img v-if="product.image || product.product && product.product.image" class="avatar rounded-circle" alt="user-icon" :src="`${searchName ? product.product.image : product.image}`" />
            <i v-else class="fa fa-image i-img2"></i>
          </td>
          <td><div class="v250">{{ searchName ? product.product.name : product.name }}</div></td>
          <td><div>{{ searchName ? product.product.brand : product.brand}}</div></td>
          <td><div>{{ searchName ? product.product.barcode : product.barcode }}</div></td>
          <td v-if="searchName">Каталог</td>
          <td>
            <div class="d-flex justify-content-end align-items-center">
              <span class="" title="Смотреть" data-target="#exampleModal" @click="viewCatalog(product.sku)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-secondary"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
              </span>
              <a href="#" class="" title="Редактировать" @click="editProduct(product.sku)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="text-secondary mx-4"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path></svg>
              </a>
              <button title="Удалить" class="badge bg-danger" @click="actionRemoveProduct(product.sku)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
              </button>
            </div>
          </td>
        </tr>
      </transition-group>
    </tbody>
  </table>

  <div role="dialog" :class="`modal fade ${modalWindow ? 'show' : ''}`" aria-modal="true" :style="`display: ${modalWindow ? 'block' : 'none'}`">
    <div class="modal-dialog modal-xl modal-dialog-scrollable">
      <span tabindex="0"></span>
      <div id="modal-3___BV_modal_content_" tabindex="-1" class="modal-content">
        <header id="modal-3___BV_modal_header_" class="modal-header">
          <h5 id="modal-3___BV_modal_title_" class="modal-title">Карточка товара</h5>
          <button @click="closeCatalog" type="button" aria-label="Close" class="close">×</button>
        </header>
        <div id="modal-3___BV_modal_body_" class="modal-body">
          <div>
            <div class="row">
              <div class="col-12 col-sm-5 img-product-one">
                <img v-if="productOne.image" class="rounded-circle" alt="user-icon" :src="`${productOne.image}`" />
              <i v-else class="fa fa-image i-img2 i-im3"></i>
              </div>
              <div class="col-12 col-sm-7">
                <h3>{{ productOne.name }}</h3>
                <div class="mt-1 mb-3 product-one-size-sku">
                  <div class="col-12 col-sm-12 mb-1"><span class="pr-3">Артикул поставщика:</span> <b>{{ productOne.sku_supplier }}</b></div>
                  <div class="col-12 col-sm-12 mb-1"><span class="pr-3">Артикул StartCommerce:</span> <b>{{ productOne.sku }}</b></div>
                </div>
                <div class="mt-5 mb-3 product-one-size">
                  <div class="col-12 col-sm-12 mb-1"><span class="pr-3">Цена-1:</span> <b>{{ productOne.retail_price }}</b> ₽</div>
                  <div class="col-12 col-sm-12 mb-1"><span class="pr-3">Цена-2:</span> <b>{{ productOne.price_two }}</b> ₽</div>
                  <div class="col-12 col-sm-12 mb-1"><span class="pr-3">Цена-3:</span> <b>{{ productOne.price_three }}</b> ₽</div>
                </div>
                <div class="mb-3 product-one-size">
                  <div class="col-12 col-sm-12 mb-1"><span class="pr-3">Количество:</span> <b>{{ productOne.count }}</b></div>
                </div>
                <div class="mb-3 product-one-size">
                  <div class="col-12 col-sm-12 mb-1"><span class="pr-3">Бренд:</span> <b>{{ productOne.brand }}</b></div>
                </div>
                <div class="mb-3 product-one-size">
                  <div class="col-12 col-sm-12 mb-1"><span class="pr-3">Штрих-код:</span> <b>{{ productOne.barcode }}</b></div>
                </div>
              </div>
              <div class="col-12 col-sm-12">
                <div class="pr-3 mb-3 product-one-size">Описание:</div>
                <div>{{ productOne.description }}</div>
              </div>
              <div class="col-12 col-sm-3"></div>
              <div class="col-12 col-sm-3"></div>
              <div class="col-12 col-sm-3"></div>
              <div class="col-12 col-sm-3"></div>
              <div class="col-12 col-sm-3"></div>
              <div class="col-12 col-sm-3"></div>
              <div class="col-12 col-sm-3"></div>
              <div class="col-12 col-sm-3"></div>
              <div class="col-12 col-sm-3"></div>
            </div>

          </div>
        </div>
        <footer id="modal-3___BV_modal_footer_" class="modal-footer">
          <button @click="closeCatalog" type="button" class="btn btn-secondary">Закрыть</button>
        </footer>
      </div>
      <span tabindex="0"></span>
    </div>
  </div>
  <div id="modal-3___BV_modal_backdrop_" class="modal-backdrop" :style="`display: ${modalWindow ? 'block' : 'none'}`"></div>

</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'products-list',
  data() {
    return {
      modalWindow: false,
    }
  },
  props: {
    products: Array,
    flagParam: Boolean,
    productParam: Array,
    productParamStat: Array,
    loading: Boolean,
    searchName: String
  },
  methods: {
    ...mapMutations({setProduct: 'products/setProduct'}),
    ...mapActions({actionGetProductUser: 'products/actionGetProductUser', actionRemoveProduct: 'products/actionRemoveProduct'}),

    viewCatalog(sku){
      this.actionGetProductUser(sku)
      this.modalWindow = true
    },

    closeCatalog(){
      this.setProduct({})
      this.modalWindow = false
    },

    editProduct(sku){
      this.actionGetProductUser(sku)
      this.$router.push('/product')
    }
    
  },

  computed: {
    ...mapState({
      productOne: state => state.products.product
    }),
  },
}
</script>
<style>
  .v250{
    width: 250px;
  }
  .white-space-no-wrap{
    white-space: normal;
  }
  .border-none{
    border: none;
  }
  .block-modal{
    position: absolute; 
    z-index: 1040;
  }
  .i-im3{
    font-size: 350px;
  }
  .img-product-one img{
    max-width: 100%;
  }
  .product-one-size{
    font-size: 21px;
  }
  .modal-backdrop{
    opacity: 0.5;
  }
  .product-one-size-sku{
    font-size: 13px;
  }
</style>