<template>
  <div class="mod-img bd-example-modal-lg" :style="modal ? 'visibility: visible' : 'visibility: hidden'" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" @click="sss">Modal title</h5>
              <button type="button" class="close" data-dismiss="modal" @click="closeModalImage" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-8">
                <div id="croppie"></div>
                
              </div>
              <div class="col-lg-4">
                <div v-if="modifiedImg" class="">
                  <img :src="modifiedImg" class="mb-3" />
                  <img :src="modifiedImg" class="img-fluid avatar-rounded" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div v-if="scroppie" class="col-12 col-sm-8 text-center">
                <button type="button" class="btn btn-warning mr-2 mt-2" @click="setAngle('-')"><i class="fa fa-arrow-left pr-2" aria-hidden="true"></i></button>
                <button type="button" class="btn btn-warning mr-2 mt-2" @click="setAngle('+')"><i class="fa fa-arrow-right pl-2" aria-hidden="true"></i></button>
                <button type="button" class="btn btn-success mr-2 mt-2" @click="setModifiedImg"><i class="fa fa-scissors pr-2" aria-hidden="true"></i>Обрезать</button>
              </div>
              <div v-if="modifiedImg" class="col-12 col-sm-4 text-center">
                <button type="button" class="btn btn-primary mr-2 mt-2" @click="setImageRes"><i class="fa fa-check pr-2" aria-hidden="true"></i>Ок</button>
              </div>
            </div>
            <br><br><br><br>
          </div>
        </div>
    </div>
  </div>
  <div v-if="modal" class="modal-backdrop show"></div>
</template>


<script>
 
import Croppie from 'croppie'
import {mapState, mapActions, mapMutations} from 'vuex'
export default {
  name: 'image-upload',
  components: {
    Croppie
  },

  props: {
    image: [String, Array, Object]
  },

  data(){
    return {
      modifiedImg: null,
      scroppie: null,
      orientation: 1,
      blob: null,
    }
  },

  methods: {
    ...mapMutations({
      setModal: 'setModal', setImg: 'setImg'
    }),

    async setModifiedImg(){
      this.blob = await this.scroppie.result('blob')
      this.modifiedImg = window.URL.createObjectURL(this.blob)
    },

    setAngle(v){
      if(v === '+'){
        if(this.orientation === 1){
          this.orientation = 7
        } else if(this.orientation === 7){
          this.orientation = 3
        } else if(this.orientation === 3){
          this.orientation = 8
        } else if(this.orientation === 8){
          this.orientation = 1
        }
      }

      if(v === '-'){
        if(this.orientation === 1){
          this.orientation = 8
        } else if(this.orientation === 8){
          this.orientation = 3
        } else if(this.orientation === 3){
          this.orientation = 7
        } else if(this.orientation === 7){
          this.orientation = 1
        }
      }

      this.scroppie.bind({
        url: this.img,
        orientation: this.orientation
      })
    },

    setImgUpload(){
      const el = document.getElementById('croppie');
      this.scroppie = new Croppie(el, {
        viewport: { width: 200, height: 200 },
        boundary: { width: 300, height: 300 },
        showZoomer: true,
        enableOrientation: true
      })

      this.scroppie.bind({
        url: this.img,
        orientation: this.orientation
      })
    },

    setImageRes(){
      this.setModal()
      const file = new File([this.blob], this.image.name, { type: this.image.type, lastModified: Date.now() })
      this.$emit('update:image', file)
      this.setImg(window.URL.createObjectURL(this.blob))
      this.modifiedImg = null
      this.scroppie.destroy()
      this.scroppie = null
      this.orientation = 1
    },

    closeModalImage(){
      this.setModal()
      this.setImg(null)
      this.$emit('update:image', null)
      this.modifiedImg = null
      this.scroppie.destroy()
      this.scroppie = null
      this.orientation = 1
    }
  },

  computed: {
    ...mapState({
      modal: state => state.modal,
      img: state => state.img
    }),
  },

  watch: {
    image(image){
      if(image && !this.img){
        this.setImg(window.URL.createObjectURL(image))
        this.setImgUpload()
        
      }
    }
  },
  
  unmounted(){
    this.setImg(null)
  }
}
</script>

<style>
.mod-img{
  position: absolute;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    padding-bottom: 60px;
}

.visibility-modal{
  visibility: hidden;
}
</style>
