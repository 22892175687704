import { axiosSaveCat, axiosCategories } from '@/api/index'

export const categoriesModule = {
  state: () => ({
    loading: false,
    categoriesList: [],
    limit: 30,
    page: 1,
    save: false,
    idRemove: '',
    message: '',
    loadMess: '',
  }),

  getters: {
    sortPagination(state){
      const startIndex = state.page === 1 ? -1 : (state.page * state.limit) - (state.limit + 1)
      return {
        count: state.categoriesList.length,
        categories: state.categoriesList.filter((v, index) => index > startIndex && index < state.page * state.limit)
      }
    }
  },

  mutations: {
    setCategories(state, v){state.categoriesList = v},
    setPage(state, v){state.page = v},
    setEditCategory(state, v){state.categoriesList = state.categoriesList.map(e => e.id == v ? {...e, edit: true} : e)},
    setNameCategory(state, v){state.categoriesList = state.categoriesList.map((e, index) => index == v.id ? {...e, name: v.name} : e)},
    setRemoveCategory(state, v){state.categoriesList = state.categoriesList.filter(e => e.id != state.idRemove)},
    setAddCategory(state, v){state.categoriesList.unshift(v)},
    setLoading(state, v){state.loading = v},
    setSave(state, v){state.save = v},
    setEdit(state, v){state.edit = v},
    setIdRemove(state, v){state.idRemove = v},
    setMessage(state, v){state.message = v},
    setLoadMess(state, v){state.loadMess = v},
  },

  actions: {

    //Получаем категории
    async actionCategories({rootState, commit, dispatch}){
      try {
        commit('setLoading', true)
        const response = await axiosCategories(rootState.token)
        setTimeout(function () {
          commit('setLoading', false)
          commit('setCategories', response.categories)
        }, 500);
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionCategories')
        }
        console.log(e.response.data)
      }
    },

    //Сохроняем категории
    async actionSave({state, rootState, commit, dispatch}){
      try {
        commit('setCategories', state.categoriesList.map(e => {return {...e, edit: false}}))
        await axiosSaveCat(rootState.token, {categories: state.categoriesList})
        commit('setSave', false)
        dispatch('actionCategories')
      } catch (e) {
        if(e.response.status === 419){
          await dispatch('actionAuthAccess', null, { root: true })
          await dispatch('actionSave')
        }
        console.log(e.response.data)
      } 
    },
  },

  namespaced: true
}