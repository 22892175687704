import axios from 'axios'
axios.defaults.withCredentials = true;

const url = 'https://start-commerce.ru/'
// 'https://start-commerce.ru/'
// 'http://localhost:11000/'

const instals = () => {
  return axios.create({
    withCredentials: true,
    baseURL: url
  })
}

const instalsAuth = (token) => {
  return axios.create({
    withCredentials: true,
    baseURL: url,
    headers: { Authorization: `Bearer ${token}` },
  })
}

const instalsAuthCatalog = (token, user) => {
  return axios.create({
    withCredentials: true,
    baseURL: url,
    headers: { 
      "ProductToken": token,
      "User": user
    }
  })
}

const instalsAuthFile = (token) => {
  return axios.create({
    withCredentials: true,
    baseURL: url,
    headers: { Authorization: `Bearer ${token}` }
  })
}

export const axiosReg = async (data) => {
  const response = await instals().post('auth/registration', data)
  return response.data
}

export const axiosLogin = async (data) => {
  const response = await instals().post('auth/login', data)
  return response.data
}

export const axiosLogout = async () => {
  const response = await instals().post('auth/logout')
  return response.data
}

export const axiosAuth = async () => {
  const response = await instals().get('auth/refresh')
  return response.data
}

export const axiosUser = async (token) => {
  const response = await instalsAuth(token).get('users/user')
  return response.data
}

export const axiosRecover = async (param) => {
  const response = await instals().get(`auth/recover/${param}`)
  return response.data
}

export const axiosActivation = async (param) => {
  const response = await instals().get(`auth/activation/${param}`)
  return response.data
}

export const axiosLinkPassword = async (param) => {
  const response = await instals().get(`auth/link/${param}`)
  return response.data
}

export const axiosResetPassword = async (data) => {
  const response = await instals().put('auth/reset-password', data)
  return response.data
}

export const axiosEditRole = async (token, param) => {
  const response = await instalsAuth(token).put(`users/edit-role/${param}`)
  return response.data
}

// Аккаунт поставщика


export const axiosSupplier = async (token) => {
  const response = await instalsAuth(token).get('suppliers/get-suppliers')
  return response.data
}

export const axiosSaveSupplier = async (token, data) => {
  const response = await instalsAuthFile(token).post('suppliers/create', data)
  return response.data
}

export const axiosRemoveSupplier = async (token, param) => {
  const response = await instalsAuth(token).delete(`suppliers/delete/${param}`)
  return response.data
}

export const axiosEditSupplier = async (token, data) => {
  const response = await instalsAuthFile(token).put('suppliers/edit', data)
  return response.data
}

//Создаем каталог

export const axiosCreateCatalog = async (token, param) => {
  const response = await instalsAuth(token).post(`catalogs/create-user/${param}`)
  return response.data
}

//Удаляем каталог

export const axiosRemoveCatalog = async (token, param) => {
  const response = await instalsAuth(token).delete(`catalogs/remove/${param}`)
  return response.data
}

// Редактирование пользователя

export const axiosEditUser = async (token, data, file) => {
  let formData = new FormData();
  formData.append('files', file);
  formData.append('name', data.name);
  formData.append('phone', data.phone);
  formData.append('email', data.email);
  const response = await instalsAuthFile(token).put('users/edit', formData)
  return response.data
}

export const axiosEditPassword = async (token, data) => {
  const response = await instalsAuth(token).put('auth/edit-password', data)
  return response.data
}

export const axiosRequisites = async (token, data) => {
  const response = await instalsAuth(token).post('users/requisites-yur', data)
  return response.data
}

export const axiosEditSelectedProviders = async (token, data) => {
  const response = await instalsAuth(token).put('users/selected-providers', data)
  return response.data
}

export const axiosRemovePackageSuppliers = async (token, data) => {
  const response = await instalsAuth(token).put('users/selected-providers-remove', data)
  return response.data
}

// Тарифы, пакеты

export const axiosGetTariff = async () => {
  const response = await instals().get('tariff/all')
  return response.data
}

export const axiosTariff = async (token, value) => {
  const response = await instalsAuth(token).post(`users/create-user-package/${value}`)
  return response.data
}

export const axiosClosesTariff = async (token, value) => {
  const response = await instalsAuth(token).put(`users/close-user-package/${value}`)
  return response.data
}

export const axiosTariffSuppliers = async (token, data) => {
  const response = await instalsAuth(token).get(`suppliers/get-suppliers/${data}`)
  return response.data
}

export const axiosCheckTariff = async (token) => {
  const response = await instalsAuth(token).put('users/check-tariff')
  return response.data
}

export const axiosAddAdditionalSupplier = async (token, value) => {
  const response = await instalsAuth(token).put(`users/addition/${value}`)
  return response.data
}

export const axiosPaymentDopSuppliers = async (token) => {
  const response = await instalsAuth(token).put('users/payment-addition')
  return response.data
}

export const axiosPaymentTariff = async (token, data) => {
  const response = await instalsAuth(token).put('users/payment-tariff', data)
  return response.data
}

export const axiosTopUpBalance = async (token, data) => {
  const response = await instalsAuth(token).post('users/top-up-balance', data)
  return response.data
}

export const axiosGetOrders = async (token) => {
  const response = await instalsAuth(token).get('users/get-orders')
  return response.data
}

export const axiosGetBalanceHistory = async (token) => {
  const response = await instalsAuth(token).get('users/get-balance-history')
  return response.data
}

// Продукты

export const axiosProductsList = async (token) => {
  const response = await instalsAuth(token).get('catalog/products')
  return response.data
}

export const axiosProductParam = async (token, data) => {
  const response = await instalsAuth(token).post('type-settings/product-param', data)
  return response.data
}

export const axiosGetProductParam = async (token) => {
  const response = await instalsAuth(token).get('type-settings/get-product-param')
  return response.data
}

export const axiosCatalogParam = async (token, param) => {
  const response = await instalsAuth(token).post(`type-settings/catalog/${param}`)
  return response.data
}

export const axiosGetProductsUser = async (token, data, page, limit) => {
  const response = await instalsAuth(token).get(`catalogs/get-products-user/${JSON.stringify(data)}/${page}/${limit}`)
  return response.data
}

export const axiosGetProductUser = async (token, paramOne, paramThree) => {
  const response = await instalsAuth(token).get(`catalogs/get-product-user/${paramOne}/${paramThree}`)
  return response.data
}

export const axiosSaveProduct = async (token, data) => {
  const response = await instalsAuth(token).post('catalogs/save-product', data)
  return response.data
}

export const axiosRemoveProduct = async (token, sku, catalog) => {
  const response = await instalsAuth(token).put(`catalogs/remove-product/${sku}/${catalog}`)
  return response.data
}


// Автоматическая оплата
export const axiosTemporaryPayment = async (token, param) => {
  const response = await instalsAuth(token).put(`users/automatic-payment/${param}`)
  return response.data
}
export const axiosTemporaryPeriod = async (token, param) => {
  const response = await instalsAuth(token).put(`users/automatic-period/${param}`)
  return response.data
}

//Категории
export const axiosCategories = async (token) => {
  const response = await instalsAuth(token).get(`category/get`)
  return response.data
}

export const axiosSaveCat = async (token, data) => {
  const response = await instalsAuth(token).post('category/save', data)
  return response.data
}

// Нейросеть

export const axiosNeurnetworkCreateText = async (token, data) => {
  const response = await instalsAuth(token).post('neurnetwork/create', data)
  return response.data
}

export const axiosNeurnetworkRewriteText = async (token, data) => {
  const response = await instalsAuth(token).post('neurnetwork/rewrite', data)
  return response.data
}

export const axiosNeurnetworkTextList = async (token, page) => {
  const response = await instalsAuth(token).get(`neurnetwork/get/${page}`)
  return response.data
}

export const axiosCreateNeurnetworkToken = async (token) => {
  const response = await instalsAuth(token).post(`users/create-token-neurnetwork/`)
  return response.data
}

// Партнеры

export const axiosCreatePartner = async (token, data) => {
  const response = await instalsAuth(token).post(`partners/create`, data)
  return response.data
}

export const axiosPromocodeList = async (token) => {
  const response = await instalsAuth(token).get(`partners/get-promocodes`)
  return response.data
}

export const axiosCreatePromocode = async (token, data) => {
  const response = await instalsAuth(token).post(`partners/create-promocode`, data)
  return response.data
}

export const axiosStatusPromocode = async (token, param) => {
  const response = await instalsAuth(token).put(`partners/edit-status-promocode/${param}`)
  return response.data
}

export const axiosRemovePromocode = async (token, param) => {
  const response = await instalsAuth(token).delete(`partners/remove-promocode/${param}`)
  return response.data
}

export const axiosReferralsList = async (token) => {
  const response = await instalsAuth(token).get(`partners/get-referrals`)
  return response.data
}

export const axiosPartnerRequestPayments = async (token, param) => {
  const response = await instalsAuth(token).put(`partners/request-payments/${param}`)
  return response.data
}

export const axiosPartnerAccrualList = async (token, id, page, limit) => {
  const response = await instalsAuth(token).get(`partners/get-referrals-accruals/${id}/${page}/${limit}`)
  return response.data
}

export const axiosPartnerReferralPaymentsList = async (token, id, page, limit) => {
  const response = await instalsAuth(token).get(`partners/get-referrals-action/${id}/${page}/${limit}`)
  return response.data
}

export const axiosEditPartnerPaymentDetails = async (token, data) => {
  const response = await instalsAuth(token).put(`partners/edit-payment-details`, data)
  return response.data
}

export const axiosGetPartnerPaymentDetails = async (token) => {
  const response = await instalsAuth(token).get(`partners/get-payment-details`)
  return response.data
}
