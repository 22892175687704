<template>
  <section class="login-content">
    <div class="container h-100">
      <div class="row align-items-center justify-content-center h-100">
        <div class="col-md-5">
          <div class="card p-5">
            <div class="auth-logo">
              <a href="https://startcommerce.ru/"><img src="/images/96.png" class="img-fluid rounded-normal light-logo" alt="logo"></a>
            </div>
            <h3 class="mb-3 text-center">Создать новый пароль</h3>
            <reset-password-form />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ResetPasswordForm from '@/components/ResetPasswordForm'
export default {
  components: {
    ResetPasswordForm
  },

  mounted(){
    document.title = 'Новый пароль - StartCommerce'
  }
}
</script>
